import {
  require_dist
} from "./chunk-UNJCJGB4.js";
import {
  __toESM
} from "./chunk-JCO6WRHX.js";

// src/providers/global.tsx
var import_react_secure_storage = __toESM(require_dist());
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
var globalContext = createContext({
  jwt: "",
  accessToken: "",
  globalLoading: false,
  onLogin: void 0,
  onLogout: void 0
});
var GlobalContextProvider = ({ children }) => {
  return /* @__PURE__ */ React.createElement(GlobalContextApp, null, children);
};
var GlobalContextApp = ({ children }) => {
  const [jwt, setJwt] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const onSSOData = useCallback(async (response) => {
    if (response.error) {
      console.log("ee", response);
    } else {
      import_react_secure_storage.default.setItem("jwt", response?.jwt);
      import_react_secure_storage.default.setItem("accessToken", response?.access_token);
      setJwt(response?.jwt);
      setAccessToken(response?.access_token);
    }
  }, []);
  useEffect(() => {
    const jwt2 = import_react_secure_storage.default.getItem("jwt");
    const accessToken2 = import_react_secure_storage.default.getItem("accessToken");
    if (jwt2) {
      setJwt(jwt2);
    }
    if (accessToken2) {
      setAccessToken(accessToken2);
    }
  }, []);
  const handleLogout = async () => {
    import_react_secure_storage.default.removeItem("jwt");
    import_react_secure_storage.default.removeItem("accessToken");
    import_react_secure_storage.default.removeItem("connection");
    import_react_secure_storage.default.removeItem("aesirxData");
    import_react_secure_storage.default.removeItem("preregistration");
    setJwt("");
    setAccessToken("");
  };
  return /* @__PURE__ */ React.createElement(
    globalContext.Provider,
    {
      value: {
        jwt,
        accessToken,
        globalLoading: false,
        onLogin: onSSOData,
        onLogout: handleLogout
      }
    },
    children
  );
};
var useGlobalContext = () => useContext(globalContext);

export {
  GlobalContextProvider,
  useGlobalContext
};

import {
  useProfileContext
} from "./chunk-EJPJYPT6.js";
import {
  axios_default
} from "./chunk-2EYS7YDL.js";
import {
  renderField
} from "./chunk-VRFZDOKQ.js";
import {
  FormDAMImage
} from "./chunk-ZIXZUI2P.js";
import {
  Label
} from "./chunk-NCTNDEQ3.js";
import {
  Spinner
} from "./chunk-POLRV4GK.js";
import {
  Button
} from "./chunk-3RL7KU3G.js";
import {
  FORM_FIELD_TYPE
} from "./chunk-IIJXEE2D.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";

// src/pages/Profile/General.tsx
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faUserCog } from "@fortawesome/free-solid-svg-icons/faUserCog";
import { MEMBER_FIELD_KEY, MEMBER_GET_FIELD_KEY, AesirxAuthenticationApiService } from "aesirx-lib";
import { observer } from "mobx-react";
import SimpleReactValidator from "simple-react-validator";
import { env } from "aesirx-lib";
var ProfileGeneral = observer(() => {
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { model } = useProfileContext();
  const request = new AesirxAuthenticationApiService();
  const memberInfo = model.getData();
  const jwt = request.getStore("jwt");
  const [formPropsData, setFormPropsData] = useState({
    [MEMBER_FIELD_KEY.ID]: "",
    [MEMBER_FIELD_KEY.AVATAR_DAM]: "",
    [MEMBER_FIELD_KEY.FIRST_NAME]: "",
    [MEMBER_FIELD_KEY.LAST_NAME]: "",
    [MEMBER_FIELD_KEY.DESCRIPTION]: "",
    [MEMBER_FIELD_KEY.ORGANIZATION]: ""
  });
  const getPreregistration = async (jwt2) => {
    try {
      const response = await axios_default.get(
        `${env.REACT_APP_WEB3_API_ENDPOINT || "https://web3id.backend.aesirx.io:8001"}/preregistration/aesirx`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt2
          }
        }
      );
      return response.data.objForm;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchData = async () => {
    try {
      const preregistrationData = await getPreregistration(`${jwt}`);
      setFormPropsData({
        [MEMBER_FIELD_KEY.ID]: preregistrationData?.id ?? memberInfo[MEMBER_GET_FIELD_KEY.ID],
        [MEMBER_FIELD_KEY.AVATAR_DAM]: preregistrationData?.avatar ?? memberInfo[MEMBER_GET_FIELD_KEY.AVATAR_DAM],
        [MEMBER_FIELD_KEY.FIRST_NAME]: preregistrationData?.first_name ?? memberInfo[MEMBER_GET_FIELD_KEY.FIRST_NAME],
        [MEMBER_FIELD_KEY.LAST_NAME]: preregistrationData?.sur_name ?? memberInfo[MEMBER_GET_FIELD_KEY.LAST_NAME],
        [MEMBER_FIELD_KEY.DESCRIPTION]: preregistrationData?.description ?? memberInfo[MEMBER_GET_FIELD_KEY.DESCRIPTION],
        [MEMBER_FIELD_KEY.ORGANIZATION]: preregistrationData?.organization ?? memberInfo[MEMBER_GET_FIELD_KEY.ORGANIZATION]
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const formSetting = [
    {
      label: "ID",
      key: MEMBER_FIELD_KEY.ID,
      type: FORM_FIELD_TYPE.INPUT,
      getValueSelected: formPropsData[MEMBER_FIELD_KEY.ID],
      className: "col-12",
      inputClassName: "border",
      readOnly: true
    },
    {
      label: t("txt_first_name"),
      key: MEMBER_FIELD_KEY.FIRST_NAME,
      type: FORM_FIELD_TYPE.INPUT,
      getValueSelected: formPropsData[MEMBER_FIELD_KEY.FIRST_NAME],
      className: "col-6",
      inputClassName: "border",
      handleChange: (event) => {
        setFormPropsData({
          ...formPropsData,
          [MEMBER_FIELD_KEY.FIRST_NAME]: event.target.value
        });
      }
    },
    {
      label: t("txt_last_name"),
      key: MEMBER_FIELD_KEY.LAST_NAME,
      type: FORM_FIELD_TYPE.INPUT,
      getValueSelected: formPropsData[MEMBER_FIELD_KEY.LAST_NAME],
      className: "col-6",
      inputClassName: "border",
      handleChange: (event) => {
        setFormPropsData({
          ...formPropsData,
          [MEMBER_FIELD_KEY.LAST_NAME]: event.target.value
        });
      }
    },
    {
      label: t("txt_organization"),
      key: MEMBER_FIELD_KEY.ORGANIZATION,
      type: FORM_FIELD_TYPE.INPUT,
      getValueSelected: formPropsData[MEMBER_FIELD_KEY.ORGANIZATION],
      className: "col-12",
      inputClassName: "border",
      readOnly: true
    }
  ];
  const validator = new SimpleReactValidator();
  const save = async () => {
    setSaving(true);
    await model.save(formPropsData);
    await model.savePreregistration(jwt, formPropsData);
    setSaving(false);
  };
  const onSelectAvatar = (image) => {
    setFormPropsData({
      ...formPropsData,
      [MEMBER_FIELD_KEY.AVATAR_DAM]: image
    });
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "bg-white p-3 rounded-3" }, /* @__PURE__ */ React.createElement("div", { className: "row" }, model.formStatus === PAGE_STATUS.LOADING ? /* @__PURE__ */ React.createElement(Spinner, null) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "col-9" }, /* @__PURE__ */ React.createElement(Form, { className: "row" }, formSetting.map((field) => renderField(field, validator)))), /* @__PURE__ */ React.createElement("div", { className: "col-3" }, /* @__PURE__ */ React.createElement(Form.Group, { key: Math.random(), className: `mb-3 ` }, /* @__PURE__ */ React.createElement(Label, { text: t("txt_your_avatar"), required: false })), /* @__PURE__ */ React.createElement(
    FormDAMImage,
    {
      onChoose: onSelectAvatar,
      current: formPropsData[MEMBER_FIELD_KEY.AVATAR_DAM]
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center col-3" }, /* @__PURE__ */ React.createElement(Button, { onClick: save, text: t("txt_update"), icon: faUserCog, loading: saving }))))));
});

export {
  ProfileGeneral
};

import {
  axios_default
} from "./chunk-2EYS7YDL.js";
import {
  Spinner
} from "./chunk-POLRV4GK.js";
import {
  notify
} from "./chunk-DL5QNKBI.js";

// src/pages/Login/index.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// src/pages/Login/index.scss
var css = `@charset "UTF-8";
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.aesirxui :root,
.aesirxui [data-bs-theme=light] {
  --aesirxsso-blue: #0d6efd;
  --aesirxsso-indigo: #6610f2;
  --aesirxsso-purple: #6f42c1;
  --aesirxsso-pink: #d63384;
  --aesirxsso-red: #dc3545;
  --aesirxsso-orange: #fd7e14;
  --aesirxsso-yellow: #ffc107;
  --aesirxsso-green: #198754;
  --aesirxsso-teal: #20c997;
  --aesirxsso-cyan: #0dcaf0;
  --aesirxsso-black: #000;
  --aesirxsso-white: #fff;
  --aesirxsso-gray: #6c757d;
  --aesirxsso-gray-dark: #343a40;
  --aesirxsso-gray-100: #f8f9fa;
  --aesirxsso-gray-200: #e9ecef;
  --aesirxsso-gray-300: #dee2e6;
  --aesirxsso-gray-400: #ced4da;
  --aesirxsso-gray-500: #adb5bd;
  --aesirxsso-gray-600: #6c757d;
  --aesirxsso-gray-700: #495057;
  --aesirxsso-gray-800: #343a40;
  --aesirxsso-gray-900: #212529;
  --aesirxsso-primary: #132342;
  --aesirxsso-secondary: #627eea;
  --aesirxsso-success: #1ab394;
  --aesirxsso-info: #0dcaf0;
  --aesirxsso-warning: #ffc107;
  --aesirxsso-danger: #dc3545;
  --aesirxsso-light: #f8f9fa;
  --aesirxsso-dark: #0f1314;
  --aesirxsso-primary-rgb: 19, 35, 66;
  --aesirxsso-secondary-rgb: 98, 126, 234;
  --aesirxsso-success-rgb: 26, 179, 148;
  --aesirxsso-info-rgb: 13, 202, 240;
  --aesirxsso-warning-rgb: 255, 193, 7;
  --aesirxsso-danger-rgb: 220, 53, 69;
  --aesirxsso-light-rgb: 248, 249, 250;
  --aesirxsso-dark-rgb: 15, 19, 20;
  --aesirxsso-primary-text-emphasis: #080e1a;
  --aesirxsso-secondary-text-emphasis: #27325e;
  --aesirxsso-success-text-emphasis: #0a483b;
  --aesirxsso-info-text-emphasis: #055160;
  --aesirxsso-warning-text-emphasis: #664d03;
  --aesirxsso-danger-text-emphasis: #58151c;
  --aesirxsso-light-text-emphasis: #495057;
  --aesirxsso-dark-text-emphasis: #495057;
  --aesirxsso-primary-bg-subtle: #d0d3d9;
  --aesirxsso-secondary-bg-subtle: #e0e5fb;
  --aesirxsso-success-bg-subtle: #d1f0ea;
  --aesirxsso-info-bg-subtle: #cff4fc;
  --aesirxsso-warning-bg-subtle: #fff3cd;
  --aesirxsso-danger-bg-subtle: #f8d7da;
  --aesirxsso-light-bg-subtle: #fcfcfd;
  --aesirxsso-dark-bg-subtle: #ced4da;
  --aesirxsso-primary-border-subtle: #a1a7b3;
  --aesirxsso-secondary-border-subtle: #c0cbf7;
  --aesirxsso-success-border-subtle: #a3e1d4;
  --aesirxsso-info-border-subtle: #9eeaf9;
  --aesirxsso-warning-border-subtle: #ffe69c;
  --aesirxsso-danger-border-subtle: #f1aeb5;
  --aesirxsso-light-border-subtle: #e9ecef;
  --aesirxsso-dark-border-subtle: #adb5bd;
  --aesirxsso-white-rgb: 255, 255, 255;
  --aesirxsso-black-rgb: 0, 0, 0;
  --aesirxsso-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --aesirxsso-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --aesirxsso-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --aesirxsso-body-font-family: var(--aesirxsso-font-sans-serif);
  --aesirxsso-body-font-size: 1rem;
  --aesirxsso-body-font-weight: 400;
  --aesirxsso-body-line-height: 1.5;
  --aesirxsso-body-color: #212529;
  --aesirxsso-body-color-rgb: 33, 37, 41;
  --aesirxsso-body-bg: #fff;
  --aesirxsso-body-bg-rgb: 255, 255, 255;
  --aesirxsso-emphasis-color: #000;
  --aesirxsso-emphasis-color-rgb: 0, 0, 0;
  --aesirxsso-secondary-color: rgba(33, 37, 41, 0.75);
  --aesirxsso-secondary-color-rgb: 33, 37, 41;
  --aesirxsso-secondary-bg: #e9ecef;
  --aesirxsso-secondary-bg-rgb: 233, 236, 239;
  --aesirxsso-tertiary-color: rgba(33, 37, 41, 0.5);
  --aesirxsso-tertiary-color-rgb: 33, 37, 41;
  --aesirxsso-tertiary-bg: #f8f9fa;
  --aesirxsso-tertiary-bg-rgb: 248, 249, 250;
  --aesirxsso-heading-color: inherit;
  --aesirxsso-link-color: #132342;
  --aesirxsso-link-color-rgb: 19, 35, 66;
  --aesirxsso-link-decoration: underline;
  --aesirxsso-link-hover-color: #0f1c35;
  --aesirxsso-link-hover-color-rgb: 15, 28, 53;
  --aesirxsso-code-color: #d63384;
  --aesirxsso-highlight-color: #212529;
  --aesirxsso-highlight-bg: #fff3cd;
  --aesirxsso-border-width: 1px;
  --aesirxsso-border-style: solid;
  --aesirxsso-border-color: #dee2e6;
  --aesirxsso-border-color-translucent: rgba(0, 0, 0, 0.175);
  --aesirxsso-border-radius: 0.375rem;
  --aesirxsso-border-radius-sm: 0.25rem;
  --aesirxsso-border-radius-lg: 0.5rem;
  --aesirxsso-border-radius-xl: 1rem;
  --aesirxsso-border-radius-xxl: 2rem;
  --aesirxsso-border-radius-2xl: var(--aesirxsso-border-radius-xxl);
  --aesirxsso-border-radius-pill: 50rem;
  --aesirxsso-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --aesirxsso-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --aesirxsso-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --aesirxsso-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --aesirxsso-focus-ring-width: 0.25rem;
  --aesirxsso-focus-ring-opacity: 0.25;
  --aesirxsso-focus-ring-color: rgba(19, 35, 66, 0.25);
  --aesirxsso-form-valid-color: #1ab394;
  --aesirxsso-form-valid-border-color: #1ab394;
  --aesirxsso-form-invalid-color: #dc3545;
  --aesirxsso-form-invalid-border-color: #dc3545;
}
.aesirxui [data-bs-theme=dark] {
  color-scheme: dark;
  --aesirxsso-body-color: #dee2e6;
  --aesirxsso-body-color-rgb: 222, 226, 230;
  --aesirxsso-body-bg: #212529;
  --aesirxsso-body-bg-rgb: 33, 37, 41;
  --aesirxsso-emphasis-color: #fff;
  --aesirxsso-emphasis-color-rgb: 255, 255, 255;
  --aesirxsso-secondary-color: rgba(222, 226, 230, 0.75);
  --aesirxsso-secondary-color-rgb: 222, 226, 230;
  --aesirxsso-secondary-bg: #343a40;
  --aesirxsso-secondary-bg-rgb: 52, 58, 64;
  --aesirxsso-tertiary-color: rgba(222, 226, 230, 0.5);
  --aesirxsso-tertiary-color-rgb: 222, 226, 230;
  --aesirxsso-tertiary-bg: #2b3035;
  --aesirxsso-tertiary-bg-rgb: 43, 48, 53;
  --aesirxsso-primary-text-emphasis: #717b8e;
  --aesirxsso-secondary-text-emphasis: #a1b2f2;
  --aesirxsso-success-text-emphasis: #76d1bf;
  --aesirxsso-info-text-emphasis: #6edff6;
  --aesirxsso-warning-text-emphasis: #ffda6a;
  --aesirxsso-danger-text-emphasis: #ea868f;
  --aesirxsso-light-text-emphasis: #f8f9fa;
  --aesirxsso-dark-text-emphasis: #dee2e6;
  --aesirxsso-primary-bg-subtle: #04070d;
  --aesirxsso-secondary-bg-subtle: #14192f;
  --aesirxsso-success-bg-subtle: #05241e;
  --aesirxsso-info-bg-subtle: #032830;
  --aesirxsso-warning-bg-subtle: #332701;
  --aesirxsso-danger-bg-subtle: #2c0b0e;
  --aesirxsso-light-bg-subtle: #343a40;
  --aesirxsso-dark-bg-subtle: #1a1d20;
  --aesirxsso-primary-border-subtle: #0b1528;
  --aesirxsso-secondary-border-subtle: #3b4c8c;
  --aesirxsso-success-border-subtle: #106b59;
  --aesirxsso-info-border-subtle: #087990;
  --aesirxsso-warning-border-subtle: #997404;
  --aesirxsso-danger-border-subtle: #842029;
  --aesirxsso-light-border-subtle: #495057;
  --aesirxsso-dark-border-subtle: #343a40;
  --aesirxsso-heading-color: inherit;
  --aesirxsso-link-color: #717b8e;
  --aesirxsso-link-hover-color: #8d95a5;
  --aesirxsso-link-color-rgb: 113, 123, 142;
  --aesirxsso-link-hover-color-rgb: 141, 149, 165;
  --aesirxsso-code-color: #e685b5;
  --aesirxsso-highlight-color: #dee2e6;
  --aesirxsso-highlight-bg: #664d03;
  --aesirxsso-border-color: #495057;
  --aesirxsso-border-color-translucent: rgba(255, 255, 255, 0.15);
  --aesirxsso-form-valid-color: #75b798;
  --aesirxsso-form-valid-border-color: #75b798;
  --aesirxsso-form-invalid-color: #ea868f;
  --aesirxsso-form-invalid-border-color: #ea868f;
}
.aesirxui .aesirxsso {
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  /* rtl:begin:remove */
  /* rtl:end:remove */
}
.aesirxui .aesirxsso *,
.aesirxui .aesirxsso *::before,
.aesirxui .aesirxsso *::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  .aesirxui .aesirxsso :root {
    scroll-behavior: smooth;
  }
}
.aesirxui .aesirxsso body {
  margin: 0;
  font-family: var(--aesirxsso-body-font-family);
  font-size: var(--aesirxsso-body-font-size);
  font-weight: var(--aesirxsso-body-font-weight);
  line-height: var(--aesirxsso-body-line-height);
  color: var(--aesirxsso-body-color);
  text-align: var(--aesirxsso-body-text-align);
  background-color: var(--aesirxsso-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.aesirxui .aesirxsso hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--aesirxsso-border-width) solid;
  opacity: 0.25;
}
.aesirxui .aesirxsso h6, .aesirxui .aesirxsso .h6, .aesirxui .aesirxsso h5, .aesirxui .aesirxsso .h5, .aesirxui .aesirxsso h4, .aesirxui .aesirxsso .h4, .aesirxui .aesirxsso h3, .aesirxui .aesirxsso .h3, .aesirxui .aesirxsso h2, .aesirxui .aesirxsso .h2, .aesirxui .aesirxsso h1, .aesirxui .aesirxsso .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--aesirxsso-heading-color);
}
.aesirxui .aesirxsso h1, .aesirxui .aesirxsso .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso h1, .aesirxui .aesirxsso .h1 {
    font-size: 2.5rem;
  }
}
.aesirxui .aesirxsso h2, .aesirxui .aesirxsso .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso h2, .aesirxui .aesirxsso .h2 {
    font-size: 2rem;
  }
}
.aesirxui .aesirxsso h3, .aesirxui .aesirxsso .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso h3, .aesirxui .aesirxsso .h3 {
    font-size: 1.75rem;
  }
}
.aesirxui .aesirxsso h4, .aesirxui .aesirxsso .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso h4, .aesirxui .aesirxsso .h4 {
    font-size: 1.5rem;
  }
}
.aesirxui .aesirxsso h5, .aesirxui .aesirxsso .h5 {
  font-size: 1.25rem;
}
.aesirxui .aesirxsso h6, .aesirxui .aesirxsso .h6 {
  font-size: 1rem;
}
.aesirxui .aesirxsso p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.aesirxui .aesirxsso abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
.aesirxui .aesirxsso address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.aesirxui .aesirxsso ol,
.aesirxui .aesirxsso ul {
  padding-left: 2rem;
}
.aesirxui .aesirxsso ol,
.aesirxui .aesirxsso ul,
.aesirxui .aesirxsso dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.aesirxui .aesirxsso ol ol,
.aesirxui .aesirxsso ul ul,
.aesirxui .aesirxsso ol ul,
.aesirxui .aesirxsso ul ol {
  margin-bottom: 0;
}
.aesirxui .aesirxsso dt {
  font-weight: 700;
}
.aesirxui .aesirxsso dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.aesirxui .aesirxsso blockquote {
  margin: 0 0 1rem;
}
.aesirxui .aesirxsso b,
.aesirxui .aesirxsso strong {
  font-weight: bolder;
}
.aesirxui .aesirxsso small, .aesirxui .aesirxsso .small {
  font-size: 0.875em;
}
.aesirxui .aesirxsso mark, .aesirxui .aesirxsso .mark {
  padding: 0.1875em;
  color: var(--aesirxsso-highlight-color);
  background-color: var(--aesirxsso-highlight-bg);
}
.aesirxui .aesirxsso sub,
.aesirxui .aesirxsso sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
.aesirxui .aesirxsso sub {
  bottom: -0.25em;
}
.aesirxui .aesirxsso sup {
  top: -0.5em;
}
.aesirxui .aesirxsso a {
  color: rgba(var(--aesirxsso-link-color-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration: underline;
}
.aesirxui .aesirxsso a:hover {
  --aesirxsso-link-color-rgb: var(--aesirxsso-link-hover-color-rgb);
}
.aesirxui .aesirxsso a:not([href]):not([class]), .aesirxui .aesirxsso a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.aesirxui .aesirxsso pre,
.aesirxui .aesirxsso code,
.aesirxui .aesirxsso kbd,
.aesirxui .aesirxsso samp {
  font-family: var(--aesirxsso-font-monospace);
  font-size: 1em;
}
.aesirxui .aesirxsso pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
.aesirxui .aesirxsso pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.aesirxui .aesirxsso code {
  font-size: 0.875em;
  color: var(--aesirxsso-code-color);
  word-wrap: break-word;
}
a > .aesirxui .aesirxsso code {
  color: inherit;
}
.aesirxui .aesirxsso kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--aesirxsso-body-bg);
  background-color: var(--aesirxsso-body-color);
  border-radius: 0.25rem;
}
.aesirxui .aesirxsso kbd kbd {
  padding: 0;
  font-size: 1em;
}
.aesirxui .aesirxsso figure {
  margin: 0 0 1rem;
}
.aesirxui .aesirxsso img,
.aesirxui .aesirxsso svg {
  vertical-align: middle;
}
.aesirxui .aesirxsso table {
  caption-side: bottom;
  border-collapse: collapse;
}
.aesirxui .aesirxsso caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--aesirxsso-secondary-color);
  text-align: left;
}
.aesirxui .aesirxsso th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.aesirxui .aesirxsso thead,
.aesirxui .aesirxsso tbody,
.aesirxui .aesirxsso tfoot,
.aesirxui .aesirxsso tr,
.aesirxui .aesirxsso td,
.aesirxui .aesirxsso th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.aesirxui .aesirxsso label {
  display: inline-block;
}
.aesirxui .aesirxsso button {
  border-radius: 0;
}
.aesirxui .aesirxsso button:focus:not(:focus-visible) {
  outline: 0;
}
.aesirxui .aesirxsso input,
.aesirxui .aesirxsso button,
.aesirxui .aesirxsso select,
.aesirxui .aesirxsso optgroup,
.aesirxui .aesirxsso textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.aesirxui .aesirxsso button,
.aesirxui .aesirxsso select {
  text-transform: none;
}
.aesirxui .aesirxsso [role=button] {
  cursor: pointer;
}
.aesirxui .aesirxsso select {
  word-wrap: normal;
}
.aesirxui .aesirxsso select:disabled {
  opacity: 1;
}
.aesirxui .aesirxsso [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}
.aesirxui .aesirxsso button,
.aesirxui .aesirxsso [type=button],
.aesirxui .aesirxsso [type=reset],
.aesirxui .aesirxsso [type=submit] {
  -webkit-appearance: button;
}
.aesirxui .aesirxsso button:not(:disabled),
.aesirxui .aesirxsso [type=button]:not(:disabled),
.aesirxui .aesirxsso [type=reset]:not(:disabled),
.aesirxui .aesirxsso [type=submit]:not(:disabled) {
  cursor: pointer;
}
.aesirxui .aesirxsso ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.aesirxui .aesirxsso textarea {
  resize: vertical;
}
.aesirxui .aesirxsso fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.aesirxui .aesirxsso legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso legend {
    font-size: 1.5rem;
  }
}
.aesirxui .aesirxsso legend + * {
  clear: left;
}
.aesirxui .aesirxsso ::-webkit-datetime-edit-fields-wrapper,
.aesirxui .aesirxsso ::-webkit-datetime-edit-text,
.aesirxui .aesirxsso ::-webkit-datetime-edit-minute,
.aesirxui .aesirxsso ::-webkit-datetime-edit-hour-field,
.aesirxui .aesirxsso ::-webkit-datetime-edit-day-field,
.aesirxui .aesirxsso ::-webkit-datetime-edit-month-field,
.aesirxui .aesirxsso ::-webkit-datetime-edit-year-field {
  padding: 0;
}
.aesirxui .aesirxsso ::-webkit-inner-spin-button {
  height: auto;
}
.aesirxui .aesirxsso [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.aesirxui .aesirxsso ::-webkit-search-decoration {
  -webkit-appearance: none;
}
.aesirxui .aesirxsso ::-webkit-color-swatch-wrapper {
  padding: 0;
}
.aesirxui .aesirxsso ::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
.aesirxui .aesirxsso output {
  display: inline-block;
}
.aesirxui .aesirxsso iframe {
  border: 0;
}
.aesirxui .aesirxsso summary {
  display: list-item;
  cursor: pointer;
}
.aesirxui .aesirxsso progress {
  vertical-align: baseline;
}
.aesirxui .aesirxsso [hidden] {
  display: none !important;
}
.aesirxui .aesirxsso .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.aesirxui .aesirxsso .display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .display-1 {
    font-size: 5rem;
  }
}
.aesirxui .aesirxsso .display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .display-2 {
    font-size: 4.5rem;
  }
}
.aesirxui .aesirxsso .display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .display-3 {
    font-size: 4rem;
  }
}
.aesirxui .aesirxsso .display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .display-4 {
    font-size: 3.5rem;
  }
}
.aesirxui .aesirxsso .display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .display-5 {
    font-size: 3rem;
  }
}
.aesirxui .aesirxsso .display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .display-6 {
    font-size: 2.5rem;
  }
}
.aesirxui .aesirxsso .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.aesirxui .aesirxsso .list-inline {
  padding-left: 0;
  list-style: none;
}
.aesirxui .aesirxsso .list-inline-item {
  display: inline-block;
}
.aesirxui .aesirxsso .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.aesirxui .aesirxsso .initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.aesirxui .aesirxsso .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.aesirxui .aesirxsso .blockquote > :last-child {
  margin-bottom: 0;
}
.aesirxui .aesirxsso .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.aesirxui .aesirxsso .blockquote-footer::before {
  content: "\u2014\xA0";
}
.aesirxui .aesirxsso .modal {
  --aesirxsso-modal-zindex: 1055;
  --aesirxsso-modal-width: 846px;
  --aesirxsso-modal-padding: 1rem;
  --aesirxsso-modal-margin: 0.5rem;
  --aesirxsso-modal-color: ;
  --aesirxsso-modal-bg: var(--aesirxsso-body-bg);
  --aesirxsso-modal-border-color: var(--aesirxsso-border-color-translucent);
  --aesirxsso-modal-border-width: var(--aesirxsso-border-width);
  --aesirxsso-modal-border-radius: var(--aesirxsso-border-radius-lg);
  --aesirxsso-modal-box-shadow: var(--aesirxsso-box-shadow-sm);
  --aesirxsso-modal-inner-border-radius: calc(var(--aesirxsso-border-radius-lg) - (var(--aesirxsso-border-width)));
  --aesirxsso-modal-header-padding-x: 1rem;
  --aesirxsso-modal-header-padding-y: 1rem;
  --aesirxsso-modal-header-padding: 1rem 1rem;
  --aesirxsso-modal-header-border-color: var(--aesirxsso-border-color);
  --aesirxsso-modal-header-border-width: var(--aesirxsso-border-width);
  --aesirxsso-modal-title-line-height: 1.5;
  --aesirxsso-modal-footer-gap: 0.5rem;
  --aesirxsso-modal-footer-bg: ;
  --aesirxsso-modal-footer-border-color: var(--aesirxsso-border-color);
  --aesirxsso-modal-footer-border-width: var(--aesirxsso-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--aesirxsso-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.aesirxui .aesirxsso .modal-dialog {
  position: relative;
  width: auto;
  margin: var(--aesirxsso-modal-margin);
  pointer-events: none;
}
.modal.fade .aesirxui .aesirxsso .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .aesirxui .aesirxsso .modal-dialog {
    transition: none;
  }
}
.modal.show .aesirxui .aesirxsso .modal-dialog {
  transform: none;
}
.modal.modal-static .aesirxui .aesirxsso .modal-dialog {
  transform: scale(1.02);
}
.aesirxui .aesirxsso .modal-dialog-scrollable {
  height: calc(100% - var(--aesirxsso-modal-margin) * 2);
}
.aesirxui .aesirxsso .modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.aesirxui .aesirxsso .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.aesirxui .aesirxsso .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--aesirxsso-modal-margin) * 2);
}
.aesirxui .aesirxsso .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--aesirxsso-modal-color);
  pointer-events: auto;
  background-color: var(--aesirxsso-modal-bg);
  background-clip: padding-box;
  border: var(--aesirxsso-modal-border-width) solid var(--aesirxsso-modal-border-color);
  border-radius: var(--aesirxsso-modal-border-radius);
  outline: 0;
}
.aesirxui .aesirxsso .modal-backdrop {
  --aesirxsso-backdrop-zindex: 1050;
  --aesirxsso-backdrop-bg: #000;
  --aesirxsso-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--aesirxsso-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--aesirxsso-backdrop-bg);
}
.aesirxui .aesirxsso .modal-backdrop.fade {
  opacity: 0;
}
.aesirxui .aesirxsso .modal-backdrop.show {
  opacity: var(--aesirxsso-backdrop-opacity);
}
.aesirxui .aesirxsso .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--aesirxsso-modal-header-padding);
  border-bottom: var(--aesirxsso-modal-header-border-width) solid var(--aesirxsso-modal-header-border-color);
  border-top-left-radius: var(--aesirxsso-modal-inner-border-radius);
  border-top-right-radius: var(--aesirxsso-modal-inner-border-radius);
}
.aesirxui .aesirxsso .modal-header .btn-close {
  padding: calc(var(--aesirxsso-modal-header-padding-y) * 0.5) calc(var(--aesirxsso-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--aesirxsso-modal-header-padding-y)) calc(-0.5 * var(--aesirxsso-modal-header-padding-x)) calc(-0.5 * var(--aesirxsso-modal-header-padding-y)) auto;
}
.aesirxui .aesirxsso .modal-title {
  margin-bottom: 0;
  line-height: var(--aesirxsso-modal-title-line-height);
}
.aesirxui .aesirxsso .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--aesirxsso-modal-padding);
}
.aesirxui .aesirxsso .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--aesirxsso-modal-padding) - var(--aesirxsso-modal-footer-gap) * 0.5);
  background-color: var(--aesirxsso-modal-footer-bg);
  border-top: var(--aesirxsso-modal-footer-border-width) solid var(--aesirxsso-modal-footer-border-color);
  border-bottom-right-radius: var(--aesirxsso-modal-inner-border-radius);
  border-bottom-left-radius: var(--aesirxsso-modal-inner-border-radius);
}
.aesirxui .aesirxsso .modal-footer > * {
  margin: calc(var(--aesirxsso-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .aesirxui .aesirxsso .modal {
    --aesirxsso-modal-margin: 1.75rem;
    --aesirxsso-modal-box-shadow: var(--aesirxsso-box-shadow);
  }
  .aesirxui .aesirxsso .modal-dialog {
    max-width: var(--aesirxsso-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .aesirxui .aesirxsso .modal-sm {
    --aesirxsso-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .aesirxui .aesirxsso .modal-lg,
  .aesirxui .aesirxsso .modal-xl {
    --aesirxsso-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .modal-xl {
    --aesirxsso-modal-width: 846px;
  }
}
.aesirxui .aesirxsso .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.aesirxui .aesirxsso .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.aesirxui .aesirxsso .modal-fullscreen .modal-header,
.aesirxui .aesirxsso .modal-fullscreen .modal-footer {
  border-radius: 0;
}
.aesirxui .aesirxsso .modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .aesirxui .aesirxsso .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-sm-down .modal-header,
  .aesirxui .aesirxsso .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .aesirxui .aesirxsso .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-md-down .modal-header,
  .aesirxui .aesirxsso .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .aesirxui .aesirxsso .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-lg-down .modal-header,
  .aesirxui .aesirxsso .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .aesirxui .aesirxsso .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-xl-down .modal-header,
  .aesirxui .aesirxsso .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .aesirxui .aesirxsso .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-xxl-down .modal-header,
  .aesirxui .aesirxsso .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .aesirxui .aesirxsso .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.aesirxui .aesirxsso .form-label {
  margin-bottom: 0.5rem;
}
.aesirxui .aesirxsso .col-form-label {
  padding-top: calc(0.375rem + var(--aesirxsso-border-width));
  padding-bottom: calc(0.375rem + var(--aesirxsso-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.aesirxui .aesirxsso .col-form-label-lg {
  padding-top: calc(0.5rem + var(--aesirxsso-border-width));
  padding-bottom: calc(0.5rem + var(--aesirxsso-border-width));
  font-size: 1.25rem;
}
.aesirxui .aesirxsso .col-form-label-sm {
  padding-top: calc(0.25rem + var(--aesirxsso-border-width));
  padding-bottom: calc(0.25rem + var(--aesirxsso-border-width));
  font-size: 0.875rem;
}
.aesirxui .aesirxsso .form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--aesirxsso-secondary-color);
}
.aesirxui .aesirxsso .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--aesirxsso-body-color);
  appearance: none;
  background-color: var(--aesirxsso-body-bg);
  background-clip: padding-box;
  border: var(--aesirxsso-border-width) solid var(--aesirxsso-border-color);
  border-radius: var(--aesirxsso-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-control {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-control[type=file] {
  overflow: hidden;
}
.aesirxui .aesirxsso .form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.aesirxui .aesirxsso .form-control:focus {
  color: var(--aesirxsso-body-color);
  background-color: var(--aesirxsso-body-bg);
  border-color: #8991a1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 35, 66, 0.25);
}
.aesirxui .aesirxsso .form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.aesirxui .aesirxsso .form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.aesirxui .aesirxsso .form-control::placeholder {
  color: var(--aesirxsso-secondary-color);
  opacity: 1;
}
.aesirxui .aesirxsso .form-control:disabled {
  background-color: var(--aesirxsso-secondary-bg);
  opacity: 1;
}
.aesirxui .aesirxsso .form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--aesirxsso-body-color);
  background-color: var(--aesirxsso-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--aesirxsso-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-control::file-selector-button {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--aesirxsso-secondary-bg);
}
.aesirxui .aesirxsso .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--aesirxsso-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--aesirxsso-border-width) 0;
}
.aesirxui .aesirxsso .form-control-plaintext:focus {
  outline: 0;
}
.aesirxui .aesirxsso .form-control-plaintext.form-control-sm, .aesirxui .aesirxsso .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.aesirxui .aesirxsso .form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--aesirxsso-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.aesirxui .aesirxsso .form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--aesirxsso-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.aesirxui .aesirxsso textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--aesirxsso-border-width) * 2));
}
.aesirxui .aesirxsso textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--aesirxsso-border-width) * 2));
}
.aesirxui .aesirxsso textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--aesirxsso-border-width) * 2));
}
.aesirxui .aesirxsso .form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--aesirxsso-border-width) * 2));
  padding: 0.375rem;
}
.aesirxui .aesirxsso .form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.aesirxui .aesirxsso .form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--aesirxsso-border-width) * 2));
}
.aesirxui .aesirxsso .form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--aesirxsso-border-width) * 2));
}
.aesirxui .aesirxsso .form-select {
  --aesirxsso-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--aesirxsso-body-color);
  appearance: none;
  background-color: var(--aesirxsso-body-bg);
  background-image: var(--aesirxsso-form-select-bg-img), var(--aesirxsso-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--aesirxsso-border-width) solid var(--aesirxsso-border-color);
  border-radius: var(--aesirxsso-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-select {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-select:focus {
  border-color: #8991a1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 35, 66, 0.25);
}
.aesirxui .aesirxsso .form-select[multiple], .aesirxui .aesirxsso .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.aesirxui .aesirxsso .form-select:disabled {
  background-color: var(--aesirxsso-secondary-bg);
}
.aesirxui .aesirxsso .form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--aesirxsso-body-color);
}
.aesirxui .aesirxsso .form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso [data-bs-theme=dark] .form-select {
  --aesirxsso-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.aesirxui .aesirxsso .form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.aesirxui .aesirxsso .form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.aesirxui .aesirxsso .form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}
.aesirxui .aesirxsso .form-check-input {
  --aesirxsso-form-check-bg: var(--aesirxsso-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--aesirxsso-form-check-bg);
  background-image: var(--aesirxsso-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--aesirxsso-border-width) solid var(--aesirxsso-border-color);
  print-color-adjust: exact;
}
.aesirxui .aesirxsso .form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.aesirxui .aesirxsso .form-check-input[type=radio] {
  border-radius: 50%;
}
.aesirxui .aesirxsso .form-check-input:active {
  filter: brightness(90%);
}
.aesirxui .aesirxsso .form-check-input:focus {
  border-color: #8991a1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 35, 66, 0.25);
}
.aesirxui .aesirxsso .form-check-input:checked {
  background-color: #132342;
  border-color: #132342;
}
.aesirxui .aesirxsso .form-check-input:checked[type=checkbox] {
  --aesirxsso-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-check-input:checked[type=radio] {
  --aesirxsso-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-check-input[type=checkbox]:indeterminate {
  background-color: #132342;
  border-color: #132342;
  --aesirxsso-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.aesirxui .aesirxsso .form-check-input[disabled] ~ .form-check-label, .aesirxui .aesirxsso .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.aesirxui .aesirxsso .form-switch {
  padding-left: 2.5em;
}
.aesirxui .aesirxsso .form-switch .form-check-input {
  --aesirxsso-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--aesirxsso-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-switch .form-check-input {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-switch .form-check-input:focus {
  --aesirxsso-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238991a1'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-switch .form-check-input:checked {
  background-position: right center;
  --aesirxsso-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.aesirxui .aesirxsso .form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}
.aesirxui .aesirxsso .form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.aesirxui .aesirxsso .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.aesirxui .aesirxsso .btn-check[disabled] + .btn, .aesirxui .aesirxsso .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.aesirxui .aesirxsso [data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --aesirxsso-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}
.aesirxui .aesirxsso .form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.aesirxui .aesirxsso .form-range:focus {
  outline: 0;
}
.aesirxui .aesirxsso .form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(19, 35, 66, 0.25);
}
.aesirxui .aesirxsso .form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(19, 35, 66, 0.25);
}
.aesirxui .aesirxsso .form-range::-moz-focus-outer {
  border: 0;
}
.aesirxui .aesirxsso .form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #132342;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-range::-webkit-slider-thumb:active {
  background-color: #b8bdc6;
}
.aesirxui .aesirxsso .form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--aesirxsso-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.aesirxui .aesirxsso .form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #132342;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-range::-moz-range-thumb {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-range::-moz-range-thumb:active {
  background-color: #b8bdc6;
}
.aesirxui .aesirxsso .form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--aesirxsso-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.aesirxui .aesirxsso .form-range:disabled {
  pointer-events: none;
}
.aesirxui .aesirxsso .form-range:disabled::-webkit-slider-thumb {
  background-color: var(--aesirxsso-secondary-color);
}
.aesirxui .aesirxsso .form-range:disabled::-moz-range-thumb {
  background-color: var(--aesirxsso-secondary-color);
}
.aesirxui .aesirxsso .form-floating {
  position: relative;
}
.aesirxui .aesirxsso .form-floating > .form-control,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext,
.aesirxui .aesirxsso .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--aesirxsso-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--aesirxsso-border-width) * 2));
  line-height: 1.25;
}
.aesirxui .aesirxsso .form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--aesirxsso-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .form-floating > label {
    transition: none;
  }
}
.aesirxui .aesirxsso .form-floating > .form-control,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.aesirxui .aesirxsso .form-floating > .form-control::placeholder,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.aesirxui .aesirxsso .form-floating > .form-control:focus, .aesirxui .aesirxsso .form-floating > .form-control:not(:placeholder-shown),
.aesirxui .aesirxsso .form-floating > .form-control-plaintext:focus,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.aesirxui .aesirxsso .form-floating > .form-control:-webkit-autofill,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.aesirxui .aesirxsso .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.aesirxui .aesirxsso .form-floating > .form-control:focus ~ label,
.aesirxui .aesirxsso .form-floating > .form-control:not(:placeholder-shown) ~ label,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext ~ label,
.aesirxui .aesirxsso .form-floating > .form-select ~ label {
  color: rgba(var(--aesirxsso-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.aesirxui .aesirxsso .form-floating > .form-control:focus ~ label::after,
.aesirxui .aesirxsso .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.aesirxui .aesirxsso .form-floating > .form-control-plaintext ~ label::after,
.aesirxui .aesirxsso .form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--aesirxsso-body-bg);
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--aesirxsso-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.aesirxui .aesirxsso .form-floating > .form-control-plaintext ~ label {
  border-width: var(--aesirxsso-border-width) 0;
}
.aesirxui .aesirxsso .form-floating > :disabled ~ label,
.aesirxui .aesirxsso .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.aesirxui .aesirxsso .form-floating > :disabled ~ label::after,
.aesirxui .aesirxsso .form-floating > .form-control:disabled ~ label::after {
  background-color: var(--aesirxsso-secondary-bg);
}
.aesirxui .aesirxsso .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.aesirxui .aesirxsso .input-group > .form-control,
.aesirxui .aesirxsso .input-group > .form-select,
.aesirxui .aesirxsso .input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.aesirxui .aesirxsso .input-group > .form-control:focus,
.aesirxui .aesirxsso .input-group > .form-select:focus,
.aesirxui .aesirxsso .input-group > .form-floating:focus-within {
  z-index: 5;
}
.aesirxui .aesirxsso .input-group .btn {
  position: relative;
  z-index: 2;
}
.aesirxui .aesirxsso .input-group .btn:focus {
  z-index: 5;
}
.aesirxui .aesirxsso .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--aesirxsso-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--aesirxsso-tertiary-bg);
  border: var(--aesirxsso-border-width) solid var(--aesirxsso-border-color);
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .input-group-lg > .form-control,
.aesirxui .aesirxsso .input-group-lg > .form-select,
.aesirxui .aesirxsso .input-group-lg > .input-group-text,
.aesirxui .aesirxsso .input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .input-group-sm > .form-control,
.aesirxui .aesirxsso .input-group-sm > .form-select,
.aesirxui .aesirxsso .input-group-sm > .input-group-text,
.aesirxui .aesirxsso .input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .input-group-lg > .form-select,
.aesirxui .aesirxsso .input-group-sm > .form-select {
  padding-right: 3rem;
}
.aesirxui .aesirxsso .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.aesirxui .aesirxsso .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.aesirxui .aesirxsso .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.aesirxui .aesirxsso .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.aesirxui .aesirxsso .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.aesirxui .aesirxsso .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.aesirxui .aesirxsso .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.aesirxui .aesirxsso .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.aesirxui .aesirxsso .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--aesirxsso-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.aesirxui .aesirxsso .input-group > .form-floating:not(:first-child) > .form-control,
.aesirxui .aesirxsso .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.aesirxui .aesirxsso .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--aesirxsso-form-valid-color);
}
.aesirxui .aesirxsso .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--aesirxsso-success);
  border-radius: var(--aesirxsso-border-radius);
}
.was-validated .aesirxui .aesirxsso:valid ~ .valid-feedback,
.was-validated .aesirxui .aesirxsso:valid ~ .valid-tooltip, .aesirxui .aesirxsso.is-valid ~ .valid-feedback,
.aesirxui .aesirxsso.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .aesirxui .aesirxsso .form-control:valid, .aesirxui .aesirxsso .form-control.is-valid {
  border-color: var(--aesirxsso-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231ab394' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .aesirxui .aesirxsso .form-control:valid:focus, .aesirxui .aesirxsso .form-control.is-valid:focus {
  border-color: var(--aesirxsso-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-success-rgb), 0.25);
}
.was-validated .aesirxui .aesirxsso textarea.form-control:valid, .aesirxui .aesirxsso textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .aesirxui .aesirxsso .form-select:valid, .aesirxui .aesirxsso .form-select.is-valid {
  border-color: var(--aesirxsso-form-valid-border-color);
}
.was-validated .aesirxui .aesirxsso .form-select:valid:not([multiple]):not([size]), .was-validated .aesirxui .aesirxsso .form-select:valid:not([multiple])[size="1"], .aesirxui .aesirxsso .form-select.is-valid:not([multiple]):not([size]), .aesirxui .aesirxsso .form-select.is-valid:not([multiple])[size="1"] {
  --aesirxsso-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231ab394' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .aesirxui .aesirxsso .form-select:valid:focus, .aesirxui .aesirxsso .form-select.is-valid:focus {
  border-color: var(--aesirxsso-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-success-rgb), 0.25);
}
.was-validated .aesirxui .aesirxsso .form-control-color:valid, .aesirxui .aesirxsso .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.was-validated .aesirxui .aesirxsso .form-check-input:valid, .aesirxui .aesirxsso .form-check-input.is-valid {
  border-color: var(--aesirxsso-form-valid-border-color);
}
.was-validated .aesirxui .aesirxsso .form-check-input:valid:checked, .aesirxui .aesirxsso .form-check-input.is-valid:checked {
  background-color: var(--aesirxsso-form-valid-color);
}
.was-validated .aesirxui .aesirxsso .form-check-input:valid:focus, .aesirxui .aesirxsso .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-success-rgb), 0.25);
}
.was-validated .aesirxui .aesirxsso .form-check-input:valid ~ .form-check-label, .aesirxui .aesirxsso .form-check-input.is-valid ~ .form-check-label {
  color: var(--aesirxsso-form-valid-color);
}
.aesirxui .aesirxsso .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .aesirxui .aesirxsso .input-group > .form-control:not(:focus):valid, .aesirxui .aesirxsso .input-group > .form-control:not(:focus).is-valid,
.was-validated .aesirxui .aesirxsso .input-group > .form-select:not(:focus):valid,
.aesirxui .aesirxsso .input-group > .form-select:not(:focus).is-valid,
.was-validated .aesirxui .aesirxsso .input-group > .form-floating:not(:focus-within):valid,
.aesirxui .aesirxsso .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
.aesirxui .aesirxsso .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--aesirxsso-form-invalid-color);
}
.aesirxui .aesirxsso .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--aesirxsso-danger);
  border-radius: var(--aesirxsso-border-radius);
}
.was-validated .aesirxui .aesirxsso:invalid ~ .invalid-feedback,
.was-validated .aesirxui .aesirxsso:invalid ~ .invalid-tooltip, .aesirxui .aesirxsso.is-invalid ~ .invalid-feedback,
.aesirxui .aesirxsso.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .aesirxui .aesirxsso .form-control:invalid, .aesirxui .aesirxsso .form-control.is-invalid {
  border-color: var(--aesirxsso-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .aesirxui .aesirxsso .form-control:invalid:focus, .aesirxui .aesirxsso .form-control.is-invalid:focus {
  border-color: var(--aesirxsso-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-danger-rgb), 0.25);
}
.was-validated .aesirxui .aesirxsso textarea.form-control:invalid, .aesirxui .aesirxsso textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .aesirxui .aesirxsso .form-select:invalid, .aesirxui .aesirxsso .form-select.is-invalid {
  border-color: var(--aesirxsso-form-invalid-border-color);
}
.was-validated .aesirxui .aesirxsso .form-select:invalid:not([multiple]):not([size]), .was-validated .aesirxui .aesirxsso .form-select:invalid:not([multiple])[size="1"], .aesirxui .aesirxsso .form-select.is-invalid:not([multiple]):not([size]), .aesirxui .aesirxsso .form-select.is-invalid:not([multiple])[size="1"] {
  --aesirxsso-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .aesirxui .aesirxsso .form-select:invalid:focus, .aesirxui .aesirxsso .form-select.is-invalid:focus {
  border-color: var(--aesirxsso-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-danger-rgb), 0.25);
}
.was-validated .aesirxui .aesirxsso .form-control-color:invalid, .aesirxui .aesirxsso .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.was-validated .aesirxui .aesirxsso .form-check-input:invalid, .aesirxui .aesirxsso .form-check-input.is-invalid {
  border-color: var(--aesirxsso-form-invalid-border-color);
}
.was-validated .aesirxui .aesirxsso .form-check-input:invalid:checked, .aesirxui .aesirxsso .form-check-input.is-invalid:checked {
  background-color: var(--aesirxsso-form-invalid-color);
}
.was-validated .aesirxui .aesirxsso .form-check-input:invalid:focus, .aesirxui .aesirxsso .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-danger-rgb), 0.25);
}
.was-validated .aesirxui .aesirxsso .form-check-input:invalid ~ .form-check-label, .aesirxui .aesirxsso .form-check-input.is-invalid ~ .form-check-label {
  color: var(--aesirxsso-form-invalid-color);
}
.aesirxui .aesirxsso .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .aesirxui .aesirxsso .input-group > .form-control:not(:focus):invalid, .aesirxui .aesirxsso .input-group > .form-control:not(:focus).is-invalid,
.was-validated .aesirxui .aesirxsso .input-group > .form-select:not(:focus):invalid,
.aesirxui .aesirxsso .input-group > .form-select:not(:focus).is-invalid,
.was-validated .aesirxui .aesirxsso .input-group > .form-floating:not(:focus-within):invalid,
.aesirxui .aesirxsso .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
.aesirxui .aesirxsso .btn-group,
.aesirxui .aesirxsso .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.aesirxui .aesirxsso .btn-group > .btn,
.aesirxui .aesirxsso .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.aesirxui .aesirxsso .btn-group > .btn-check:checked + .btn,
.aesirxui .aesirxsso .btn-group > .btn-check:focus + .btn,
.aesirxui .aesirxsso .btn-group > .btn:hover,
.aesirxui .aesirxsso .btn-group > .btn:focus,
.aesirxui .aesirxsso .btn-group > .btn:active,
.aesirxui .aesirxsso .btn-group > .btn.active,
.aesirxui .aesirxsso .btn-group-vertical > .btn-check:checked + .btn,
.aesirxui .aesirxsso .btn-group-vertical > .btn-check:focus + .btn,
.aesirxui .aesirxsso .btn-group-vertical > .btn:hover,
.aesirxui .aesirxsso .btn-group-vertical > .btn:focus,
.aesirxui .aesirxsso .btn-group-vertical > .btn:active,
.aesirxui .aesirxsso .btn-group-vertical > .btn.active {
  z-index: 1;
}
.aesirxui .aesirxsso .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.aesirxui .aesirxsso .btn-toolbar .input-group {
  width: auto;
}
.aesirxui .aesirxsso .btn-group {
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .btn-group > :not(.btn-check:first-child) + .btn,
.aesirxui .aesirxsso .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--aesirxsso-border-width) * -1);
}
.aesirxui .aesirxsso .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.aesirxui .aesirxsso .btn-group > .btn.dropdown-toggle-split:first-child,
.aesirxui .aesirxsso .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.aesirxui .aesirxsso .btn-group > .btn:nth-child(n+3),
.aesirxui .aesirxsso .btn-group > :not(.btn-check) + .btn,
.aesirxui .aesirxsso .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.aesirxui .aesirxsso .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.aesirxui .aesirxsso .dropdown-toggle-split::after, .dropup .aesirxui .aesirxsso .dropdown-toggle-split::after, .dropend .aesirxui .aesirxsso .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .aesirxui .aesirxsso .dropdown-toggle-split::before {
  margin-right: 0;
}
.aesirxui .aesirxsso .btn-sm + .dropdown-toggle-split, .aesirxui .aesirxsso .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.aesirxui .aesirxsso .btn-lg + .dropdown-toggle-split, .aesirxui .aesirxsso .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.aesirxui .aesirxsso .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.aesirxui .aesirxsso .btn-group-vertical > .btn,
.aesirxui .aesirxsso .btn-group-vertical > .btn-group {
  width: 100%;
}
.aesirxui .aesirxsso .btn-group-vertical > .btn:not(:first-child),
.aesirxui .aesirxsso .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--aesirxsso-border-width) * -1);
}
.aesirxui .aesirxsso .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.aesirxui .aesirxsso .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.aesirxui .aesirxsso .btn-group-vertical > .btn ~ .btn,
.aesirxui .aesirxsso .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.aesirxui .aesirxsso .btn {
  --aesirxsso-btn-padding-x: 0.75rem;
  --aesirxsso-btn-padding-y: 0.375rem;
  --aesirxsso-btn-font-family: ;
  --aesirxsso-btn-font-size: 1rem;
  --aesirxsso-btn-font-weight: 400;
  --aesirxsso-btn-line-height: 1.5;
  --aesirxsso-btn-color: var(--aesirxsso-body-color);
  --aesirxsso-btn-bg: transparent;
  --aesirxsso-btn-border-width: var(--aesirxsso-border-width);
  --aesirxsso-btn-border-color: transparent;
  --aesirxsso-btn-border-radius: var(--aesirxsso-border-radius);
  --aesirxsso-btn-hover-border-color: transparent;
  --aesirxsso-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --aesirxsso-btn-disabled-opacity: 0.65;
  --aesirxsso-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--aesirxsso-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--aesirxsso-btn-padding-y) var(--aesirxsso-btn-padding-x);
  font-family: var(--aesirxsso-btn-font-family);
  font-size: var(--aesirxsso-btn-font-size);
  font-weight: var(--aesirxsso-btn-font-weight);
  line-height: var(--aesirxsso-btn-line-height);
  color: var(--aesirxsso-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--aesirxsso-btn-border-width) solid var(--aesirxsso-btn-border-color);
  border-radius: var(--aesirxsso-btn-border-radius);
  background-color: var(--aesirxsso-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .btn {
    transition: none;
  }
}
.aesirxui .aesirxsso .btn:hover {
  color: var(--aesirxsso-btn-hover-color);
  background-color: var(--aesirxsso-btn-hover-bg);
  border-color: var(--aesirxsso-btn-hover-border-color);
}
.btn-check + .aesirxui .aesirxsso .btn:hover {
  color: var(--aesirxsso-btn-color);
  background-color: var(--aesirxsso-btn-bg);
  border-color: var(--aesirxsso-btn-border-color);
}
.aesirxui .aesirxsso .btn:focus-visible {
  color: var(--aesirxsso-btn-hover-color);
  background-color: var(--aesirxsso-btn-hover-bg);
  border-color: var(--aesirxsso-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--aesirxsso-btn-focus-box-shadow);
}
.btn-check:focus-visible + .aesirxui .aesirxsso .btn {
  border-color: var(--aesirxsso-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--aesirxsso-btn-focus-box-shadow);
}
.btn-check:checked + .aesirxui .aesirxsso .btn, :not(.btn-check) + .aesirxui .aesirxsso .btn:active, .aesirxui .aesirxsso .btn:first-child:active, .aesirxui .aesirxsso .btn.active, .aesirxui .aesirxsso .btn.show {
  color: var(--aesirxsso-btn-active-color);
  background-color: var(--aesirxsso-btn-active-bg);
  border-color: var(--aesirxsso-btn-active-border-color);
}
.btn-check:checked + .aesirxui .aesirxsso .btn:focus-visible, :not(.btn-check) + .aesirxui .aesirxsso .btn:active:focus-visible, .aesirxui .aesirxsso .btn:first-child:active:focus-visible, .aesirxui .aesirxsso .btn.active:focus-visible, .aesirxui .aesirxsso .btn.show:focus-visible {
  box-shadow: var(--aesirxsso-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .aesirxui .aesirxsso .btn {
  box-shadow: var(--aesirxsso-btn-focus-box-shadow);
}
.aesirxui .aesirxsso .btn:disabled, .aesirxui .aesirxsso .btn.disabled, fieldset:disabled .aesirxui .aesirxsso .btn {
  color: var(--aesirxsso-btn-disabled-color);
  pointer-events: none;
  background-color: var(--aesirxsso-btn-disabled-bg);
  border-color: var(--aesirxsso-btn-disabled-border-color);
  opacity: var(--aesirxsso-btn-disabled-opacity);
}
.aesirxui .aesirxsso .btn-primary {
  --aesirxsso-btn-color: #fff;
  --aesirxsso-btn-bg: #132342;
  --aesirxsso-btn-border-color: #132342;
  --aesirxsso-btn-hover-color: #fff;
  --aesirxsso-btn-hover-bg: #101e38;
  --aesirxsso-btn-hover-border-color: #0f1c35;
  --aesirxsso-btn-focus-shadow-rgb: 54, 68, 94;
  --aesirxsso-btn-active-color: #fff;
  --aesirxsso-btn-active-bg: #0f1c35;
  --aesirxsso-btn-active-border-color: #0e1a32;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #fff;
  --aesirxsso-btn-disabled-bg: #132342;
  --aesirxsso-btn-disabled-border-color: #132342;
}
.aesirxui .aesirxsso .btn-secondary {
  --aesirxsso-btn-color: #000;
  --aesirxsso-btn-bg: #627eea;
  --aesirxsso-btn-border-color: #627eea;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #7a91ed;
  --aesirxsso-btn-hover-border-color: #728bec;
  --aesirxsso-btn-focus-shadow-rgb: 83, 107, 199;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #8198ee;
  --aesirxsso-btn-active-border-color: #728bec;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #000;
  --aesirxsso-btn-disabled-bg: #627eea;
  --aesirxsso-btn-disabled-border-color: #627eea;
}
.aesirxui .aesirxsso .btn-success {
  --aesirxsso-btn-color: #000;
  --aesirxsso-btn-bg: #1ab394;
  --aesirxsso-btn-border-color: #1ab394;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #3cbea4;
  --aesirxsso-btn-hover-border-color: #31bb9f;
  --aesirxsso-btn-focus-shadow-rgb: 22, 152, 126;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #48c2a9;
  --aesirxsso-btn-active-border-color: #31bb9f;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #000;
  --aesirxsso-btn-disabled-bg: #1ab394;
  --aesirxsso-btn-disabled-border-color: #1ab394;
}
.aesirxui .aesirxsso .btn-info {
  --aesirxsso-btn-color: #000;
  --aesirxsso-btn-bg: #0dcaf0;
  --aesirxsso-btn-border-color: #0dcaf0;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #31d2f2;
  --aesirxsso-btn-hover-border-color: #25cff2;
  --aesirxsso-btn-focus-shadow-rgb: 11, 172, 204;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #3dd5f3;
  --aesirxsso-btn-active-border-color: #25cff2;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #000;
  --aesirxsso-btn-disabled-bg: #0dcaf0;
  --aesirxsso-btn-disabled-border-color: #0dcaf0;
}
.aesirxui .aesirxsso .btn-warning {
  --aesirxsso-btn-color: #000;
  --aesirxsso-btn-bg: #ffc107;
  --aesirxsso-btn-border-color: #ffc107;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #ffca2c;
  --aesirxsso-btn-hover-border-color: #ffc720;
  --aesirxsso-btn-focus-shadow-rgb: 217, 164, 6;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #ffcd39;
  --aesirxsso-btn-active-border-color: #ffc720;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #000;
  --aesirxsso-btn-disabled-bg: #ffc107;
  --aesirxsso-btn-disabled-border-color: #ffc107;
}
.aesirxui .aesirxsso .btn-danger {
  --aesirxsso-btn-color: #fff;
  --aesirxsso-btn-bg: #dc3545;
  --aesirxsso-btn-border-color: #dc3545;
  --aesirxsso-btn-hover-color: #fff;
  --aesirxsso-btn-hover-bg: #bb2d3b;
  --aesirxsso-btn-hover-border-color: #b02a37;
  --aesirxsso-btn-focus-shadow-rgb: 225, 83, 97;
  --aesirxsso-btn-active-color: #fff;
  --aesirxsso-btn-active-bg: #b02a37;
  --aesirxsso-btn-active-border-color: #a52834;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #fff;
  --aesirxsso-btn-disabled-bg: #dc3545;
  --aesirxsso-btn-disabled-border-color: #dc3545;
}
.aesirxui .aesirxsso .btn-light {
  --aesirxsso-btn-color: #000;
  --aesirxsso-btn-bg: #f8f9fa;
  --aesirxsso-btn-border-color: #f8f9fa;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #d3d4d5;
  --aesirxsso-btn-hover-border-color: #c6c7c8;
  --aesirxsso-btn-focus-shadow-rgb: 211, 212, 213;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #c6c7c8;
  --aesirxsso-btn-active-border-color: #babbbc;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #000;
  --aesirxsso-btn-disabled-bg: #f8f9fa;
  --aesirxsso-btn-disabled-border-color: #f8f9fa;
}
.aesirxui .aesirxsso .btn-dark {
  --aesirxsso-btn-color: #fff;
  --aesirxsso-btn-bg: #0f1314;
  --aesirxsso-btn-border-color: #0f1314;
  --aesirxsso-btn-hover-color: #fff;
  --aesirxsso-btn-hover-bg: #333637;
  --aesirxsso-btn-hover-border-color: #272b2c;
  --aesirxsso-btn-focus-shadow-rgb: 51, 54, 55;
  --aesirxsso-btn-active-color: #fff;
  --aesirxsso-btn-active-bg: #3f4243;
  --aesirxsso-btn-active-border-color: #272b2c;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #fff;
  --aesirxsso-btn-disabled-bg: #0f1314;
  --aesirxsso-btn-disabled-border-color: #0f1314;
}
.aesirxui .aesirxsso .btn-outline-primary {
  --aesirxsso-btn-color: #132342;
  --aesirxsso-btn-border-color: #132342;
  --aesirxsso-btn-hover-color: #fff;
  --aesirxsso-btn-hover-bg: #132342;
  --aesirxsso-btn-hover-border-color: #132342;
  --aesirxsso-btn-focus-shadow-rgb: 19, 35, 66;
  --aesirxsso-btn-active-color: #fff;
  --aesirxsso-btn-active-bg: #132342;
  --aesirxsso-btn-active-border-color: #132342;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #132342;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #132342;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-secondary {
  --aesirxsso-btn-color: #627eea;
  --aesirxsso-btn-border-color: #627eea;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #627eea;
  --aesirxsso-btn-hover-border-color: #627eea;
  --aesirxsso-btn-focus-shadow-rgb: 98, 126, 234;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #627eea;
  --aesirxsso-btn-active-border-color: #627eea;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #627eea;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #627eea;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-success {
  --aesirxsso-btn-color: #1ab394;
  --aesirxsso-btn-border-color: #1ab394;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #1ab394;
  --aesirxsso-btn-hover-border-color: #1ab394;
  --aesirxsso-btn-focus-shadow-rgb: 26, 179, 148;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #1ab394;
  --aesirxsso-btn-active-border-color: #1ab394;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #1ab394;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #1ab394;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-info {
  --aesirxsso-btn-color: #0dcaf0;
  --aesirxsso-btn-border-color: #0dcaf0;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #0dcaf0;
  --aesirxsso-btn-hover-border-color: #0dcaf0;
  --aesirxsso-btn-focus-shadow-rgb: 13, 202, 240;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #0dcaf0;
  --aesirxsso-btn-active-border-color: #0dcaf0;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #0dcaf0;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #0dcaf0;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-warning {
  --aesirxsso-btn-color: #ffc107;
  --aesirxsso-btn-border-color: #ffc107;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #ffc107;
  --aesirxsso-btn-hover-border-color: #ffc107;
  --aesirxsso-btn-focus-shadow-rgb: 255, 193, 7;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #ffc107;
  --aesirxsso-btn-active-border-color: #ffc107;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #ffc107;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #ffc107;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-danger {
  --aesirxsso-btn-color: #dc3545;
  --aesirxsso-btn-border-color: #dc3545;
  --aesirxsso-btn-hover-color: #fff;
  --aesirxsso-btn-hover-bg: #dc3545;
  --aesirxsso-btn-hover-border-color: #dc3545;
  --aesirxsso-btn-focus-shadow-rgb: 220, 53, 69;
  --aesirxsso-btn-active-color: #fff;
  --aesirxsso-btn-active-bg: #dc3545;
  --aesirxsso-btn-active-border-color: #dc3545;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #dc3545;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #dc3545;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-light {
  --aesirxsso-btn-color: #f8f9fa;
  --aesirxsso-btn-border-color: #f8f9fa;
  --aesirxsso-btn-hover-color: #000;
  --aesirxsso-btn-hover-bg: #f8f9fa;
  --aesirxsso-btn-hover-border-color: #f8f9fa;
  --aesirxsso-btn-focus-shadow-rgb: 248, 249, 250;
  --aesirxsso-btn-active-color: #000;
  --aesirxsso-btn-active-bg: #f8f9fa;
  --aesirxsso-btn-active-border-color: #f8f9fa;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #f8f9fa;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #f8f9fa;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-outline-dark {
  --aesirxsso-btn-color: #0f1314;
  --aesirxsso-btn-border-color: #0f1314;
  --aesirxsso-btn-hover-color: #fff;
  --aesirxsso-btn-hover-bg: #0f1314;
  --aesirxsso-btn-hover-border-color: #0f1314;
  --aesirxsso-btn-focus-shadow-rgb: 15, 19, 20;
  --aesirxsso-btn-active-color: #fff;
  --aesirxsso-btn-active-bg: #0f1314;
  --aesirxsso-btn-active-border-color: #0f1314;
  --aesirxsso-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --aesirxsso-btn-disabled-color: #0f1314;
  --aesirxsso-btn-disabled-bg: transparent;
  --aesirxsso-btn-disabled-border-color: #0f1314;
  --aesirxsso-gradient: none;
}
.aesirxui .aesirxsso .btn-link {
  --aesirxsso-btn-font-weight: 400;
  --aesirxsso-btn-color: var(--aesirxsso-link-color);
  --aesirxsso-btn-bg: transparent;
  --aesirxsso-btn-border-color: transparent;
  --aesirxsso-btn-hover-color: var(--aesirxsso-link-hover-color);
  --aesirxsso-btn-hover-border-color: transparent;
  --aesirxsso-btn-active-color: var(--aesirxsso-link-hover-color);
  --aesirxsso-btn-active-border-color: transparent;
  --aesirxsso-btn-disabled-color: #6c757d;
  --aesirxsso-btn-disabled-border-color: transparent;
  --aesirxsso-btn-box-shadow: 0 0 0 #000;
  --aesirxsso-btn-focus-shadow-rgb: 54, 68, 94;
  text-decoration: underline;
}
.aesirxui .aesirxsso .btn-link:focus-visible {
  color: var(--aesirxsso-btn-color);
}
.aesirxui .aesirxsso .btn-link:hover {
  color: var(--aesirxsso-btn-hover-color);
}
.aesirxui .aesirxsso .btn-lg, .aesirxui .aesirxsso .btn-group-lg > .btn {
  --aesirxsso-btn-padding-y: 0.5rem;
  --aesirxsso-btn-padding-x: 1rem;
  --aesirxsso-btn-font-size: 1.25rem;
  --aesirxsso-btn-border-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .btn-sm, .aesirxui .aesirxsso .btn-group-sm > .btn {
  --aesirxsso-btn-padding-y: 0.25rem;
  --aesirxsso-btn-padding-x: 0.5rem;
  --aesirxsso-btn-font-size: 0.875rem;
  --aesirxsso-btn-border-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .spinner-grow,
.aesirxui .aesirxsso .spinner-border {
  display: inline-block;
  width: var(--aesirxsso-spinner-width);
  height: var(--aesirxsso-spinner-height);
  vertical-align: var(--aesirxsso-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--aesirxsso-spinner-animation-speed) linear infinite var(--aesirxsso-spinner-animation-name);
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.aesirxui .aesirxsso .spinner-border {
  --aesirxsso-spinner-width: 2rem;
  --aesirxsso-spinner-height: 2rem;
  --aesirxsso-spinner-vertical-align: -0.125em;
  --aesirxsso-spinner-border-width: 0.25em;
  --aesirxsso-spinner-animation-speed: 0.75s;
  --aesirxsso-spinner-animation-name: spinner-border;
  border: var(--aesirxsso-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}
.aesirxui .aesirxsso .spinner-border-sm {
  --aesirxsso-spinner-width: 1rem;
  --aesirxsso-spinner-height: 1rem;
  --aesirxsso-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.aesirxui .aesirxsso .spinner-grow {
  --aesirxsso-spinner-width: 2rem;
  --aesirxsso-spinner-height: 2rem;
  --aesirxsso-spinner-vertical-align: -0.125em;
  --aesirxsso-spinner-animation-speed: 0.75s;
  --aesirxsso-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.aesirxui .aesirxsso .spinner-grow-sm {
  --aesirxsso-spinner-width: 1rem;
  --aesirxsso-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .spinner-border,
  .aesirxui .aesirxsso .spinner-grow {
    --aesirxsso-spinner-animation-speed: 1.5s;
  }
}
.aesirxui .aesirxsso .btn-close {
  --aesirxsso-btn-close-color: #fff;
  --aesirxsso-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --aesirxsso-btn-close-opacity: 1;
  --aesirxsso-btn-close-hover-opacity: 0.75;
  --aesirxsso-btn-close-focus-shadow: 0 0 0 0.25rem rgba(19, 35, 66, 0.25);
  --aesirxsso-btn-close-focus-opacity: 1;
  --aesirxsso-btn-close-disabled-opacity: 0.25;
  --aesirxsso-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--aesirxsso-btn-close-color);
  background: transparent var(--aesirxsso-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--aesirxsso-btn-close-opacity);
}
.aesirxui .aesirxsso .btn-close:hover {
  color: var(--aesirxsso-btn-close-color);
  text-decoration: none;
  opacity: var(--aesirxsso-btn-close-hover-opacity);
}
.aesirxui .aesirxsso .btn-close:focus {
  outline: 0;
  box-shadow: var(--aesirxsso-btn-close-focus-shadow);
  opacity: var(--aesirxsso-btn-close-focus-opacity);
}
.aesirxui .aesirxsso .btn-close:disabled, .aesirxui .aesirxsso .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--aesirxsso-btn-close-disabled-opacity);
}
.aesirxui .aesirxsso .btn-close-white {
  filter: var(--aesirxsso-btn-close-white-filter);
}
.aesirxui .aesirxsso [data-bs-theme=dark] .btn-close {
  filter: var(--aesirxsso-btn-close-white-filter);
}
.aesirxui .aesirxsso .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .fade {
    transition: none;
  }
}
.aesirxui .aesirxsso .fade:not(.show) {
  opacity: 0;
}
.aesirxui .aesirxsso .collapse:not(.show) {
  display: none;
}
.aesirxui .aesirxsso .collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .collapsing {
    transition: none;
  }
}
.aesirxui .aesirxsso .collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .collapsing.collapse-horizontal {
    transition: none;
  }
}
.aesirxui .aesirxsso .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.aesirxui .aesirxsso .text-bg-primary {
  color: #fff;
  background-color: RGBA(var(--aesirxsso-primary-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-secondary {
  color: #000;
  background-color: RGBA(var(--aesirxsso-secondary-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-success {
  color: #000;
  background-color: RGBA(var(--aesirxsso-success-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-info {
  color: #000;
  background-color: RGBA(var(--aesirxsso-info-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-warning {
  color: #000;
  background-color: RGBA(var(--aesirxsso-warning-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-danger {
  color: #fff;
  background-color: RGBA(var(--aesirxsso-danger-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-light {
  color: #000;
  background-color: RGBA(var(--aesirxsso-light-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .text-bg-dark {
  color: #fff;
  background-color: RGBA(var(--aesirxsso-dark-rgb), var(--aesirxsso-bg-opacity, 1));
}
.aesirxui .aesirxsso .link-primary {
  color: RGBA(var(--aesirxsso-primary-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-primary-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-primary:hover, .aesirxui .aesirxsso .link-primary:focus {
  color: RGBA(15, 28, 53, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(15, 28, 53, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-secondary {
  color: RGBA(var(--aesirxsso-secondary-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-secondary-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-secondary:hover, .aesirxui .aesirxsso .link-secondary:focus {
  color: RGBA(129, 152, 238, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(129, 152, 238, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-success {
  color: RGBA(var(--aesirxsso-success-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-success-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-success:hover, .aesirxui .aesirxsso .link-success:focus {
  color: RGBA(72, 194, 169, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(72, 194, 169, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-info {
  color: RGBA(var(--aesirxsso-info-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-info-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-info:hover, .aesirxui .aesirxsso .link-info:focus {
  color: RGBA(61, 213, 243, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(61, 213, 243, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-warning {
  color: RGBA(var(--aesirxsso-warning-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-warning-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-warning:hover, .aesirxui .aesirxsso .link-warning:focus {
  color: RGBA(255, 205, 57, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(255, 205, 57, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-danger {
  color: RGBA(var(--aesirxsso-danger-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-danger-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-danger:hover, .aesirxui .aesirxsso .link-danger:focus {
  color: RGBA(176, 42, 55, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(176, 42, 55, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-light {
  color: RGBA(var(--aesirxsso-light-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-light-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-light:hover, .aesirxui .aesirxsso .link-light:focus {
  color: RGBA(249, 250, 251, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(249, 250, 251, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-dark {
  color: RGBA(var(--aesirxsso-dark-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-dark-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-dark:hover, .aesirxui .aesirxsso .link-dark:focus {
  color: RGBA(12, 15, 16, var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(12, 15, 16, var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-body-emphasis {
  color: RGBA(var(--aesirxsso-emphasis-color-rgb), var(--aesirxsso-link-opacity, 1));
  text-decoration-color: RGBA(var(--aesirxsso-emphasis-color-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-body-emphasis:hover, .aesirxui .aesirxsso .link-body-emphasis:focus {
  color: RGBA(var(--aesirxsso-emphasis-color-rgb), var(--aesirxsso-link-opacity, 0.75));
  text-decoration-color: RGBA(var(--aesirxsso-emphasis-color-rgb), var(--aesirxsso-link-underline-opacity, 0.75));
}
.aesirxui .aesirxsso .focus-ring:focus {
  outline: 0;
  box-shadow: var(--aesirxsso-focus-ring-x, 0) var(--aesirxsso-focus-ring-y, 0) var(--aesirxsso-focus-ring-blur, 0) var(--aesirxsso-focus-ring-width) var(--aesirxsso-focus-ring-color);
}
.aesirxui .aesirxsso .icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--aesirxsso-link-color-rgb), var(--aesirxsso-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.aesirxui .aesirxsso .icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .aesirxui .aesirxsso .icon-link > .bi {
    transition: none;
  }
}
.aesirxui .aesirxsso .icon-link-hover:hover > .bi, .aesirxui .aesirxsso .icon-link-hover:focus-visible > .bi {
  transform: var(--aesirxsso-icon-link-transform, translate3d(0.25em, 0, 0));
}
.aesirxui .aesirxsso .ratio {
  position: relative;
  width: 100%;
}
.aesirxui .aesirxsso .ratio::before {
  display: block;
  padding-top: var(--aesirxsso-aspect-ratio);
  content: "";
}
.aesirxui .aesirxsso .ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.aesirxui .aesirxsso .ratio-1x1 {
  --aesirxsso-aspect-ratio: 100%;
}
.aesirxui .aesirxsso .ratio-4x3 {
  --aesirxsso-aspect-ratio: 75%;
}
.aesirxui .aesirxsso .ratio-16x9 {
  --aesirxsso-aspect-ratio: 56.25%;
}
.aesirxui .aesirxsso .ratio-21x9 {
  --aesirxsso-aspect-ratio: 42.8571428571%;
}
.aesirxui .aesirxsso .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.aesirxui .aesirxsso .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.aesirxui .aesirxsso .sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.aesirxui .aesirxsso .sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .aesirxui .aesirxsso .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .aesirxui .aesirxsso .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .aesirxui .aesirxsso .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .aesirxui .aesirxsso .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .aesirxui .aesirxsso .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .aesirxui .aesirxsso .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .aesirxui .aesirxsso .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .aesirxui .aesirxsso .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .aesirxui .aesirxsso .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.aesirxui .aesirxsso .hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.aesirxui .aesirxsso .vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.aesirxui .aesirxsso .visually-hidden,
.aesirxui .aesirxsso .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.aesirxui .aesirxsso .visually-hidden:not(caption),
.aesirxui .aesirxsso .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
.aesirxui .aesirxsso .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.aesirxui .aesirxsso .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.aesirxui .aesirxsso .vr {
  display: inline-block;
  align-self: stretch;
  width: var(--aesirxsso-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.aesirxui .aesirxsso .align-baseline {
  vertical-align: baseline;
}
.aesirxui .aesirxsso .align-top {
  vertical-align: top;
}
.aesirxui .aesirxsso .align-middle {
  vertical-align: middle;
}
.aesirxui .aesirxsso .align-bottom {
  vertical-align: bottom;
}
.aesirxui .aesirxsso .align-text-bottom {
  vertical-align: text-bottom;
}
.aesirxui .aesirxsso .align-text-top {
  vertical-align: text-top;
}
.aesirxui .aesirxsso .float-start {
  float: left;
}
.aesirxui .aesirxsso .float-end {
  float: right;
}
.aesirxui .aesirxsso .float-none {
  float: none;
}
.aesirxui .aesirxsso .object-fit-contain {
  object-fit: contain;
}
.aesirxui .aesirxsso .object-fit-cover {
  object-fit: cover;
}
.aesirxui .aesirxsso .object-fit-fill {
  object-fit: fill;
}
.aesirxui .aesirxsso .object-fit-scale {
  object-fit: scale-down;
}
.aesirxui .aesirxsso .object-fit-none {
  object-fit: none;
}
.aesirxui .aesirxsso .opacity-0 {
  opacity: 0;
}
.aesirxui .aesirxsso .opacity-25 {
  opacity: 0.25;
}
.aesirxui .aesirxsso .opacity-50 {
  opacity: 0.5;
}
.aesirxui .aesirxsso .opacity-75 {
  opacity: 0.75;
}
.aesirxui .aesirxsso .opacity-100 {
  opacity: 1;
}
.aesirxui .aesirxsso .overflow-auto {
  overflow: auto;
}
.aesirxui .aesirxsso .overflow-hidden {
  overflow: hidden;
}
.aesirxui .aesirxsso .overflow-visible {
  overflow: visible;
}
.aesirxui .aesirxsso .overflow-scroll {
  overflow: scroll;
}
.aesirxui .aesirxsso .overflow-x-auto {
  overflow-x: auto;
}
.aesirxui .aesirxsso .overflow-x-hidden {
  overflow-x: hidden;
}
.aesirxui .aesirxsso .overflow-x-visible {
  overflow-x: visible;
}
.aesirxui .aesirxsso .overflow-x-scroll {
  overflow-x: scroll;
}
.aesirxui .aesirxsso .overflow-y-auto {
  overflow-y: auto;
}
.aesirxui .aesirxsso .overflow-y-hidden {
  overflow-y: hidden;
}
.aesirxui .aesirxsso .overflow-y-visible {
  overflow-y: visible;
}
.aesirxui .aesirxsso .overflow-y-scroll {
  overflow-y: scroll;
}
.aesirxui .aesirxsso .d-inline {
  display: inline;
}
.aesirxui .aesirxsso .d-inline-block {
  display: inline-block;
}
.aesirxui .aesirxsso .d-block {
  display: block;
}
.aesirxui .aesirxsso .d-grid {
  display: grid;
}
.aesirxui .aesirxsso .d-inline-grid {
  display: inline-grid;
}
.aesirxui .aesirxsso .d-table {
  display: table;
}
.aesirxui .aesirxsso .d-table-row {
  display: table-row;
}
.aesirxui .aesirxsso .d-table-cell {
  display: table-cell;
}
.aesirxui .aesirxsso .d-flex {
  display: flex;
}
.aesirxui .aesirxsso .d-inline-flex {
  display: inline-flex;
}
.aesirxui .aesirxsso .d-none {
  display: none;
}
.aesirxui .aesirxsso .shadow {
  box-shadow: var(--aesirxsso-box-shadow);
}
.aesirxui .aesirxsso .shadow-sm {
  box-shadow: var(--aesirxsso-box-shadow-sm);
}
.aesirxui .aesirxsso .shadow-lg {
  box-shadow: var(--aesirxsso-box-shadow-lg);
}
.aesirxui .aesirxsso .shadow-none {
  box-shadow: none;
}
.aesirxui .aesirxsso .focus-ring-primary {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-primary-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-secondary {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-secondary-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-success {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-success-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-info {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-info-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-warning {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-warning-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-danger {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-danger-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-light {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-light-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .focus-ring-dark {
  --aesirxsso-focus-ring-color: rgba(var(--aesirxsso-dark-rgb), var(--aesirxsso-focus-ring-opacity));
}
.aesirxui .aesirxsso .position-static {
  position: static;
}
.aesirxui .aesirxsso .position-relative {
  position: relative;
}
.aesirxui .aesirxsso .position-absolute {
  position: absolute;
}
.aesirxui .aesirxsso .position-fixed {
  position: fixed;
}
.aesirxui .aesirxsso .position-sticky {
  position: sticky;
}
.aesirxui .aesirxsso .top-0 {
  top: 0;
}
.aesirxui .aesirxsso .top-50 {
  top: 50%;
}
.aesirxui .aesirxsso .top-100 {
  top: 100%;
}
.aesirxui .aesirxsso .bottom-0 {
  bottom: 0;
}
.aesirxui .aesirxsso .bottom-50 {
  bottom: 50%;
}
.aesirxui .aesirxsso .bottom-100 {
  bottom: 100%;
}
.aesirxui .aesirxsso .start-0 {
  left: 0;
}
.aesirxui .aesirxsso .start-50 {
  left: 50%;
}
.aesirxui .aesirxsso .start-100 {
  left: 100%;
}
.aesirxui .aesirxsso .end-0 {
  right: 0;
}
.aesirxui .aesirxsso .end-50 {
  right: 50%;
}
.aesirxui .aesirxsso .end-100 {
  right: 100%;
}
.aesirxui .aesirxsso .translate-middle {
  transform: translate(-50%, -50%);
}
.aesirxui .aesirxsso .translate-middle-x {
  transform: translateX(-50%);
}
.aesirxui .aesirxsso .translate-middle-y {
  transform: translateY(-50%);
}
.aesirxui .aesirxsso .border {
  border: var(--aesirxsso-border-width) var(--aesirxsso-border-style) var(--aesirxsso-border-color);
}
.aesirxui .aesirxsso .border-0 {
  border: 0;
}
.aesirxui .aesirxsso .border-top {
  border-top: var(--aesirxsso-border-width) var(--aesirxsso-border-style) var(--aesirxsso-border-color);
}
.aesirxui .aesirxsso .border-top-0 {
  border-top: 0;
}
.aesirxui .aesirxsso .border-end {
  border-right: var(--aesirxsso-border-width) var(--aesirxsso-border-style) var(--aesirxsso-border-color);
}
.aesirxui .aesirxsso .border-end-0 {
  border-right: 0;
}
.aesirxui .aesirxsso .border-bottom {
  border-bottom: var(--aesirxsso-border-width) var(--aesirxsso-border-style) var(--aesirxsso-border-color);
}
.aesirxui .aesirxsso .border-bottom-0 {
  border-bottom: 0;
}
.aesirxui .aesirxsso .border-start {
  border-left: var(--aesirxsso-border-width) var(--aesirxsso-border-style) var(--aesirxsso-border-color);
}
.aesirxui .aesirxsso .border-start-0 {
  border-left: 0;
}
.aesirxui .aesirxsso .border-primary {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-primary-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-secondary {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-secondary-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-success {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-success-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-info {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-info-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-warning {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-warning-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-danger {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-danger-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-light {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-light-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-dark {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-dark-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-black {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-black-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-white {
  --aesirxsso-border-opacity: 1;
  border-color: rgba(var(--aesirxsso-white-rgb), var(--aesirxsso-border-opacity));
}
.aesirxui .aesirxsso .border-primary-subtle {
  border-color: var(--aesirxsso-primary-border-subtle);
}
.aesirxui .aesirxsso .border-secondary-subtle {
  border-color: var(--aesirxsso-secondary-border-subtle);
}
.aesirxui .aesirxsso .border-success-subtle {
  border-color: var(--aesirxsso-success-border-subtle);
}
.aesirxui .aesirxsso .border-info-subtle {
  border-color: var(--aesirxsso-info-border-subtle);
}
.aesirxui .aesirxsso .border-warning-subtle {
  border-color: var(--aesirxsso-warning-border-subtle);
}
.aesirxui .aesirxsso .border-danger-subtle {
  border-color: var(--aesirxsso-danger-border-subtle);
}
.aesirxui .aesirxsso .border-light-subtle {
  border-color: var(--aesirxsso-light-border-subtle);
}
.aesirxui .aesirxsso .border-dark-subtle {
  border-color: var(--aesirxsso-dark-border-subtle);
}
.aesirxui .aesirxsso .border-1 {
  border-width: 1px;
}
.aesirxui .aesirxsso .border-2 {
  border-width: 2px;
}
.aesirxui .aesirxsso .border-3 {
  border-width: 3px;
}
.aesirxui .aesirxsso .border-4 {
  border-width: 4px;
}
.aesirxui .aesirxsso .border-5 {
  border-width: 5px;
}
.aesirxui .aesirxsso .border-opacity-10 {
  --aesirxsso-border-opacity: 0.1;
}
.aesirxui .aesirxsso .border-opacity-25 {
  --aesirxsso-border-opacity: 0.25;
}
.aesirxui .aesirxsso .border-opacity-50 {
  --aesirxsso-border-opacity: 0.5;
}
.aesirxui .aesirxsso .border-opacity-75 {
  --aesirxsso-border-opacity: 0.75;
}
.aesirxui .aesirxsso .border-opacity-100 {
  --aesirxsso-border-opacity: 1;
}
.aesirxui .aesirxsso .w-25 {
  width: 25%;
}
.aesirxui .aesirxsso .w-50 {
  width: 50%;
}
.aesirxui .aesirxsso .w-75 {
  width: 75%;
}
.aesirxui .aesirxsso .w-100 {
  width: 100%;
}
.aesirxui .aesirxsso .w-auto {
  width: auto;
}
.aesirxui .aesirxsso .mw-100 {
  max-width: 100%;
}
.aesirxui .aesirxsso .vw-100 {
  width: 100vw;
}
.aesirxui .aesirxsso .min-vw-100 {
  min-width: 100vw;
}
.aesirxui .aesirxsso .h-25 {
  height: 25%;
}
.aesirxui .aesirxsso .h-50 {
  height: 50%;
}
.aesirxui .aesirxsso .h-75 {
  height: 75%;
}
.aesirxui .aesirxsso .h-100 {
  height: 100%;
}
.aesirxui .aesirxsso .h-auto {
  height: auto;
}
.aesirxui .aesirxsso .mh-100 {
  max-height: 100%;
}
.aesirxui .aesirxsso .vh-100 {
  height: 100vh;
}
.aesirxui .aesirxsso .min-vh-100 {
  min-height: 100vh;
}
.aesirxui .aesirxsso .flex-fill {
  flex: 1 1 auto;
}
.aesirxui .aesirxsso .flex-row {
  flex-direction: row;
}
.aesirxui .aesirxsso .flex-column {
  flex-direction: column;
}
.aesirxui .aesirxsso .flex-row-reverse {
  flex-direction: row-reverse;
}
.aesirxui .aesirxsso .flex-column-reverse {
  flex-direction: column-reverse;
}
.aesirxui .aesirxsso .flex-grow-0 {
  flex-grow: 0;
}
.aesirxui .aesirxsso .flex-grow-1 {
  flex-grow: 1;
}
.aesirxui .aesirxsso .flex-shrink-0 {
  flex-shrink: 0;
}
.aesirxui .aesirxsso .flex-shrink-1 {
  flex-shrink: 1;
}
.aesirxui .aesirxsso .flex-wrap {
  flex-wrap: wrap;
}
.aesirxui .aesirxsso .flex-nowrap {
  flex-wrap: nowrap;
}
.aesirxui .aesirxsso .flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.aesirxui .aesirxsso .justify-content-start {
  justify-content: flex-start;
}
.aesirxui .aesirxsso .justify-content-end {
  justify-content: flex-end;
}
.aesirxui .aesirxsso .justify-content-center {
  justify-content: center;
}
.aesirxui .aesirxsso .justify-content-between {
  justify-content: space-between;
}
.aesirxui .aesirxsso .justify-content-around {
  justify-content: space-around;
}
.aesirxui .aesirxsso .justify-content-evenly {
  justify-content: space-evenly;
}
.aesirxui .aesirxsso .align-items-start {
  align-items: flex-start;
}
.aesirxui .aesirxsso .align-items-end {
  align-items: flex-end;
}
.aesirxui .aesirxsso .align-items-center {
  align-items: center;
}
.aesirxui .aesirxsso .align-items-baseline {
  align-items: baseline;
}
.aesirxui .aesirxsso .align-items-stretch {
  align-items: stretch;
}
.aesirxui .aesirxsso .align-content-start {
  align-content: flex-start;
}
.aesirxui .aesirxsso .align-content-end {
  align-content: flex-end;
}
.aesirxui .aesirxsso .align-content-center {
  align-content: center;
}
.aesirxui .aesirxsso .align-content-between {
  align-content: space-between;
}
.aesirxui .aesirxsso .align-content-around {
  align-content: space-around;
}
.aesirxui .aesirxsso .align-content-stretch {
  align-content: stretch;
}
.aesirxui .aesirxsso .align-self-auto {
  align-self: auto;
}
.aesirxui .aesirxsso .align-self-start {
  align-self: flex-start;
}
.aesirxui .aesirxsso .align-self-end {
  align-self: flex-end;
}
.aesirxui .aesirxsso .align-self-center {
  align-self: center;
}
.aesirxui .aesirxsso .align-self-baseline {
  align-self: baseline;
}
.aesirxui .aesirxsso .align-self-stretch {
  align-self: stretch;
}
.aesirxui .aesirxsso .order-first {
  order: -1;
}
.aesirxui .aesirxsso .order-0 {
  order: 0;
}
.aesirxui .aesirxsso .order-1 {
  order: 1;
}
.aesirxui .aesirxsso .order-2 {
  order: 2;
}
.aesirxui .aesirxsso .order-3 {
  order: 3;
}
.aesirxui .aesirxsso .order-4 {
  order: 4;
}
.aesirxui .aesirxsso .order-5 {
  order: 5;
}
.aesirxui .aesirxsso .order-last {
  order: 6;
}
.aesirxui .aesirxsso .m-0 {
  margin: 0;
}
.aesirxui .aesirxsso .m-1 {
  margin: 0.25rem;
}
.aesirxui .aesirxsso .m-2 {
  margin: 0.5rem;
}
.aesirxui .aesirxsso .m-3 {
  margin: 1rem;
}
.aesirxui .aesirxsso .m-4 {
  margin: 1.5rem;
}
.aesirxui .aesirxsso .m-5 {
  margin: 3rem;
}
.aesirxui .aesirxsso .m-auto {
  margin: auto;
}
.aesirxui .aesirxsso .mx-0 {
  margin-right: 0;
  margin-left: 0;
}
.aesirxui .aesirxsso .mx-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.aesirxui .aesirxsso .mx-2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.aesirxui .aesirxsso .mx-3 {
  margin-right: 1rem;
  margin-left: 1rem;
}
.aesirxui .aesirxsso .mx-4 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.aesirxui .aesirxsso .mx-5 {
  margin-right: 3rem;
  margin-left: 3rem;
}
.aesirxui .aesirxsso .mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.aesirxui .aesirxsso .my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.aesirxui .aesirxsso .my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.aesirxui .aesirxsso .my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.aesirxui .aesirxsso .my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.aesirxui .aesirxsso .my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.aesirxui .aesirxsso .my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.aesirxui .aesirxsso .my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.aesirxui .aesirxsso .mt-0 {
  margin-top: 0;
}
.aesirxui .aesirxsso .mt-1 {
  margin-top: 0.25rem;
}
.aesirxui .aesirxsso .mt-2 {
  margin-top: 0.5rem;
}
.aesirxui .aesirxsso .mt-3 {
  margin-top: 1rem;
}
.aesirxui .aesirxsso .mt-4 {
  margin-top: 1.5rem;
}
.aesirxui .aesirxsso .mt-5 {
  margin-top: 3rem;
}
.aesirxui .aesirxsso .mt-auto {
  margin-top: auto;
}
.aesirxui .aesirxsso .me-0 {
  margin-right: 0;
}
.aesirxui .aesirxsso .me-1 {
  margin-right: 0.25rem;
}
.aesirxui .aesirxsso .me-2 {
  margin-right: 0.5rem;
}
.aesirxui .aesirxsso .me-3 {
  margin-right: 1rem;
}
.aesirxui .aesirxsso .me-4 {
  margin-right: 1.5rem;
}
.aesirxui .aesirxsso .me-5 {
  margin-right: 3rem;
}
.aesirxui .aesirxsso .me-auto {
  margin-right: auto;
}
.aesirxui .aesirxsso .mb-0 {
  margin-bottom: 0;
}
.aesirxui .aesirxsso .mb-1 {
  margin-bottom: 0.25rem;
}
.aesirxui .aesirxsso .mb-2 {
  margin-bottom: 0.5rem;
}
.aesirxui .aesirxsso .mb-3 {
  margin-bottom: 1rem;
}
.aesirxui .aesirxsso .mb-4 {
  margin-bottom: 1.5rem;
}
.aesirxui .aesirxsso .mb-5 {
  margin-bottom: 3rem;
}
.aesirxui .aesirxsso .mb-auto {
  margin-bottom: auto;
}
.aesirxui .aesirxsso .ms-0 {
  margin-left: 0;
}
.aesirxui .aesirxsso .ms-1 {
  margin-left: 0.25rem;
}
.aesirxui .aesirxsso .ms-2 {
  margin-left: 0.5rem;
}
.aesirxui .aesirxsso .ms-3 {
  margin-left: 1rem;
}
.aesirxui .aesirxsso .ms-4 {
  margin-left: 1.5rem;
}
.aesirxui .aesirxsso .ms-5 {
  margin-left: 3rem;
}
.aesirxui .aesirxsso .ms-auto {
  margin-left: auto;
}
.aesirxui .aesirxsso .m-n1 {
  margin: -0.25rem;
}
.aesirxui .aesirxsso .m-n2 {
  margin: -0.5rem;
}
.aesirxui .aesirxsso .m-n3 {
  margin: -1rem;
}
.aesirxui .aesirxsso .m-n4 {
  margin: -1.5rem;
}
.aesirxui .aesirxsso .m-n5 {
  margin: -3rem;
}
.aesirxui .aesirxsso .mx-n1 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}
.aesirxui .aesirxsso .mx-n2 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.aesirxui .aesirxsso .mx-n3 {
  margin-right: -1rem;
  margin-left: -1rem;
}
.aesirxui .aesirxsso .mx-n4 {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}
.aesirxui .aesirxsso .mx-n5 {
  margin-right: -3rem;
  margin-left: -3rem;
}
.aesirxui .aesirxsso .my-n1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.aesirxui .aesirxsso .my-n2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.aesirxui .aesirxsso .my-n3 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.aesirxui .aesirxsso .my-n4 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}
.aesirxui .aesirxsso .my-n5 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.aesirxui .aesirxsso .mt-n1 {
  margin-top: -0.25rem;
}
.aesirxui .aesirxsso .mt-n2 {
  margin-top: -0.5rem;
}
.aesirxui .aesirxsso .mt-n3 {
  margin-top: -1rem;
}
.aesirxui .aesirxsso .mt-n4 {
  margin-top: -1.5rem;
}
.aesirxui .aesirxsso .mt-n5 {
  margin-top: -3rem;
}
.aesirxui .aesirxsso .me-n1 {
  margin-right: -0.25rem;
}
.aesirxui .aesirxsso .me-n2 {
  margin-right: -0.5rem;
}
.aesirxui .aesirxsso .me-n3 {
  margin-right: -1rem;
}
.aesirxui .aesirxsso .me-n4 {
  margin-right: -1.5rem;
}
.aesirxui .aesirxsso .me-n5 {
  margin-right: -3rem;
}
.aesirxui .aesirxsso .mb-n1 {
  margin-bottom: -0.25rem;
}
.aesirxui .aesirxsso .mb-n2 {
  margin-bottom: -0.5rem;
}
.aesirxui .aesirxsso .mb-n3 {
  margin-bottom: -1rem;
}
.aesirxui .aesirxsso .mb-n4 {
  margin-bottom: -1.5rem;
}
.aesirxui .aesirxsso .mb-n5 {
  margin-bottom: -3rem;
}
.aesirxui .aesirxsso .ms-n1 {
  margin-left: -0.25rem;
}
.aesirxui .aesirxsso .ms-n2 {
  margin-left: -0.5rem;
}
.aesirxui .aesirxsso .ms-n3 {
  margin-left: -1rem;
}
.aesirxui .aesirxsso .ms-n4 {
  margin-left: -1.5rem;
}
.aesirxui .aesirxsso .ms-n5 {
  margin-left: -3rem;
}
.aesirxui .aesirxsso .p-0 {
  padding: 0;
}
.aesirxui .aesirxsso .p-1 {
  padding: 0.25rem;
}
.aesirxui .aesirxsso .p-2 {
  padding: 0.5rem;
}
.aesirxui .aesirxsso .p-3 {
  padding: 1rem;
}
.aesirxui .aesirxsso .p-4 {
  padding: 1.5rem;
}
.aesirxui .aesirxsso .p-5 {
  padding: 3rem;
}
.aesirxui .aesirxsso .px-0 {
  padding-right: 0;
  padding-left: 0;
}
.aesirxui .aesirxsso .px-1 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
.aesirxui .aesirxsso .px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.aesirxui .aesirxsso .px-3 {
  padding-right: 1rem;
  padding-left: 1rem;
}
.aesirxui .aesirxsso .px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.aesirxui .aesirxsso .px-5 {
  padding-right: 3rem;
  padding-left: 3rem;
}
.aesirxui .aesirxsso .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.aesirxui .aesirxsso .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.aesirxui .aesirxsso .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.aesirxui .aesirxsso .py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.aesirxui .aesirxsso .py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.aesirxui .aesirxsso .py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.aesirxui .aesirxsso .pt-0 {
  padding-top: 0;
}
.aesirxui .aesirxsso .pt-1 {
  padding-top: 0.25rem;
}
.aesirxui .aesirxsso .pt-2 {
  padding-top: 0.5rem;
}
.aesirxui .aesirxsso .pt-3 {
  padding-top: 1rem;
}
.aesirxui .aesirxsso .pt-4 {
  padding-top: 1.5rem;
}
.aesirxui .aesirxsso .pt-5 {
  padding-top: 3rem;
}
.aesirxui .aesirxsso .pe-0 {
  padding-right: 0;
}
.aesirxui .aesirxsso .pe-1 {
  padding-right: 0.25rem;
}
.aesirxui .aesirxsso .pe-2 {
  padding-right: 0.5rem;
}
.aesirxui .aesirxsso .pe-3 {
  padding-right: 1rem;
}
.aesirxui .aesirxsso .pe-4 {
  padding-right: 1.5rem;
}
.aesirxui .aesirxsso .pe-5 {
  padding-right: 3rem;
}
.aesirxui .aesirxsso .pb-0 {
  padding-bottom: 0;
}
.aesirxui .aesirxsso .pb-1 {
  padding-bottom: 0.25rem;
}
.aesirxui .aesirxsso .pb-2 {
  padding-bottom: 0.5rem;
}
.aesirxui .aesirxsso .pb-3 {
  padding-bottom: 1rem;
}
.aesirxui .aesirxsso .pb-4 {
  padding-bottom: 1.5rem;
}
.aesirxui .aesirxsso .pb-5 {
  padding-bottom: 3rem;
}
.aesirxui .aesirxsso .ps-0 {
  padding-left: 0;
}
.aesirxui .aesirxsso .ps-1 {
  padding-left: 0.25rem;
}
.aesirxui .aesirxsso .ps-2 {
  padding-left: 0.5rem;
}
.aesirxui .aesirxsso .ps-3 {
  padding-left: 1rem;
}
.aesirxui .aesirxsso .ps-4 {
  padding-left: 1.5rem;
}
.aesirxui .aesirxsso .ps-5 {
  padding-left: 3rem;
}
.aesirxui .aesirxsso .gap-0 {
  gap: 0;
}
.aesirxui .aesirxsso .gap-1 {
  gap: 0.25rem;
}
.aesirxui .aesirxsso .gap-2 {
  gap: 0.5rem;
}
.aesirxui .aesirxsso .gap-3 {
  gap: 1rem;
}
.aesirxui .aesirxsso .gap-4 {
  gap: 1.5rem;
}
.aesirxui .aesirxsso .gap-5 {
  gap: 3rem;
}
.aesirxui .aesirxsso .row-gap-0 {
  row-gap: 0;
}
.aesirxui .aesirxsso .row-gap-1 {
  row-gap: 0.25rem;
}
.aesirxui .aesirxsso .row-gap-2 {
  row-gap: 0.5rem;
}
.aesirxui .aesirxsso .row-gap-3 {
  row-gap: 1rem;
}
.aesirxui .aesirxsso .row-gap-4 {
  row-gap: 1.5rem;
}
.aesirxui .aesirxsso .row-gap-5 {
  row-gap: 3rem;
}
.aesirxui .aesirxsso .column-gap-0 {
  column-gap: 0;
}
.aesirxui .aesirxsso .column-gap-1 {
  column-gap: 0.25rem;
}
.aesirxui .aesirxsso .column-gap-2 {
  column-gap: 0.5rem;
}
.aesirxui .aesirxsso .column-gap-3 {
  column-gap: 1rem;
}
.aesirxui .aesirxsso .column-gap-4 {
  column-gap: 1.5rem;
}
.aesirxui .aesirxsso .column-gap-5 {
  column-gap: 3rem;
}
.aesirxui .aesirxsso .font-monospace {
  font-family: var(--aesirxsso-font-monospace);
}
.aesirxui .aesirxsso .fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}
.aesirxui .aesirxsso .fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}
.aesirxui .aesirxsso .fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}
.aesirxui .aesirxsso .fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}
.aesirxui .aesirxsso .fs-5 {
  font-size: 1.25rem;
}
.aesirxui .aesirxsso .fs-6 {
  font-size: 1rem;
}
.aesirxui .aesirxsso .fst-italic {
  font-style: italic;
}
.aesirxui .aesirxsso .fst-normal {
  font-style: normal;
}
.aesirxui .aesirxsso .fw-lighter {
  font-weight: lighter;
}
.aesirxui .aesirxsso .fw-light {
  font-weight: 300;
}
.aesirxui .aesirxsso .fw-normal {
  font-weight: 400;
}
.aesirxui .aesirxsso .fw-medium {
  font-weight: 500;
}
.aesirxui .aesirxsso .fw-semibold {
  font-weight: 600;
}
.aesirxui .aesirxsso .fw-bold {
  font-weight: 700;
}
.aesirxui .aesirxsso .fw-bolder {
  font-weight: bolder;
}
.aesirxui .aesirxsso .lh-1 {
  line-height: 1;
}
.aesirxui .aesirxsso .lh-sm {
  line-height: 1.25;
}
.aesirxui .aesirxsso .lh-base {
  line-height: 1.5;
}
.aesirxui .aesirxsso .lh-lg {
  line-height: 2;
}
.aesirxui .aesirxsso .text-start {
  text-align: left;
}
.aesirxui .aesirxsso .text-end {
  text-align: right;
}
.aesirxui .aesirxsso .text-center {
  text-align: center;
}
.aesirxui .aesirxsso .text-decoration-none {
  text-decoration: none;
}
.aesirxui .aesirxsso .text-decoration-underline {
  text-decoration: underline;
}
.aesirxui .aesirxsso .text-decoration-line-through {
  text-decoration: line-through;
}
.aesirxui .aesirxsso .text-lowercase {
  text-transform: lowercase;
}
.aesirxui .aesirxsso .text-uppercase {
  text-transform: uppercase;
}
.aesirxui .aesirxsso .text-capitalize {
  text-transform: capitalize;
}
.aesirxui .aesirxsso .text-wrap {
  white-space: normal;
}
.aesirxui .aesirxsso .text-nowrap {
  white-space: nowrap;
}
.aesirxui .aesirxsso .text-break {
  word-wrap: break-word;
  word-break: break-word;
}
.aesirxui .aesirxsso .text-primary {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-primary-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-secondary {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-secondary-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-success {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-success-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-info {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-info-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-warning {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-warning-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-danger {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-danger-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-light {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-light-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-dark {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-dark-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-black {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-black-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-white {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-white-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-body {
  --aesirxsso-text-opacity: 1;
  color: rgba(var(--aesirxsso-body-color-rgb), var(--aesirxsso-text-opacity));
}
.aesirxui .aesirxsso .text-muted {
  --aesirxsso-text-opacity: 1;
  color: var(--aesirxsso-secondary-color);
}
.aesirxui .aesirxsso .text-black-50 {
  --aesirxsso-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}
.aesirxui .aesirxsso .text-white-50 {
  --aesirxsso-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.aesirxui .aesirxsso .text-body-secondary {
  --aesirxsso-text-opacity: 1;
  color: var(--aesirxsso-secondary-color);
}
.aesirxui .aesirxsso .text-body-tertiary {
  --aesirxsso-text-opacity: 1;
  color: var(--aesirxsso-tertiary-color);
}
.aesirxui .aesirxsso .text-body-emphasis {
  --aesirxsso-text-opacity: 1;
  color: var(--aesirxsso-emphasis-color);
}
.aesirxui .aesirxsso .text-reset {
  --aesirxsso-text-opacity: 1;
  color: inherit;
}
.aesirxui .aesirxsso .text-opacity-25 {
  --aesirxsso-text-opacity: 0.25;
}
.aesirxui .aesirxsso .text-opacity-50 {
  --aesirxsso-text-opacity: 0.5;
}
.aesirxui .aesirxsso .text-opacity-75 {
  --aesirxsso-text-opacity: 0.75;
}
.aesirxui .aesirxsso .text-opacity-100 {
  --aesirxsso-text-opacity: 1;
}
.aesirxui .aesirxsso .text-primary-emphasis {
  color: var(--aesirxsso-primary-text-emphasis);
}
.aesirxui .aesirxsso .text-secondary-emphasis {
  color: var(--aesirxsso-secondary-text-emphasis);
}
.aesirxui .aesirxsso .text-success-emphasis {
  color: var(--aesirxsso-success-text-emphasis);
}
.aesirxui .aesirxsso .text-info-emphasis {
  color: var(--aesirxsso-info-text-emphasis);
}
.aesirxui .aesirxsso .text-warning-emphasis {
  color: var(--aesirxsso-warning-text-emphasis);
}
.aesirxui .aesirxsso .text-danger-emphasis {
  color: var(--aesirxsso-danger-text-emphasis);
}
.aesirxui .aesirxsso .text-light-emphasis {
  color: var(--aesirxsso-light-text-emphasis);
}
.aesirxui .aesirxsso .text-dark-emphasis {
  color: var(--aesirxsso-dark-text-emphasis);
}
.aesirxui .aesirxsso .link-opacity-10 {
  --aesirxsso-link-opacity: 0.1;
}
.aesirxui .aesirxsso .link-opacity-10-hover:hover {
  --aesirxsso-link-opacity: 0.1;
}
.aesirxui .aesirxsso .link-opacity-25 {
  --aesirxsso-link-opacity: 0.25;
}
.aesirxui .aesirxsso .link-opacity-25-hover:hover {
  --aesirxsso-link-opacity: 0.25;
}
.aesirxui .aesirxsso .link-opacity-50 {
  --aesirxsso-link-opacity: 0.5;
}
.aesirxui .aesirxsso .link-opacity-50-hover:hover {
  --aesirxsso-link-opacity: 0.5;
}
.aesirxui .aesirxsso .link-opacity-75 {
  --aesirxsso-link-opacity: 0.75;
}
.aesirxui .aesirxsso .link-opacity-75-hover:hover {
  --aesirxsso-link-opacity: 0.75;
}
.aesirxui .aesirxsso .link-opacity-100 {
  --aesirxsso-link-opacity: 1;
}
.aesirxui .aesirxsso .link-opacity-100-hover:hover {
  --aesirxsso-link-opacity: 1;
}
.aesirxui .aesirxsso .link-offset-1 {
  text-underline-offset: 0.125em;
}
.aesirxui .aesirxsso .link-offset-1-hover:hover {
  text-underline-offset: 0.125em;
}
.aesirxui .aesirxsso .link-offset-2 {
  text-underline-offset: 0.25em;
}
.aesirxui .aesirxsso .link-offset-2-hover:hover {
  text-underline-offset: 0.25em;
}
.aesirxui .aesirxsso .link-offset-3 {
  text-underline-offset: 0.375em;
}
.aesirxui .aesirxsso .link-offset-3-hover:hover {
  text-underline-offset: 0.375em;
}
.aesirxui .aesirxsso .link-underline-primary {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-primary-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-secondary {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-secondary-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-success {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-success-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-info {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-info-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-warning {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-warning-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-danger {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-danger-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-light {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-light-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline-dark {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-dark-rgb), var(--aesirxsso-link-underline-opacity));
}
.aesirxui .aesirxsso .link-underline {
  --aesirxsso-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--aesirxsso-link-color-rgb), var(--aesirxsso-link-underline-opacity, 1));
}
.aesirxui .aesirxsso .link-underline-opacity-0 {
  --aesirxsso-link-underline-opacity: 0;
}
.aesirxui .aesirxsso .link-underline-opacity-0-hover:hover {
  --aesirxsso-link-underline-opacity: 0;
}
.aesirxui .aesirxsso .link-underline-opacity-10 {
  --aesirxsso-link-underline-opacity: 0.1;
}
.aesirxui .aesirxsso .link-underline-opacity-10-hover:hover {
  --aesirxsso-link-underline-opacity: 0.1;
}
.aesirxui .aesirxsso .link-underline-opacity-25 {
  --aesirxsso-link-underline-opacity: 0.25;
}
.aesirxui .aesirxsso .link-underline-opacity-25-hover:hover {
  --aesirxsso-link-underline-opacity: 0.25;
}
.aesirxui .aesirxsso .link-underline-opacity-50 {
  --aesirxsso-link-underline-opacity: 0.5;
}
.aesirxui .aesirxsso .link-underline-opacity-50-hover:hover {
  --aesirxsso-link-underline-opacity: 0.5;
}
.aesirxui .aesirxsso .link-underline-opacity-75 {
  --aesirxsso-link-underline-opacity: 0.75;
}
.aesirxui .aesirxsso .link-underline-opacity-75-hover:hover {
  --aesirxsso-link-underline-opacity: 0.75;
}
.aesirxui .aesirxsso .link-underline-opacity-100 {
  --aesirxsso-link-underline-opacity: 1;
}
.aesirxui .aesirxsso .link-underline-opacity-100-hover:hover {
  --aesirxsso-link-underline-opacity: 1;
}
.aesirxui .aesirxsso .bg-primary {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-primary-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-secondary {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-secondary-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-success {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-success-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-info {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-info-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-warning {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-warning-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-danger {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-danger-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-light {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-light-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-dark {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-dark-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-black {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-black-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-white {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-white-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-body {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-body-bg-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-transparent {
  --aesirxsso-bg-opacity: 1;
  background-color: transparent;
}
.aesirxui .aesirxsso .bg-body-secondary {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-secondary-bg-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-body-tertiary {
  --aesirxsso-bg-opacity: 1;
  background-color: rgba(var(--aesirxsso-tertiary-bg-rgb), var(--aesirxsso-bg-opacity));
}
.aesirxui .aesirxsso .bg-opacity-10 {
  --aesirxsso-bg-opacity: 0.1;
}
.aesirxui .aesirxsso .bg-opacity-25 {
  --aesirxsso-bg-opacity: 0.25;
}
.aesirxui .aesirxsso .bg-opacity-50 {
  --aesirxsso-bg-opacity: 0.5;
}
.aesirxui .aesirxsso .bg-opacity-75 {
  --aesirxsso-bg-opacity: 0.75;
}
.aesirxui .aesirxsso .bg-opacity-100 {
  --aesirxsso-bg-opacity: 1;
}
.aesirxui .aesirxsso .bg-primary-subtle {
  background-color: var(--aesirxsso-primary-bg-subtle);
}
.aesirxui .aesirxsso .bg-secondary-subtle {
  background-color: var(--aesirxsso-secondary-bg-subtle);
}
.aesirxui .aesirxsso .bg-success-subtle {
  background-color: var(--aesirxsso-success-bg-subtle);
}
.aesirxui .aesirxsso .bg-info-subtle {
  background-color: var(--aesirxsso-info-bg-subtle);
}
.aesirxui .aesirxsso .bg-warning-subtle {
  background-color: var(--aesirxsso-warning-bg-subtle);
}
.aesirxui .aesirxsso .bg-danger-subtle {
  background-color: var(--aesirxsso-danger-bg-subtle);
}
.aesirxui .aesirxsso .bg-light-subtle {
  background-color: var(--aesirxsso-light-bg-subtle);
}
.aesirxui .aesirxsso .bg-dark-subtle {
  background-color: var(--aesirxsso-dark-bg-subtle);
}
.aesirxui .aesirxsso .bg-gradient {
  background-image: var(--aesirxsso-gradient);
}
.aesirxui .aesirxsso .user-select-all {
  user-select: all;
}
.aesirxui .aesirxsso .user-select-auto {
  user-select: auto;
}
.aesirxui .aesirxsso .user-select-none {
  user-select: none;
}
.aesirxui .aesirxsso .pe-none {
  pointer-events: none;
}
.aesirxui .aesirxsso .pe-auto {
  pointer-events: auto;
}
.aesirxui .aesirxsso .rounded {
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-0 {
  border-radius: 0;
}
.aesirxui .aesirxsso .rounded-1 {
  border-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .rounded-2 {
  border-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-3 {
  border-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .rounded-4 {
  border-radius: var(--aesirxsso-border-radius-xl);
}
.aesirxui .aesirxsso .rounded-5 {
  border-radius: var(--aesirxsso-border-radius-xxl);
}
.aesirxui .aesirxsso .rounded-circle {
  border-radius: 50%;
}
.aesirxui .aesirxsso .rounded-pill {
  border-radius: var(--aesirxsso-border-radius-pill);
}
.aesirxui .aesirxsso .rounded-top {
  border-top-left-radius: var(--aesirxsso-border-radius);
  border-top-right-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.aesirxui .aesirxsso .rounded-top-1 {
  border-top-left-radius: var(--aesirxsso-border-radius-sm);
  border-top-right-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .rounded-top-2 {
  border-top-left-radius: var(--aesirxsso-border-radius);
  border-top-right-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-top-3 {
  border-top-left-radius: var(--aesirxsso-border-radius-lg);
  border-top-right-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .rounded-top-4 {
  border-top-left-radius: var(--aesirxsso-border-radius-xl);
  border-top-right-radius: var(--aesirxsso-border-radius-xl);
}
.aesirxui .aesirxsso .rounded-top-5 {
  border-top-left-radius: var(--aesirxsso-border-radius-xxl);
  border-top-right-radius: var(--aesirxsso-border-radius-xxl);
}
.aesirxui .aesirxsso .rounded-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}
.aesirxui .aesirxsso .rounded-top-pill {
  border-top-left-radius: var(--aesirxsso-border-radius-pill);
  border-top-right-radius: var(--aesirxsso-border-radius-pill);
}
.aesirxui .aesirxsso .rounded-end {
  border-top-right-radius: var(--aesirxsso-border-radius);
  border-bottom-right-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.aesirxui .aesirxsso .rounded-end-1 {
  border-top-right-radius: var(--aesirxsso-border-radius-sm);
  border-bottom-right-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .rounded-end-2 {
  border-top-right-radius: var(--aesirxsso-border-radius);
  border-bottom-right-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-end-3 {
  border-top-right-radius: var(--aesirxsso-border-radius-lg);
  border-bottom-right-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .rounded-end-4 {
  border-top-right-radius: var(--aesirxsso-border-radius-xl);
  border-bottom-right-radius: var(--aesirxsso-border-radius-xl);
}
.aesirxui .aesirxsso .rounded-end-5 {
  border-top-right-radius: var(--aesirxsso-border-radius-xxl);
  border-bottom-right-radius: var(--aesirxsso-border-radius-xxl);
}
.aesirxui .aesirxsso .rounded-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.aesirxui .aesirxsso .rounded-end-pill {
  border-top-right-radius: var(--aesirxsso-border-radius-pill);
  border-bottom-right-radius: var(--aesirxsso-border-radius-pill);
}
.aesirxui .aesirxsso .rounded-bottom {
  border-bottom-right-radius: var(--aesirxsso-border-radius);
  border-bottom-left-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.aesirxui .aesirxsso .rounded-bottom-1 {
  border-bottom-right-radius: var(--aesirxsso-border-radius-sm);
  border-bottom-left-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .rounded-bottom-2 {
  border-bottom-right-radius: var(--aesirxsso-border-radius);
  border-bottom-left-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-bottom-3 {
  border-bottom-right-radius: var(--aesirxsso-border-radius-lg);
  border-bottom-left-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .rounded-bottom-4 {
  border-bottom-right-radius: var(--aesirxsso-border-radius-xl);
  border-bottom-left-radius: var(--aesirxsso-border-radius-xl);
}
.aesirxui .aesirxsso .rounded-bottom-5 {
  border-bottom-right-radius: var(--aesirxsso-border-radius-xxl);
  border-bottom-left-radius: var(--aesirxsso-border-radius-xxl);
}
.aesirxui .aesirxsso .rounded-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.aesirxui .aesirxsso .rounded-bottom-pill {
  border-bottom-right-radius: var(--aesirxsso-border-radius-pill);
  border-bottom-left-radius: var(--aesirxsso-border-radius-pill);
}
.aesirxui .aesirxsso .rounded-start {
  border-bottom-left-radius: var(--aesirxsso-border-radius);
  border-top-left-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-start-0 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.aesirxui .aesirxsso .rounded-start-1 {
  border-bottom-left-radius: var(--aesirxsso-border-radius-sm);
  border-top-left-radius: var(--aesirxsso-border-radius-sm);
}
.aesirxui .aesirxsso .rounded-start-2 {
  border-bottom-left-radius: var(--aesirxsso-border-radius);
  border-top-left-radius: var(--aesirxsso-border-radius);
}
.aesirxui .aesirxsso .rounded-start-3 {
  border-bottom-left-radius: var(--aesirxsso-border-radius-lg);
  border-top-left-radius: var(--aesirxsso-border-radius-lg);
}
.aesirxui .aesirxsso .rounded-start-4 {
  border-bottom-left-radius: var(--aesirxsso-border-radius-xl);
  border-top-left-radius: var(--aesirxsso-border-radius-xl);
}
.aesirxui .aesirxsso .rounded-start-5 {
  border-bottom-left-radius: var(--aesirxsso-border-radius-xxl);
  border-top-left-radius: var(--aesirxsso-border-radius-xxl);
}
.aesirxui .aesirxsso .rounded-start-circle {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}
.aesirxui .aesirxsso .rounded-start-pill {
  border-bottom-left-radius: var(--aesirxsso-border-radius-pill);
  border-top-left-radius: var(--aesirxsso-border-radius-pill);
}
.aesirxui .aesirxsso .visible {
  visibility: visible;
}
.aesirxui .aesirxsso .invisible {
  visibility: hidden;
}
.aesirxui .aesirxsso .z-n1 {
  z-index: -1;
}
.aesirxui .aesirxsso .z-0 {
  z-index: 0;
}
.aesirxui .aesirxsso .z-1 {
  z-index: 1;
}
.aesirxui .aesirxsso .z-2 {
  z-index: 2;
}
.aesirxui .aesirxsso .z-3 {
  z-index: 3;
}
@media (min-width: 576px) {
  .aesirxui .aesirxsso .float-sm-start {
    float: left;
  }
  .aesirxui .aesirxsso .float-sm-end {
    float: right;
  }
  .aesirxui .aesirxsso .float-sm-none {
    float: none;
  }
  .aesirxui .aesirxsso .object-fit-sm-contain {
    object-fit: contain;
  }
  .aesirxui .aesirxsso .object-fit-sm-cover {
    object-fit: cover;
  }
  .aesirxui .aesirxsso .object-fit-sm-fill {
    object-fit: fill;
  }
  .aesirxui .aesirxsso .object-fit-sm-scale {
    object-fit: scale-down;
  }
  .aesirxui .aesirxsso .object-fit-sm-none {
    object-fit: none;
  }
  .aesirxui .aesirxsso .d-sm-inline {
    display: inline;
  }
  .aesirxui .aesirxsso .d-sm-inline-block {
    display: inline-block;
  }
  .aesirxui .aesirxsso .d-sm-block {
    display: block;
  }
  .aesirxui .aesirxsso .d-sm-grid {
    display: grid;
  }
  .aesirxui .aesirxsso .d-sm-inline-grid {
    display: inline-grid;
  }
  .aesirxui .aesirxsso .d-sm-table {
    display: table;
  }
  .aesirxui .aesirxsso .d-sm-table-row {
    display: table-row;
  }
  .aesirxui .aesirxsso .d-sm-table-cell {
    display: table-cell;
  }
  .aesirxui .aesirxsso .d-sm-flex {
    display: flex;
  }
  .aesirxui .aesirxsso .d-sm-inline-flex {
    display: inline-flex;
  }
  .aesirxui .aesirxsso .d-sm-none {
    display: none;
  }
  .aesirxui .aesirxsso .flex-sm-fill {
    flex: 1 1 auto;
  }
  .aesirxui .aesirxsso .flex-sm-row {
    flex-direction: row;
  }
  .aesirxui .aesirxsso .flex-sm-column {
    flex-direction: column;
  }
  .aesirxui .aesirxsso .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .aesirxui .aesirxsso .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .aesirxui .aesirxsso .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .aesirxui .aesirxsso .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .aesirxui .aesirxsso .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .aesirxui .aesirxsso .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .aesirxui .aesirxsso .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .aesirxui .aesirxsso .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .aesirxui .aesirxsso .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .aesirxui .aesirxsso .justify-content-sm-start {
    justify-content: flex-start;
  }
  .aesirxui .aesirxsso .justify-content-sm-end {
    justify-content: flex-end;
  }
  .aesirxui .aesirxsso .justify-content-sm-center {
    justify-content: center;
  }
  .aesirxui .aesirxsso .justify-content-sm-between {
    justify-content: space-between;
  }
  .aesirxui .aesirxsso .justify-content-sm-around {
    justify-content: space-around;
  }
  .aesirxui .aesirxsso .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .aesirxui .aesirxsso .align-items-sm-start {
    align-items: flex-start;
  }
  .aesirxui .aesirxsso .align-items-sm-end {
    align-items: flex-end;
  }
  .aesirxui .aesirxsso .align-items-sm-center {
    align-items: center;
  }
  .aesirxui .aesirxsso .align-items-sm-baseline {
    align-items: baseline;
  }
  .aesirxui .aesirxsso .align-items-sm-stretch {
    align-items: stretch;
  }
  .aesirxui .aesirxsso .align-content-sm-start {
    align-content: flex-start;
  }
  .aesirxui .aesirxsso .align-content-sm-end {
    align-content: flex-end;
  }
  .aesirxui .aesirxsso .align-content-sm-center {
    align-content: center;
  }
  .aesirxui .aesirxsso .align-content-sm-between {
    align-content: space-between;
  }
  .aesirxui .aesirxsso .align-content-sm-around {
    align-content: space-around;
  }
  .aesirxui .aesirxsso .align-content-sm-stretch {
    align-content: stretch;
  }
  .aesirxui .aesirxsso .align-self-sm-auto {
    align-self: auto;
  }
  .aesirxui .aesirxsso .align-self-sm-start {
    align-self: flex-start;
  }
  .aesirxui .aesirxsso .align-self-sm-end {
    align-self: flex-end;
  }
  .aesirxui .aesirxsso .align-self-sm-center {
    align-self: center;
  }
  .aesirxui .aesirxsso .align-self-sm-baseline {
    align-self: baseline;
  }
  .aesirxui .aesirxsso .align-self-sm-stretch {
    align-self: stretch;
  }
  .aesirxui .aesirxsso .order-sm-first {
    order: -1;
  }
  .aesirxui .aesirxsso .order-sm-0 {
    order: 0;
  }
  .aesirxui .aesirxsso .order-sm-1 {
    order: 1;
  }
  .aesirxui .aesirxsso .order-sm-2 {
    order: 2;
  }
  .aesirxui .aesirxsso .order-sm-3 {
    order: 3;
  }
  .aesirxui .aesirxsso .order-sm-4 {
    order: 4;
  }
  .aesirxui .aesirxsso .order-sm-5 {
    order: 5;
  }
  .aesirxui .aesirxsso .order-sm-last {
    order: 6;
  }
  .aesirxui .aesirxsso .m-sm-0 {
    margin: 0;
  }
  .aesirxui .aesirxsso .m-sm-1 {
    margin: 0.25rem;
  }
  .aesirxui .aesirxsso .m-sm-2 {
    margin: 0.5rem;
  }
  .aesirxui .aesirxsso .m-sm-3 {
    margin: 1rem;
  }
  .aesirxui .aesirxsso .m-sm-4 {
    margin: 1.5rem;
  }
  .aesirxui .aesirxsso .m-sm-5 {
    margin: 3rem;
  }
  .aesirxui .aesirxsso .m-sm-auto {
    margin: auto;
  }
  .aesirxui .aesirxsso .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .aesirxui .aesirxsso .mx-sm-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .mx-sm-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .mx-sm-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .mx-sm-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .mx-sm-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .aesirxui .aesirxsso .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .my-sm-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .my-sm-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .mt-sm-0 {
    margin-top: 0;
  }
  .aesirxui .aesirxsso .mt-sm-1 {
    margin-top: 0.25rem;
  }
  .aesirxui .aesirxsso .mt-sm-2 {
    margin-top: 0.5rem;
  }
  .aesirxui .aesirxsso .mt-sm-3 {
    margin-top: 1rem;
  }
  .aesirxui .aesirxsso .mt-sm-4 {
    margin-top: 1.5rem;
  }
  .aesirxui .aesirxsso .mt-sm-5 {
    margin-top: 3rem;
  }
  .aesirxui .aesirxsso .mt-sm-auto {
    margin-top: auto;
  }
  .aesirxui .aesirxsso .me-sm-0 {
    margin-right: 0;
  }
  .aesirxui .aesirxsso .me-sm-1 {
    margin-right: 0.25rem;
  }
  .aesirxui .aesirxsso .me-sm-2 {
    margin-right: 0.5rem;
  }
  .aesirxui .aesirxsso .me-sm-3 {
    margin-right: 1rem;
  }
  .aesirxui .aesirxsso .me-sm-4 {
    margin-right: 1.5rem;
  }
  .aesirxui .aesirxsso .me-sm-5 {
    margin-right: 3rem;
  }
  .aesirxui .aesirxsso .me-sm-auto {
    margin-right: auto;
  }
  .aesirxui .aesirxsso .mb-sm-0 {
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .mb-sm-1 {
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .mb-sm-2 {
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .mb-sm-3 {
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .mb-sm-4 {
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .mb-sm-5 {
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .mb-sm-auto {
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .ms-sm-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .ms-sm-1 {
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ms-sm-2 {
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ms-sm-3 {
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .ms-sm-4 {
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ms-sm-5 {
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .ms-sm-auto {
    margin-left: auto;
  }
  .aesirxui .aesirxsso .m-sm-n1 {
    margin: -0.25rem;
  }
  .aesirxui .aesirxsso .m-sm-n2 {
    margin: -0.5rem;
  }
  .aesirxui .aesirxsso .m-sm-n3 {
    margin: -1rem;
  }
  .aesirxui .aesirxsso .m-sm-n4 {
    margin: -1.5rem;
  }
  .aesirxui .aesirxsso .m-sm-n5 {
    margin: -3rem;
  }
  .aesirxui .aesirxsso .mx-sm-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .mx-sm-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .mx-sm-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .mx-sm-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .mx-sm-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .my-sm-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .my-sm-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .my-sm-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .my-sm-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .my-sm-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .mt-sm-n1 {
    margin-top: -0.25rem;
  }
  .aesirxui .aesirxsso .mt-sm-n2 {
    margin-top: -0.5rem;
  }
  .aesirxui .aesirxsso .mt-sm-n3 {
    margin-top: -1rem;
  }
  .aesirxui .aesirxsso .mt-sm-n4 {
    margin-top: -1.5rem;
  }
  .aesirxui .aesirxsso .mt-sm-n5 {
    margin-top: -3rem;
  }
  .aesirxui .aesirxsso .me-sm-n1 {
    margin-right: -0.25rem;
  }
  .aesirxui .aesirxsso .me-sm-n2 {
    margin-right: -0.5rem;
  }
  .aesirxui .aesirxsso .me-sm-n3 {
    margin-right: -1rem;
  }
  .aesirxui .aesirxsso .me-sm-n4 {
    margin-right: -1.5rem;
  }
  .aesirxui .aesirxsso .me-sm-n5 {
    margin-right: -3rem;
  }
  .aesirxui .aesirxsso .mb-sm-n1 {
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .mb-sm-n2 {
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .mb-sm-n3 {
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .mb-sm-n4 {
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .mb-sm-n5 {
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .ms-sm-n1 {
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .ms-sm-n2 {
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .ms-sm-n3 {
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .ms-sm-n4 {
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .ms-sm-n5 {
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .p-sm-0 {
    padding: 0;
  }
  .aesirxui .aesirxsso .p-sm-1 {
    padding: 0.25rem;
  }
  .aesirxui .aesirxsso .p-sm-2 {
    padding: 0.5rem;
  }
  .aesirxui .aesirxsso .p-sm-3 {
    padding: 1rem;
  }
  .aesirxui .aesirxsso .p-sm-4 {
    padding: 1.5rem;
  }
  .aesirxui .aesirxsso .p-sm-5 {
    padding: 3rem;
  }
  .aesirxui .aesirxsso .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .aesirxui .aesirxsso .px-sm-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .px-sm-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .px-sm-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .px-sm-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .px-sm-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .py-sm-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .py-sm-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .py-sm-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .pt-sm-0 {
    padding-top: 0;
  }
  .aesirxui .aesirxsso .pt-sm-1 {
    padding-top: 0.25rem;
  }
  .aesirxui .aesirxsso .pt-sm-2 {
    padding-top: 0.5rem;
  }
  .aesirxui .aesirxsso .pt-sm-3 {
    padding-top: 1rem;
  }
  .aesirxui .aesirxsso .pt-sm-4 {
    padding-top: 1.5rem;
  }
  .aesirxui .aesirxsso .pt-sm-5 {
    padding-top: 3rem;
  }
  .aesirxui .aesirxsso .pe-sm-0 {
    padding-right: 0;
  }
  .aesirxui .aesirxsso .pe-sm-1 {
    padding-right: 0.25rem;
  }
  .aesirxui .aesirxsso .pe-sm-2 {
    padding-right: 0.5rem;
  }
  .aesirxui .aesirxsso .pe-sm-3 {
    padding-right: 1rem;
  }
  .aesirxui .aesirxsso .pe-sm-4 {
    padding-right: 1.5rem;
  }
  .aesirxui .aesirxsso .pe-sm-5 {
    padding-right: 3rem;
  }
  .aesirxui .aesirxsso .pb-sm-0 {
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .pb-sm-1 {
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .pb-sm-2 {
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .pb-sm-3 {
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .pb-sm-4 {
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .pb-sm-5 {
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .ps-sm-0 {
    padding-left: 0;
  }
  .aesirxui .aesirxsso .ps-sm-1 {
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ps-sm-2 {
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ps-sm-3 {
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .ps-sm-4 {
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ps-sm-5 {
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .gap-sm-0 {
    gap: 0;
  }
  .aesirxui .aesirxsso .gap-sm-1 {
    gap: 0.25rem;
  }
  .aesirxui .aesirxsso .gap-sm-2 {
    gap: 0.5rem;
  }
  .aesirxui .aesirxsso .gap-sm-3 {
    gap: 1rem;
  }
  .aesirxui .aesirxsso .gap-sm-4 {
    gap: 1.5rem;
  }
  .aesirxui .aesirxsso .gap-sm-5 {
    gap: 3rem;
  }
  .aesirxui .aesirxsso .row-gap-sm-0 {
    row-gap: 0;
  }
  .aesirxui .aesirxsso .row-gap-sm-1 {
    row-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .row-gap-sm-2 {
    row-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .row-gap-sm-3 {
    row-gap: 1rem;
  }
  .aesirxui .aesirxsso .row-gap-sm-4 {
    row-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .row-gap-sm-5 {
    row-gap: 3rem;
  }
  .aesirxui .aesirxsso .column-gap-sm-0 {
    column-gap: 0;
  }
  .aesirxui .aesirxsso .column-gap-sm-1 {
    column-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .column-gap-sm-2 {
    column-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .column-gap-sm-3 {
    column-gap: 1rem;
  }
  .aesirxui .aesirxsso .column-gap-sm-4 {
    column-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .column-gap-sm-5 {
    column-gap: 3rem;
  }
  .aesirxui .aesirxsso .text-sm-start {
    text-align: left;
  }
  .aesirxui .aesirxsso .text-sm-end {
    text-align: right;
  }
  .aesirxui .aesirxsso .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .aesirxui .aesirxsso .float-md-start {
    float: left;
  }
  .aesirxui .aesirxsso .float-md-end {
    float: right;
  }
  .aesirxui .aesirxsso .float-md-none {
    float: none;
  }
  .aesirxui .aesirxsso .object-fit-md-contain {
    object-fit: contain;
  }
  .aesirxui .aesirxsso .object-fit-md-cover {
    object-fit: cover;
  }
  .aesirxui .aesirxsso .object-fit-md-fill {
    object-fit: fill;
  }
  .aesirxui .aesirxsso .object-fit-md-scale {
    object-fit: scale-down;
  }
  .aesirxui .aesirxsso .object-fit-md-none {
    object-fit: none;
  }
  .aesirxui .aesirxsso .d-md-inline {
    display: inline;
  }
  .aesirxui .aesirxsso .d-md-inline-block {
    display: inline-block;
  }
  .aesirxui .aesirxsso .d-md-block {
    display: block;
  }
  .aesirxui .aesirxsso .d-md-grid {
    display: grid;
  }
  .aesirxui .aesirxsso .d-md-inline-grid {
    display: inline-grid;
  }
  .aesirxui .aesirxsso .d-md-table {
    display: table;
  }
  .aesirxui .aesirxsso .d-md-table-row {
    display: table-row;
  }
  .aesirxui .aesirxsso .d-md-table-cell {
    display: table-cell;
  }
  .aesirxui .aesirxsso .d-md-flex {
    display: flex;
  }
  .aesirxui .aesirxsso .d-md-inline-flex {
    display: inline-flex;
  }
  .aesirxui .aesirxsso .d-md-none {
    display: none;
  }
  .aesirxui .aesirxsso .flex-md-fill {
    flex: 1 1 auto;
  }
  .aesirxui .aesirxsso .flex-md-row {
    flex-direction: row;
  }
  .aesirxui .aesirxsso .flex-md-column {
    flex-direction: column;
  }
  .aesirxui .aesirxsso .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .aesirxui .aesirxsso .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .aesirxui .aesirxsso .flex-md-grow-0 {
    flex-grow: 0;
  }
  .aesirxui .aesirxsso .flex-md-grow-1 {
    flex-grow: 1;
  }
  .aesirxui .aesirxsso .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .aesirxui .aesirxsso .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .aesirxui .aesirxsso .flex-md-wrap {
    flex-wrap: wrap;
  }
  .aesirxui .aesirxsso .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .aesirxui .aesirxsso .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .aesirxui .aesirxsso .justify-content-md-start {
    justify-content: flex-start;
  }
  .aesirxui .aesirxsso .justify-content-md-end {
    justify-content: flex-end;
  }
  .aesirxui .aesirxsso .justify-content-md-center {
    justify-content: center;
  }
  .aesirxui .aesirxsso .justify-content-md-between {
    justify-content: space-between;
  }
  .aesirxui .aesirxsso .justify-content-md-around {
    justify-content: space-around;
  }
  .aesirxui .aesirxsso .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .aesirxui .aesirxsso .align-items-md-start {
    align-items: flex-start;
  }
  .aesirxui .aesirxsso .align-items-md-end {
    align-items: flex-end;
  }
  .aesirxui .aesirxsso .align-items-md-center {
    align-items: center;
  }
  .aesirxui .aesirxsso .align-items-md-baseline {
    align-items: baseline;
  }
  .aesirxui .aesirxsso .align-items-md-stretch {
    align-items: stretch;
  }
  .aesirxui .aesirxsso .align-content-md-start {
    align-content: flex-start;
  }
  .aesirxui .aesirxsso .align-content-md-end {
    align-content: flex-end;
  }
  .aesirxui .aesirxsso .align-content-md-center {
    align-content: center;
  }
  .aesirxui .aesirxsso .align-content-md-between {
    align-content: space-between;
  }
  .aesirxui .aesirxsso .align-content-md-around {
    align-content: space-around;
  }
  .aesirxui .aesirxsso .align-content-md-stretch {
    align-content: stretch;
  }
  .aesirxui .aesirxsso .align-self-md-auto {
    align-self: auto;
  }
  .aesirxui .aesirxsso .align-self-md-start {
    align-self: flex-start;
  }
  .aesirxui .aesirxsso .align-self-md-end {
    align-self: flex-end;
  }
  .aesirxui .aesirxsso .align-self-md-center {
    align-self: center;
  }
  .aesirxui .aesirxsso .align-self-md-baseline {
    align-self: baseline;
  }
  .aesirxui .aesirxsso .align-self-md-stretch {
    align-self: stretch;
  }
  .aesirxui .aesirxsso .order-md-first {
    order: -1;
  }
  .aesirxui .aesirxsso .order-md-0 {
    order: 0;
  }
  .aesirxui .aesirxsso .order-md-1 {
    order: 1;
  }
  .aesirxui .aesirxsso .order-md-2 {
    order: 2;
  }
  .aesirxui .aesirxsso .order-md-3 {
    order: 3;
  }
  .aesirxui .aesirxsso .order-md-4 {
    order: 4;
  }
  .aesirxui .aesirxsso .order-md-5 {
    order: 5;
  }
  .aesirxui .aesirxsso .order-md-last {
    order: 6;
  }
  .aesirxui .aesirxsso .m-md-0 {
    margin: 0;
  }
  .aesirxui .aesirxsso .m-md-1 {
    margin: 0.25rem;
  }
  .aesirxui .aesirxsso .m-md-2 {
    margin: 0.5rem;
  }
  .aesirxui .aesirxsso .m-md-3 {
    margin: 1rem;
  }
  .aesirxui .aesirxsso .m-md-4 {
    margin: 1.5rem;
  }
  .aesirxui .aesirxsso .m-md-5 {
    margin: 3rem;
  }
  .aesirxui .aesirxsso .m-md-auto {
    margin: auto;
  }
  .aesirxui .aesirxsso .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .aesirxui .aesirxsso .mx-md-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .mx-md-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .mx-md-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .mx-md-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .mx-md-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .aesirxui .aesirxsso .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .my-md-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .my-md-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .my-md-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .mt-md-0 {
    margin-top: 0;
  }
  .aesirxui .aesirxsso .mt-md-1 {
    margin-top: 0.25rem;
  }
  .aesirxui .aesirxsso .mt-md-2 {
    margin-top: 0.5rem;
  }
  .aesirxui .aesirxsso .mt-md-3 {
    margin-top: 1rem;
  }
  .aesirxui .aesirxsso .mt-md-4 {
    margin-top: 1.5rem;
  }
  .aesirxui .aesirxsso .mt-md-5 {
    margin-top: 3rem;
  }
  .aesirxui .aesirxsso .mt-md-auto {
    margin-top: auto;
  }
  .aesirxui .aesirxsso .me-md-0 {
    margin-right: 0;
  }
  .aesirxui .aesirxsso .me-md-1 {
    margin-right: 0.25rem;
  }
  .aesirxui .aesirxsso .me-md-2 {
    margin-right: 0.5rem;
  }
  .aesirxui .aesirxsso .me-md-3 {
    margin-right: 1rem;
  }
  .aesirxui .aesirxsso .me-md-4 {
    margin-right: 1.5rem;
  }
  .aesirxui .aesirxsso .me-md-5 {
    margin-right: 3rem;
  }
  .aesirxui .aesirxsso .me-md-auto {
    margin-right: auto;
  }
  .aesirxui .aesirxsso .mb-md-0 {
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .mb-md-1 {
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .mb-md-2 {
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .mb-md-3 {
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .mb-md-4 {
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .mb-md-5 {
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .mb-md-auto {
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .ms-md-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .ms-md-1 {
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ms-md-2 {
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ms-md-3 {
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .ms-md-4 {
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ms-md-5 {
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .ms-md-auto {
    margin-left: auto;
  }
  .aesirxui .aesirxsso .m-md-n1 {
    margin: -0.25rem;
  }
  .aesirxui .aesirxsso .m-md-n2 {
    margin: -0.5rem;
  }
  .aesirxui .aesirxsso .m-md-n3 {
    margin: -1rem;
  }
  .aesirxui .aesirxsso .m-md-n4 {
    margin: -1.5rem;
  }
  .aesirxui .aesirxsso .m-md-n5 {
    margin: -3rem;
  }
  .aesirxui .aesirxsso .mx-md-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .mx-md-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .mx-md-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .mx-md-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .mx-md-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .my-md-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .my-md-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .my-md-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .my-md-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .my-md-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .mt-md-n1 {
    margin-top: -0.25rem;
  }
  .aesirxui .aesirxsso .mt-md-n2 {
    margin-top: -0.5rem;
  }
  .aesirxui .aesirxsso .mt-md-n3 {
    margin-top: -1rem;
  }
  .aesirxui .aesirxsso .mt-md-n4 {
    margin-top: -1.5rem;
  }
  .aesirxui .aesirxsso .mt-md-n5 {
    margin-top: -3rem;
  }
  .aesirxui .aesirxsso .me-md-n1 {
    margin-right: -0.25rem;
  }
  .aesirxui .aesirxsso .me-md-n2 {
    margin-right: -0.5rem;
  }
  .aesirxui .aesirxsso .me-md-n3 {
    margin-right: -1rem;
  }
  .aesirxui .aesirxsso .me-md-n4 {
    margin-right: -1.5rem;
  }
  .aesirxui .aesirxsso .me-md-n5 {
    margin-right: -3rem;
  }
  .aesirxui .aesirxsso .mb-md-n1 {
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .mb-md-n2 {
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .mb-md-n3 {
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .mb-md-n4 {
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .mb-md-n5 {
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .ms-md-n1 {
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .ms-md-n2 {
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .ms-md-n3 {
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .ms-md-n4 {
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .ms-md-n5 {
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .p-md-0 {
    padding: 0;
  }
  .aesirxui .aesirxsso .p-md-1 {
    padding: 0.25rem;
  }
  .aesirxui .aesirxsso .p-md-2 {
    padding: 0.5rem;
  }
  .aesirxui .aesirxsso .p-md-3 {
    padding: 1rem;
  }
  .aesirxui .aesirxsso .p-md-4 {
    padding: 1.5rem;
  }
  .aesirxui .aesirxsso .p-md-5 {
    padding: 3rem;
  }
  .aesirxui .aesirxsso .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .aesirxui .aesirxsso .px-md-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .px-md-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .px-md-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .px-md-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .px-md-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .py-md-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .py-md-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .py-md-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .pt-md-0 {
    padding-top: 0;
  }
  .aesirxui .aesirxsso .pt-md-1 {
    padding-top: 0.25rem;
  }
  .aesirxui .aesirxsso .pt-md-2 {
    padding-top: 0.5rem;
  }
  .aesirxui .aesirxsso .pt-md-3 {
    padding-top: 1rem;
  }
  .aesirxui .aesirxsso .pt-md-4 {
    padding-top: 1.5rem;
  }
  .aesirxui .aesirxsso .pt-md-5 {
    padding-top: 3rem;
  }
  .aesirxui .aesirxsso .pe-md-0 {
    padding-right: 0;
  }
  .aesirxui .aesirxsso .pe-md-1 {
    padding-right: 0.25rem;
  }
  .aesirxui .aesirxsso .pe-md-2 {
    padding-right: 0.5rem;
  }
  .aesirxui .aesirxsso .pe-md-3 {
    padding-right: 1rem;
  }
  .aesirxui .aesirxsso .pe-md-4 {
    padding-right: 1.5rem;
  }
  .aesirxui .aesirxsso .pe-md-5 {
    padding-right: 3rem;
  }
  .aesirxui .aesirxsso .pb-md-0 {
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .pb-md-1 {
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .pb-md-2 {
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .pb-md-3 {
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .pb-md-4 {
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .pb-md-5 {
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .ps-md-0 {
    padding-left: 0;
  }
  .aesirxui .aesirxsso .ps-md-1 {
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ps-md-2 {
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ps-md-3 {
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .ps-md-4 {
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ps-md-5 {
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .gap-md-0 {
    gap: 0;
  }
  .aesirxui .aesirxsso .gap-md-1 {
    gap: 0.25rem;
  }
  .aesirxui .aesirxsso .gap-md-2 {
    gap: 0.5rem;
  }
  .aesirxui .aesirxsso .gap-md-3 {
    gap: 1rem;
  }
  .aesirxui .aesirxsso .gap-md-4 {
    gap: 1.5rem;
  }
  .aesirxui .aesirxsso .gap-md-5 {
    gap: 3rem;
  }
  .aesirxui .aesirxsso .row-gap-md-0 {
    row-gap: 0;
  }
  .aesirxui .aesirxsso .row-gap-md-1 {
    row-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .row-gap-md-2 {
    row-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .row-gap-md-3 {
    row-gap: 1rem;
  }
  .aesirxui .aesirxsso .row-gap-md-4 {
    row-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .row-gap-md-5 {
    row-gap: 3rem;
  }
  .aesirxui .aesirxsso .column-gap-md-0 {
    column-gap: 0;
  }
  .aesirxui .aesirxsso .column-gap-md-1 {
    column-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .column-gap-md-2 {
    column-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .column-gap-md-3 {
    column-gap: 1rem;
  }
  .aesirxui .aesirxsso .column-gap-md-4 {
    column-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .column-gap-md-5 {
    column-gap: 3rem;
  }
  .aesirxui .aesirxsso .text-md-start {
    text-align: left;
  }
  .aesirxui .aesirxsso .text-md-end {
    text-align: right;
  }
  .aesirxui .aesirxsso .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .aesirxui .aesirxsso .float-lg-start {
    float: left;
  }
  .aesirxui .aesirxsso .float-lg-end {
    float: right;
  }
  .aesirxui .aesirxsso .float-lg-none {
    float: none;
  }
  .aesirxui .aesirxsso .object-fit-lg-contain {
    object-fit: contain;
  }
  .aesirxui .aesirxsso .object-fit-lg-cover {
    object-fit: cover;
  }
  .aesirxui .aesirxsso .object-fit-lg-fill {
    object-fit: fill;
  }
  .aesirxui .aesirxsso .object-fit-lg-scale {
    object-fit: scale-down;
  }
  .aesirxui .aesirxsso .object-fit-lg-none {
    object-fit: none;
  }
  .aesirxui .aesirxsso .d-lg-inline {
    display: inline;
  }
  .aesirxui .aesirxsso .d-lg-inline-block {
    display: inline-block;
  }
  .aesirxui .aesirxsso .d-lg-block {
    display: block;
  }
  .aesirxui .aesirxsso .d-lg-grid {
    display: grid;
  }
  .aesirxui .aesirxsso .d-lg-inline-grid {
    display: inline-grid;
  }
  .aesirxui .aesirxsso .d-lg-table {
    display: table;
  }
  .aesirxui .aesirxsso .d-lg-table-row {
    display: table-row;
  }
  .aesirxui .aesirxsso .d-lg-table-cell {
    display: table-cell;
  }
  .aesirxui .aesirxsso .d-lg-flex {
    display: flex;
  }
  .aesirxui .aesirxsso .d-lg-inline-flex {
    display: inline-flex;
  }
  .aesirxui .aesirxsso .d-lg-none {
    display: none;
  }
  .aesirxui .aesirxsso .flex-lg-fill {
    flex: 1 1 auto;
  }
  .aesirxui .aesirxsso .flex-lg-row {
    flex-direction: row;
  }
  .aesirxui .aesirxsso .flex-lg-column {
    flex-direction: column;
  }
  .aesirxui .aesirxsso .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .aesirxui .aesirxsso .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .aesirxui .aesirxsso .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .aesirxui .aesirxsso .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .aesirxui .aesirxsso .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .aesirxui .aesirxsso .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .aesirxui .aesirxsso .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .aesirxui .aesirxsso .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .aesirxui .aesirxsso .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .aesirxui .aesirxsso .justify-content-lg-start {
    justify-content: flex-start;
  }
  .aesirxui .aesirxsso .justify-content-lg-end {
    justify-content: flex-end;
  }
  .aesirxui .aesirxsso .justify-content-lg-center {
    justify-content: center;
  }
  .aesirxui .aesirxsso .justify-content-lg-between {
    justify-content: space-between;
  }
  .aesirxui .aesirxsso .justify-content-lg-around {
    justify-content: space-around;
  }
  .aesirxui .aesirxsso .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .aesirxui .aesirxsso .align-items-lg-start {
    align-items: flex-start;
  }
  .aesirxui .aesirxsso .align-items-lg-end {
    align-items: flex-end;
  }
  .aesirxui .aesirxsso .align-items-lg-center {
    align-items: center;
  }
  .aesirxui .aesirxsso .align-items-lg-baseline {
    align-items: baseline;
  }
  .aesirxui .aesirxsso .align-items-lg-stretch {
    align-items: stretch;
  }
  .aesirxui .aesirxsso .align-content-lg-start {
    align-content: flex-start;
  }
  .aesirxui .aesirxsso .align-content-lg-end {
    align-content: flex-end;
  }
  .aesirxui .aesirxsso .align-content-lg-center {
    align-content: center;
  }
  .aesirxui .aesirxsso .align-content-lg-between {
    align-content: space-between;
  }
  .aesirxui .aesirxsso .align-content-lg-around {
    align-content: space-around;
  }
  .aesirxui .aesirxsso .align-content-lg-stretch {
    align-content: stretch;
  }
  .aesirxui .aesirxsso .align-self-lg-auto {
    align-self: auto;
  }
  .aesirxui .aesirxsso .align-self-lg-start {
    align-self: flex-start;
  }
  .aesirxui .aesirxsso .align-self-lg-end {
    align-self: flex-end;
  }
  .aesirxui .aesirxsso .align-self-lg-center {
    align-self: center;
  }
  .aesirxui .aesirxsso .align-self-lg-baseline {
    align-self: baseline;
  }
  .aesirxui .aesirxsso .align-self-lg-stretch {
    align-self: stretch;
  }
  .aesirxui .aesirxsso .order-lg-first {
    order: -1;
  }
  .aesirxui .aesirxsso .order-lg-0 {
    order: 0;
  }
  .aesirxui .aesirxsso .order-lg-1 {
    order: 1;
  }
  .aesirxui .aesirxsso .order-lg-2 {
    order: 2;
  }
  .aesirxui .aesirxsso .order-lg-3 {
    order: 3;
  }
  .aesirxui .aesirxsso .order-lg-4 {
    order: 4;
  }
  .aesirxui .aesirxsso .order-lg-5 {
    order: 5;
  }
  .aesirxui .aesirxsso .order-lg-last {
    order: 6;
  }
  .aesirxui .aesirxsso .m-lg-0 {
    margin: 0;
  }
  .aesirxui .aesirxsso .m-lg-1 {
    margin: 0.25rem;
  }
  .aesirxui .aesirxsso .m-lg-2 {
    margin: 0.5rem;
  }
  .aesirxui .aesirxsso .m-lg-3 {
    margin: 1rem;
  }
  .aesirxui .aesirxsso .m-lg-4 {
    margin: 1.5rem;
  }
  .aesirxui .aesirxsso .m-lg-5 {
    margin: 3rem;
  }
  .aesirxui .aesirxsso .m-lg-auto {
    margin: auto;
  }
  .aesirxui .aesirxsso .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .aesirxui .aesirxsso .mx-lg-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .mx-lg-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .mx-lg-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .mx-lg-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .mx-lg-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .aesirxui .aesirxsso .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .my-lg-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .my-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .my-lg-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .mt-lg-0 {
    margin-top: 0;
  }
  .aesirxui .aesirxsso .mt-lg-1 {
    margin-top: 0.25rem;
  }
  .aesirxui .aesirxsso .mt-lg-2 {
    margin-top: 0.5rem;
  }
  .aesirxui .aesirxsso .mt-lg-3 {
    margin-top: 1rem;
  }
  .aesirxui .aesirxsso .mt-lg-4 {
    margin-top: 1.5rem;
  }
  .aesirxui .aesirxsso .mt-lg-5 {
    margin-top: 3rem;
  }
  .aesirxui .aesirxsso .mt-lg-auto {
    margin-top: auto;
  }
  .aesirxui .aesirxsso .me-lg-0 {
    margin-right: 0;
  }
  .aesirxui .aesirxsso .me-lg-1 {
    margin-right: 0.25rem;
  }
  .aesirxui .aesirxsso .me-lg-2 {
    margin-right: 0.5rem;
  }
  .aesirxui .aesirxsso .me-lg-3 {
    margin-right: 1rem;
  }
  .aesirxui .aesirxsso .me-lg-4 {
    margin-right: 1.5rem;
  }
  .aesirxui .aesirxsso .me-lg-5 {
    margin-right: 3rem;
  }
  .aesirxui .aesirxsso .me-lg-auto {
    margin-right: auto;
  }
  .aesirxui .aesirxsso .mb-lg-0 {
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .mb-lg-1 {
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .mb-lg-2 {
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .mb-lg-3 {
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .mb-lg-4 {
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .mb-lg-5 {
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .mb-lg-auto {
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .ms-lg-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .ms-lg-1 {
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ms-lg-2 {
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ms-lg-3 {
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .ms-lg-4 {
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ms-lg-5 {
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .ms-lg-auto {
    margin-left: auto;
  }
  .aesirxui .aesirxsso .m-lg-n1 {
    margin: -0.25rem;
  }
  .aesirxui .aesirxsso .m-lg-n2 {
    margin: -0.5rem;
  }
  .aesirxui .aesirxsso .m-lg-n3 {
    margin: -1rem;
  }
  .aesirxui .aesirxsso .m-lg-n4 {
    margin: -1.5rem;
  }
  .aesirxui .aesirxsso .m-lg-n5 {
    margin: -3rem;
  }
  .aesirxui .aesirxsso .mx-lg-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .mx-lg-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .mx-lg-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .mx-lg-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .mx-lg-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .my-lg-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .my-lg-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .my-lg-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .my-lg-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .my-lg-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .mt-lg-n1 {
    margin-top: -0.25rem;
  }
  .aesirxui .aesirxsso .mt-lg-n2 {
    margin-top: -0.5rem;
  }
  .aesirxui .aesirxsso .mt-lg-n3 {
    margin-top: -1rem;
  }
  .aesirxui .aesirxsso .mt-lg-n4 {
    margin-top: -1.5rem;
  }
  .aesirxui .aesirxsso .mt-lg-n5 {
    margin-top: -3rem;
  }
  .aesirxui .aesirxsso .me-lg-n1 {
    margin-right: -0.25rem;
  }
  .aesirxui .aesirxsso .me-lg-n2 {
    margin-right: -0.5rem;
  }
  .aesirxui .aesirxsso .me-lg-n3 {
    margin-right: -1rem;
  }
  .aesirxui .aesirxsso .me-lg-n4 {
    margin-right: -1.5rem;
  }
  .aesirxui .aesirxsso .me-lg-n5 {
    margin-right: -3rem;
  }
  .aesirxui .aesirxsso .mb-lg-n1 {
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .mb-lg-n2 {
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .mb-lg-n3 {
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .mb-lg-n4 {
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .mb-lg-n5 {
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .ms-lg-n1 {
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .ms-lg-n2 {
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .ms-lg-n3 {
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .ms-lg-n4 {
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .ms-lg-n5 {
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .p-lg-0 {
    padding: 0;
  }
  .aesirxui .aesirxsso .p-lg-1 {
    padding: 0.25rem;
  }
  .aesirxui .aesirxsso .p-lg-2 {
    padding: 0.5rem;
  }
  .aesirxui .aesirxsso .p-lg-3 {
    padding: 1rem;
  }
  .aesirxui .aesirxsso .p-lg-4 {
    padding: 1.5rem;
  }
  .aesirxui .aesirxsso .p-lg-5 {
    padding: 3rem;
  }
  .aesirxui .aesirxsso .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .aesirxui .aesirxsso .px-lg-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .px-lg-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .px-lg-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .px-lg-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .px-lg-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .py-lg-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .py-lg-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .py-lg-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .pt-lg-0 {
    padding-top: 0;
  }
  .aesirxui .aesirxsso .pt-lg-1 {
    padding-top: 0.25rem;
  }
  .aesirxui .aesirxsso .pt-lg-2 {
    padding-top: 0.5rem;
  }
  .aesirxui .aesirxsso .pt-lg-3 {
    padding-top: 1rem;
  }
  .aesirxui .aesirxsso .pt-lg-4 {
    padding-top: 1.5rem;
  }
  .aesirxui .aesirxsso .pt-lg-5 {
    padding-top: 3rem;
  }
  .aesirxui .aesirxsso .pe-lg-0 {
    padding-right: 0;
  }
  .aesirxui .aesirxsso .pe-lg-1 {
    padding-right: 0.25rem;
  }
  .aesirxui .aesirxsso .pe-lg-2 {
    padding-right: 0.5rem;
  }
  .aesirxui .aesirxsso .pe-lg-3 {
    padding-right: 1rem;
  }
  .aesirxui .aesirxsso .pe-lg-4 {
    padding-right: 1.5rem;
  }
  .aesirxui .aesirxsso .pe-lg-5 {
    padding-right: 3rem;
  }
  .aesirxui .aesirxsso .pb-lg-0 {
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .pb-lg-1 {
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .pb-lg-2 {
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .pb-lg-3 {
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .pb-lg-4 {
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .pb-lg-5 {
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .ps-lg-0 {
    padding-left: 0;
  }
  .aesirxui .aesirxsso .ps-lg-1 {
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ps-lg-2 {
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ps-lg-3 {
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .ps-lg-4 {
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ps-lg-5 {
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .gap-lg-0 {
    gap: 0;
  }
  .aesirxui .aesirxsso .gap-lg-1 {
    gap: 0.25rem;
  }
  .aesirxui .aesirxsso .gap-lg-2 {
    gap: 0.5rem;
  }
  .aesirxui .aesirxsso .gap-lg-3 {
    gap: 1rem;
  }
  .aesirxui .aesirxsso .gap-lg-4 {
    gap: 1.5rem;
  }
  .aesirxui .aesirxsso .gap-lg-5 {
    gap: 3rem;
  }
  .aesirxui .aesirxsso .row-gap-lg-0 {
    row-gap: 0;
  }
  .aesirxui .aesirxsso .row-gap-lg-1 {
    row-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .row-gap-lg-2 {
    row-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .row-gap-lg-3 {
    row-gap: 1rem;
  }
  .aesirxui .aesirxsso .row-gap-lg-4 {
    row-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .row-gap-lg-5 {
    row-gap: 3rem;
  }
  .aesirxui .aesirxsso .column-gap-lg-0 {
    column-gap: 0;
  }
  .aesirxui .aesirxsso .column-gap-lg-1 {
    column-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .column-gap-lg-2 {
    column-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .column-gap-lg-3 {
    column-gap: 1rem;
  }
  .aesirxui .aesirxsso .column-gap-lg-4 {
    column-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .column-gap-lg-5 {
    column-gap: 3rem;
  }
  .aesirxui .aesirxsso .text-lg-start {
    text-align: left;
  }
  .aesirxui .aesirxsso .text-lg-end {
    text-align: right;
  }
  .aesirxui .aesirxsso .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .float-xl-start {
    float: left;
  }
  .aesirxui .aesirxsso .float-xl-end {
    float: right;
  }
  .aesirxui .aesirxsso .float-xl-none {
    float: none;
  }
  .aesirxui .aesirxsso .object-fit-xl-contain {
    object-fit: contain;
  }
  .aesirxui .aesirxsso .object-fit-xl-cover {
    object-fit: cover;
  }
  .aesirxui .aesirxsso .object-fit-xl-fill {
    object-fit: fill;
  }
  .aesirxui .aesirxsso .object-fit-xl-scale {
    object-fit: scale-down;
  }
  .aesirxui .aesirxsso .object-fit-xl-none {
    object-fit: none;
  }
  .aesirxui .aesirxsso .d-xl-inline {
    display: inline;
  }
  .aesirxui .aesirxsso .d-xl-inline-block {
    display: inline-block;
  }
  .aesirxui .aesirxsso .d-xl-block {
    display: block;
  }
  .aesirxui .aesirxsso .d-xl-grid {
    display: grid;
  }
  .aesirxui .aesirxsso .d-xl-inline-grid {
    display: inline-grid;
  }
  .aesirxui .aesirxsso .d-xl-table {
    display: table;
  }
  .aesirxui .aesirxsso .d-xl-table-row {
    display: table-row;
  }
  .aesirxui .aesirxsso .d-xl-table-cell {
    display: table-cell;
  }
  .aesirxui .aesirxsso .d-xl-flex {
    display: flex;
  }
  .aesirxui .aesirxsso .d-xl-inline-flex {
    display: inline-flex;
  }
  .aesirxui .aesirxsso .d-xl-none {
    display: none;
  }
  .aesirxui .aesirxsso .flex-xl-fill {
    flex: 1 1 auto;
  }
  .aesirxui .aesirxsso .flex-xl-row {
    flex-direction: row;
  }
  .aesirxui .aesirxsso .flex-xl-column {
    flex-direction: column;
  }
  .aesirxui .aesirxsso .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .aesirxui .aesirxsso .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .aesirxui .aesirxsso .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .aesirxui .aesirxsso .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .aesirxui .aesirxsso .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .aesirxui .aesirxsso .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .aesirxui .aesirxsso .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .aesirxui .aesirxsso .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .aesirxui .aesirxsso .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .aesirxui .aesirxsso .justify-content-xl-start {
    justify-content: flex-start;
  }
  .aesirxui .aesirxsso .justify-content-xl-end {
    justify-content: flex-end;
  }
  .aesirxui .aesirxsso .justify-content-xl-center {
    justify-content: center;
  }
  .aesirxui .aesirxsso .justify-content-xl-between {
    justify-content: space-between;
  }
  .aesirxui .aesirxsso .justify-content-xl-around {
    justify-content: space-around;
  }
  .aesirxui .aesirxsso .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .aesirxui .aesirxsso .align-items-xl-start {
    align-items: flex-start;
  }
  .aesirxui .aesirxsso .align-items-xl-end {
    align-items: flex-end;
  }
  .aesirxui .aesirxsso .align-items-xl-center {
    align-items: center;
  }
  .aesirxui .aesirxsso .align-items-xl-baseline {
    align-items: baseline;
  }
  .aesirxui .aesirxsso .align-items-xl-stretch {
    align-items: stretch;
  }
  .aesirxui .aesirxsso .align-content-xl-start {
    align-content: flex-start;
  }
  .aesirxui .aesirxsso .align-content-xl-end {
    align-content: flex-end;
  }
  .aesirxui .aesirxsso .align-content-xl-center {
    align-content: center;
  }
  .aesirxui .aesirxsso .align-content-xl-between {
    align-content: space-between;
  }
  .aesirxui .aesirxsso .align-content-xl-around {
    align-content: space-around;
  }
  .aesirxui .aesirxsso .align-content-xl-stretch {
    align-content: stretch;
  }
  .aesirxui .aesirxsso .align-self-xl-auto {
    align-self: auto;
  }
  .aesirxui .aesirxsso .align-self-xl-start {
    align-self: flex-start;
  }
  .aesirxui .aesirxsso .align-self-xl-end {
    align-self: flex-end;
  }
  .aesirxui .aesirxsso .align-self-xl-center {
    align-self: center;
  }
  .aesirxui .aesirxsso .align-self-xl-baseline {
    align-self: baseline;
  }
  .aesirxui .aesirxsso .align-self-xl-stretch {
    align-self: stretch;
  }
  .aesirxui .aesirxsso .order-xl-first {
    order: -1;
  }
  .aesirxui .aesirxsso .order-xl-0 {
    order: 0;
  }
  .aesirxui .aesirxsso .order-xl-1 {
    order: 1;
  }
  .aesirxui .aesirxsso .order-xl-2 {
    order: 2;
  }
  .aesirxui .aesirxsso .order-xl-3 {
    order: 3;
  }
  .aesirxui .aesirxsso .order-xl-4 {
    order: 4;
  }
  .aesirxui .aesirxsso .order-xl-5 {
    order: 5;
  }
  .aesirxui .aesirxsso .order-xl-last {
    order: 6;
  }
  .aesirxui .aesirxsso .m-xl-0 {
    margin: 0;
  }
  .aesirxui .aesirxsso .m-xl-1 {
    margin: 0.25rem;
  }
  .aesirxui .aesirxsso .m-xl-2 {
    margin: 0.5rem;
  }
  .aesirxui .aesirxsso .m-xl-3 {
    margin: 1rem;
  }
  .aesirxui .aesirxsso .m-xl-4 {
    margin: 1.5rem;
  }
  .aesirxui .aesirxsso .m-xl-5 {
    margin: 3rem;
  }
  .aesirxui .aesirxsso .m-xl-auto {
    margin: auto;
  }
  .aesirxui .aesirxsso .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .aesirxui .aesirxsso .mx-xl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .mx-xl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .mx-xl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .mx-xl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .mx-xl-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .aesirxui .aesirxsso .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .my-xl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .my-xl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .my-xl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .mt-xl-0 {
    margin-top: 0;
  }
  .aesirxui .aesirxsso .mt-xl-1 {
    margin-top: 0.25rem;
  }
  .aesirxui .aesirxsso .mt-xl-2 {
    margin-top: 0.5rem;
  }
  .aesirxui .aesirxsso .mt-xl-3 {
    margin-top: 1rem;
  }
  .aesirxui .aesirxsso .mt-xl-4 {
    margin-top: 1.5rem;
  }
  .aesirxui .aesirxsso .mt-xl-5 {
    margin-top: 3rem;
  }
  .aesirxui .aesirxsso .mt-xl-auto {
    margin-top: auto;
  }
  .aesirxui .aesirxsso .me-xl-0 {
    margin-right: 0;
  }
  .aesirxui .aesirxsso .me-xl-1 {
    margin-right: 0.25rem;
  }
  .aesirxui .aesirxsso .me-xl-2 {
    margin-right: 0.5rem;
  }
  .aesirxui .aesirxsso .me-xl-3 {
    margin-right: 1rem;
  }
  .aesirxui .aesirxsso .me-xl-4 {
    margin-right: 1.5rem;
  }
  .aesirxui .aesirxsso .me-xl-5 {
    margin-right: 3rem;
  }
  .aesirxui .aesirxsso .me-xl-auto {
    margin-right: auto;
  }
  .aesirxui .aesirxsso .mb-xl-0 {
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .mb-xl-1 {
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .mb-xl-2 {
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .mb-xl-3 {
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .mb-xl-4 {
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .mb-xl-5 {
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .mb-xl-auto {
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .ms-xl-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .ms-xl-1 {
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ms-xl-2 {
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ms-xl-3 {
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .ms-xl-4 {
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ms-xl-5 {
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .ms-xl-auto {
    margin-left: auto;
  }
  .aesirxui .aesirxsso .m-xl-n1 {
    margin: -0.25rem;
  }
  .aesirxui .aesirxsso .m-xl-n2 {
    margin: -0.5rem;
  }
  .aesirxui .aesirxsso .m-xl-n3 {
    margin: -1rem;
  }
  .aesirxui .aesirxsso .m-xl-n4 {
    margin: -1.5rem;
  }
  .aesirxui .aesirxsso .m-xl-n5 {
    margin: -3rem;
  }
  .aesirxui .aesirxsso .mx-xl-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .mx-xl-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .mx-xl-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .mx-xl-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .mx-xl-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .my-xl-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .my-xl-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .my-xl-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .my-xl-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .my-xl-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .mt-xl-n1 {
    margin-top: -0.25rem;
  }
  .aesirxui .aesirxsso .mt-xl-n2 {
    margin-top: -0.5rem;
  }
  .aesirxui .aesirxsso .mt-xl-n3 {
    margin-top: -1rem;
  }
  .aesirxui .aesirxsso .mt-xl-n4 {
    margin-top: -1.5rem;
  }
  .aesirxui .aesirxsso .mt-xl-n5 {
    margin-top: -3rem;
  }
  .aesirxui .aesirxsso .me-xl-n1 {
    margin-right: -0.25rem;
  }
  .aesirxui .aesirxsso .me-xl-n2 {
    margin-right: -0.5rem;
  }
  .aesirxui .aesirxsso .me-xl-n3 {
    margin-right: -1rem;
  }
  .aesirxui .aesirxsso .me-xl-n4 {
    margin-right: -1.5rem;
  }
  .aesirxui .aesirxsso .me-xl-n5 {
    margin-right: -3rem;
  }
  .aesirxui .aesirxsso .mb-xl-n1 {
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .mb-xl-n2 {
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .mb-xl-n3 {
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .mb-xl-n4 {
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .mb-xl-n5 {
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .ms-xl-n1 {
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .ms-xl-n2 {
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .ms-xl-n3 {
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .ms-xl-n4 {
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .ms-xl-n5 {
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .p-xl-0 {
    padding: 0;
  }
  .aesirxui .aesirxsso .p-xl-1 {
    padding: 0.25rem;
  }
  .aesirxui .aesirxsso .p-xl-2 {
    padding: 0.5rem;
  }
  .aesirxui .aesirxsso .p-xl-3 {
    padding: 1rem;
  }
  .aesirxui .aesirxsso .p-xl-4 {
    padding: 1.5rem;
  }
  .aesirxui .aesirxsso .p-xl-5 {
    padding: 3rem;
  }
  .aesirxui .aesirxsso .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .aesirxui .aesirxsso .px-xl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .px-xl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .px-xl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .px-xl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .px-xl-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .py-xl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .py-xl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .py-xl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .pt-xl-0 {
    padding-top: 0;
  }
  .aesirxui .aesirxsso .pt-xl-1 {
    padding-top: 0.25rem;
  }
  .aesirxui .aesirxsso .pt-xl-2 {
    padding-top: 0.5rem;
  }
  .aesirxui .aesirxsso .pt-xl-3 {
    padding-top: 1rem;
  }
  .aesirxui .aesirxsso .pt-xl-4 {
    padding-top: 1.5rem;
  }
  .aesirxui .aesirxsso .pt-xl-5 {
    padding-top: 3rem;
  }
  .aesirxui .aesirxsso .pe-xl-0 {
    padding-right: 0;
  }
  .aesirxui .aesirxsso .pe-xl-1 {
    padding-right: 0.25rem;
  }
  .aesirxui .aesirxsso .pe-xl-2 {
    padding-right: 0.5rem;
  }
  .aesirxui .aesirxsso .pe-xl-3 {
    padding-right: 1rem;
  }
  .aesirxui .aesirxsso .pe-xl-4 {
    padding-right: 1.5rem;
  }
  .aesirxui .aesirxsso .pe-xl-5 {
    padding-right: 3rem;
  }
  .aesirxui .aesirxsso .pb-xl-0 {
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .pb-xl-1 {
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .pb-xl-2 {
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .pb-xl-3 {
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .pb-xl-4 {
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .pb-xl-5 {
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .ps-xl-0 {
    padding-left: 0;
  }
  .aesirxui .aesirxsso .ps-xl-1 {
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ps-xl-2 {
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ps-xl-3 {
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .ps-xl-4 {
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ps-xl-5 {
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .gap-xl-0 {
    gap: 0;
  }
  .aesirxui .aesirxsso .gap-xl-1 {
    gap: 0.25rem;
  }
  .aesirxui .aesirxsso .gap-xl-2 {
    gap: 0.5rem;
  }
  .aesirxui .aesirxsso .gap-xl-3 {
    gap: 1rem;
  }
  .aesirxui .aesirxsso .gap-xl-4 {
    gap: 1.5rem;
  }
  .aesirxui .aesirxsso .gap-xl-5 {
    gap: 3rem;
  }
  .aesirxui .aesirxsso .row-gap-xl-0 {
    row-gap: 0;
  }
  .aesirxui .aesirxsso .row-gap-xl-1 {
    row-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .row-gap-xl-2 {
    row-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .row-gap-xl-3 {
    row-gap: 1rem;
  }
  .aesirxui .aesirxsso .row-gap-xl-4 {
    row-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .row-gap-xl-5 {
    row-gap: 3rem;
  }
  .aesirxui .aesirxsso .column-gap-xl-0 {
    column-gap: 0;
  }
  .aesirxui .aesirxsso .column-gap-xl-1 {
    column-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .column-gap-xl-2 {
    column-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .column-gap-xl-3 {
    column-gap: 1rem;
  }
  .aesirxui .aesirxsso .column-gap-xl-4 {
    column-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .column-gap-xl-5 {
    column-gap: 3rem;
  }
  .aesirxui .aesirxsso .text-xl-start {
    text-align: left;
  }
  .aesirxui .aesirxsso .text-xl-end {
    text-align: right;
  }
  .aesirxui .aesirxsso .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .aesirxui .aesirxsso .float-xxl-start {
    float: left;
  }
  .aesirxui .aesirxsso .float-xxl-end {
    float: right;
  }
  .aesirxui .aesirxsso .float-xxl-none {
    float: none;
  }
  .aesirxui .aesirxsso .object-fit-xxl-contain {
    object-fit: contain;
  }
  .aesirxui .aesirxsso .object-fit-xxl-cover {
    object-fit: cover;
  }
  .aesirxui .aesirxsso .object-fit-xxl-fill {
    object-fit: fill;
  }
  .aesirxui .aesirxsso .object-fit-xxl-scale {
    object-fit: scale-down;
  }
  .aesirxui .aesirxsso .object-fit-xxl-none {
    object-fit: none;
  }
  .aesirxui .aesirxsso .d-xxl-inline {
    display: inline;
  }
  .aesirxui .aesirxsso .d-xxl-inline-block {
    display: inline-block;
  }
  .aesirxui .aesirxsso .d-xxl-block {
    display: block;
  }
  .aesirxui .aesirxsso .d-xxl-grid {
    display: grid;
  }
  .aesirxui .aesirxsso .d-xxl-inline-grid {
    display: inline-grid;
  }
  .aesirxui .aesirxsso .d-xxl-table {
    display: table;
  }
  .aesirxui .aesirxsso .d-xxl-table-row {
    display: table-row;
  }
  .aesirxui .aesirxsso .d-xxl-table-cell {
    display: table-cell;
  }
  .aesirxui .aesirxsso .d-xxl-flex {
    display: flex;
  }
  .aesirxui .aesirxsso .d-xxl-inline-flex {
    display: inline-flex;
  }
  .aesirxui .aesirxsso .d-xxl-none {
    display: none;
  }
  .aesirxui .aesirxsso .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .aesirxui .aesirxsso .flex-xxl-row {
    flex-direction: row;
  }
  .aesirxui .aesirxsso .flex-xxl-column {
    flex-direction: column;
  }
  .aesirxui .aesirxsso .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .aesirxui .aesirxsso .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .aesirxui .aesirxsso .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .aesirxui .aesirxsso .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .aesirxui .aesirxsso .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .aesirxui .aesirxsso .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .aesirxui .aesirxsso .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .aesirxui .aesirxsso .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .aesirxui .aesirxsso .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .aesirxui .aesirxsso .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .aesirxui .aesirxsso .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .aesirxui .aesirxsso .justify-content-xxl-center {
    justify-content: center;
  }
  .aesirxui .aesirxsso .justify-content-xxl-between {
    justify-content: space-between;
  }
  .aesirxui .aesirxsso .justify-content-xxl-around {
    justify-content: space-around;
  }
  .aesirxui .aesirxsso .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .aesirxui .aesirxsso .align-items-xxl-start {
    align-items: flex-start;
  }
  .aesirxui .aesirxsso .align-items-xxl-end {
    align-items: flex-end;
  }
  .aesirxui .aesirxsso .align-items-xxl-center {
    align-items: center;
  }
  .aesirxui .aesirxsso .align-items-xxl-baseline {
    align-items: baseline;
  }
  .aesirxui .aesirxsso .align-items-xxl-stretch {
    align-items: stretch;
  }
  .aesirxui .aesirxsso .align-content-xxl-start {
    align-content: flex-start;
  }
  .aesirxui .aesirxsso .align-content-xxl-end {
    align-content: flex-end;
  }
  .aesirxui .aesirxsso .align-content-xxl-center {
    align-content: center;
  }
  .aesirxui .aesirxsso .align-content-xxl-between {
    align-content: space-between;
  }
  .aesirxui .aesirxsso .align-content-xxl-around {
    align-content: space-around;
  }
  .aesirxui .aesirxsso .align-content-xxl-stretch {
    align-content: stretch;
  }
  .aesirxui .aesirxsso .align-self-xxl-auto {
    align-self: auto;
  }
  .aesirxui .aesirxsso .align-self-xxl-start {
    align-self: flex-start;
  }
  .aesirxui .aesirxsso .align-self-xxl-end {
    align-self: flex-end;
  }
  .aesirxui .aesirxsso .align-self-xxl-center {
    align-self: center;
  }
  .aesirxui .aesirxsso .align-self-xxl-baseline {
    align-self: baseline;
  }
  .aesirxui .aesirxsso .align-self-xxl-stretch {
    align-self: stretch;
  }
  .aesirxui .aesirxsso .order-xxl-first {
    order: -1;
  }
  .aesirxui .aesirxsso .order-xxl-0 {
    order: 0;
  }
  .aesirxui .aesirxsso .order-xxl-1 {
    order: 1;
  }
  .aesirxui .aesirxsso .order-xxl-2 {
    order: 2;
  }
  .aesirxui .aesirxsso .order-xxl-3 {
    order: 3;
  }
  .aesirxui .aesirxsso .order-xxl-4 {
    order: 4;
  }
  .aesirxui .aesirxsso .order-xxl-5 {
    order: 5;
  }
  .aesirxui .aesirxsso .order-xxl-last {
    order: 6;
  }
  .aesirxui .aesirxsso .m-xxl-0 {
    margin: 0;
  }
  .aesirxui .aesirxsso .m-xxl-1 {
    margin: 0.25rem;
  }
  .aesirxui .aesirxsso .m-xxl-2 {
    margin: 0.5rem;
  }
  .aesirxui .aesirxsso .m-xxl-3 {
    margin: 1rem;
  }
  .aesirxui .aesirxsso .m-xxl-4 {
    margin: 1.5rem;
  }
  .aesirxui .aesirxsso .m-xxl-5 {
    margin: 3rem;
  }
  .aesirxui .aesirxsso .m-xxl-auto {
    margin: auto;
  }
  .aesirxui .aesirxsso .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .aesirxui .aesirxsso .mx-xxl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .mx-xxl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .mx-xxl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .mx-xxl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .mx-xxl-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .aesirxui .aesirxsso .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .my-xxl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .my-xxl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .my-xxl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .mt-xxl-0 {
    margin-top: 0;
  }
  .aesirxui .aesirxsso .mt-xxl-1 {
    margin-top: 0.25rem;
  }
  .aesirxui .aesirxsso .mt-xxl-2 {
    margin-top: 0.5rem;
  }
  .aesirxui .aesirxsso .mt-xxl-3 {
    margin-top: 1rem;
  }
  .aesirxui .aesirxsso .mt-xxl-4 {
    margin-top: 1.5rem;
  }
  .aesirxui .aesirxsso .mt-xxl-5 {
    margin-top: 3rem;
  }
  .aesirxui .aesirxsso .mt-xxl-auto {
    margin-top: auto;
  }
  .aesirxui .aesirxsso .me-xxl-0 {
    margin-right: 0;
  }
  .aesirxui .aesirxsso .me-xxl-1 {
    margin-right: 0.25rem;
  }
  .aesirxui .aesirxsso .me-xxl-2 {
    margin-right: 0.5rem;
  }
  .aesirxui .aesirxsso .me-xxl-3 {
    margin-right: 1rem;
  }
  .aesirxui .aesirxsso .me-xxl-4 {
    margin-right: 1.5rem;
  }
  .aesirxui .aesirxsso .me-xxl-5 {
    margin-right: 3rem;
  }
  .aesirxui .aesirxsso .me-xxl-auto {
    margin-right: auto;
  }
  .aesirxui .aesirxsso .mb-xxl-0 {
    margin-bottom: 0;
  }
  .aesirxui .aesirxsso .mb-xxl-1 {
    margin-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .mb-xxl-2 {
    margin-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .mb-xxl-3 {
    margin-bottom: 1rem;
  }
  .aesirxui .aesirxsso .mb-xxl-4 {
    margin-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .mb-xxl-5 {
    margin-bottom: 3rem;
  }
  .aesirxui .aesirxsso .mb-xxl-auto {
    margin-bottom: auto;
  }
  .aesirxui .aesirxsso .ms-xxl-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .ms-xxl-1 {
    margin-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ms-xxl-2 {
    margin-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ms-xxl-3 {
    margin-left: 1rem;
  }
  .aesirxui .aesirxsso .ms-xxl-4 {
    margin-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ms-xxl-5 {
    margin-left: 3rem;
  }
  .aesirxui .aesirxsso .ms-xxl-auto {
    margin-left: auto;
  }
  .aesirxui .aesirxsso .m-xxl-n1 {
    margin: -0.25rem;
  }
  .aesirxui .aesirxsso .m-xxl-n2 {
    margin: -0.5rem;
  }
  .aesirxui .aesirxsso .m-xxl-n3 {
    margin: -1rem;
  }
  .aesirxui .aesirxsso .m-xxl-n4 {
    margin: -1.5rem;
  }
  .aesirxui .aesirxsso .m-xxl-n5 {
    margin: -3rem;
  }
  .aesirxui .aesirxsso .mx-xxl-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .mx-xxl-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .mx-xxl-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .mx-xxl-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .mx-xxl-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .my-xxl-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .my-xxl-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .my-xxl-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .my-xxl-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .my-xxl-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .mt-xxl-n1 {
    margin-top: -0.25rem;
  }
  .aesirxui .aesirxsso .mt-xxl-n2 {
    margin-top: -0.5rem;
  }
  .aesirxui .aesirxsso .mt-xxl-n3 {
    margin-top: -1rem;
  }
  .aesirxui .aesirxsso .mt-xxl-n4 {
    margin-top: -1.5rem;
  }
  .aesirxui .aesirxsso .mt-xxl-n5 {
    margin-top: -3rem;
  }
  .aesirxui .aesirxsso .me-xxl-n1 {
    margin-right: -0.25rem;
  }
  .aesirxui .aesirxsso .me-xxl-n2 {
    margin-right: -0.5rem;
  }
  .aesirxui .aesirxsso .me-xxl-n3 {
    margin-right: -1rem;
  }
  .aesirxui .aesirxsso .me-xxl-n4 {
    margin-right: -1.5rem;
  }
  .aesirxui .aesirxsso .me-xxl-n5 {
    margin-right: -3rem;
  }
  .aesirxui .aesirxsso .mb-xxl-n1 {
    margin-bottom: -0.25rem;
  }
  .aesirxui .aesirxsso .mb-xxl-n2 {
    margin-bottom: -0.5rem;
  }
  .aesirxui .aesirxsso .mb-xxl-n3 {
    margin-bottom: -1rem;
  }
  .aesirxui .aesirxsso .mb-xxl-n4 {
    margin-bottom: -1.5rem;
  }
  .aesirxui .aesirxsso .mb-xxl-n5 {
    margin-bottom: -3rem;
  }
  .aesirxui .aesirxsso .ms-xxl-n1 {
    margin-left: -0.25rem;
  }
  .aesirxui .aesirxsso .ms-xxl-n2 {
    margin-left: -0.5rem;
  }
  .aesirxui .aesirxsso .ms-xxl-n3 {
    margin-left: -1rem;
  }
  .aesirxui .aesirxsso .ms-xxl-n4 {
    margin-left: -1.5rem;
  }
  .aesirxui .aesirxsso .ms-xxl-n5 {
    margin-left: -3rem;
  }
  .aesirxui .aesirxsso .p-xxl-0 {
    padding: 0;
  }
  .aesirxui .aesirxsso .p-xxl-1 {
    padding: 0.25rem;
  }
  .aesirxui .aesirxsso .p-xxl-2 {
    padding: 0.5rem;
  }
  .aesirxui .aesirxsso .p-xxl-3 {
    padding: 1rem;
  }
  .aesirxui .aesirxsso .p-xxl-4 {
    padding: 1.5rem;
  }
  .aesirxui .aesirxsso .p-xxl-5 {
    padding: 3rem;
  }
  .aesirxui .aesirxsso .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .aesirxui .aesirxsso .px-xxl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .px-xxl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .px-xxl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .px-xxl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .px-xxl-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .py-xxl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .py-xxl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .py-xxl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .pt-xxl-0 {
    padding-top: 0;
  }
  .aesirxui .aesirxsso .pt-xxl-1 {
    padding-top: 0.25rem;
  }
  .aesirxui .aesirxsso .pt-xxl-2 {
    padding-top: 0.5rem;
  }
  .aesirxui .aesirxsso .pt-xxl-3 {
    padding-top: 1rem;
  }
  .aesirxui .aesirxsso .pt-xxl-4 {
    padding-top: 1.5rem;
  }
  .aesirxui .aesirxsso .pt-xxl-5 {
    padding-top: 3rem;
  }
  .aesirxui .aesirxsso .pe-xxl-0 {
    padding-right: 0;
  }
  .aesirxui .aesirxsso .pe-xxl-1 {
    padding-right: 0.25rem;
  }
  .aesirxui .aesirxsso .pe-xxl-2 {
    padding-right: 0.5rem;
  }
  .aesirxui .aesirxsso .pe-xxl-3 {
    padding-right: 1rem;
  }
  .aesirxui .aesirxsso .pe-xxl-4 {
    padding-right: 1.5rem;
  }
  .aesirxui .aesirxsso .pe-xxl-5 {
    padding-right: 3rem;
  }
  .aesirxui .aesirxsso .pb-xxl-0 {
    padding-bottom: 0;
  }
  .aesirxui .aesirxsso .pb-xxl-1 {
    padding-bottom: 0.25rem;
  }
  .aesirxui .aesirxsso .pb-xxl-2 {
    padding-bottom: 0.5rem;
  }
  .aesirxui .aesirxsso .pb-xxl-3 {
    padding-bottom: 1rem;
  }
  .aesirxui .aesirxsso .pb-xxl-4 {
    padding-bottom: 1.5rem;
  }
  .aesirxui .aesirxsso .pb-xxl-5 {
    padding-bottom: 3rem;
  }
  .aesirxui .aesirxsso .ps-xxl-0 {
    padding-left: 0;
  }
  .aesirxui .aesirxsso .ps-xxl-1 {
    padding-left: 0.25rem;
  }
  .aesirxui .aesirxsso .ps-xxl-2 {
    padding-left: 0.5rem;
  }
  .aesirxui .aesirxsso .ps-xxl-3 {
    padding-left: 1rem;
  }
  .aesirxui .aesirxsso .ps-xxl-4 {
    padding-left: 1.5rem;
  }
  .aesirxui .aesirxsso .ps-xxl-5 {
    padding-left: 3rem;
  }
  .aesirxui .aesirxsso .gap-xxl-0 {
    gap: 0;
  }
  .aesirxui .aesirxsso .gap-xxl-1 {
    gap: 0.25rem;
  }
  .aesirxui .aesirxsso .gap-xxl-2 {
    gap: 0.5rem;
  }
  .aesirxui .aesirxsso .gap-xxl-3 {
    gap: 1rem;
  }
  .aesirxui .aesirxsso .gap-xxl-4 {
    gap: 1.5rem;
  }
  .aesirxui .aesirxsso .gap-xxl-5 {
    gap: 3rem;
  }
  .aesirxui .aesirxsso .row-gap-xxl-0 {
    row-gap: 0;
  }
  .aesirxui .aesirxsso .row-gap-xxl-1 {
    row-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .row-gap-xxl-2 {
    row-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .row-gap-xxl-3 {
    row-gap: 1rem;
  }
  .aesirxui .aesirxsso .row-gap-xxl-4 {
    row-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .row-gap-xxl-5 {
    row-gap: 3rem;
  }
  .aesirxui .aesirxsso .column-gap-xxl-0 {
    column-gap: 0;
  }
  .aesirxui .aesirxsso .column-gap-xxl-1 {
    column-gap: 0.25rem;
  }
  .aesirxui .aesirxsso .column-gap-xxl-2 {
    column-gap: 0.5rem;
  }
  .aesirxui .aesirxsso .column-gap-xxl-3 {
    column-gap: 1rem;
  }
  .aesirxui .aesirxsso .column-gap-xxl-4 {
    column-gap: 1.5rem;
  }
  .aesirxui .aesirxsso .column-gap-xxl-5 {
    column-gap: 3rem;
  }
  .aesirxui .aesirxsso .text-xxl-start {
    text-align: left;
  }
  .aesirxui .aesirxsso .text-xxl-end {
    text-align: right;
  }
  .aesirxui .aesirxsso .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .fs-1 {
    font-size: 2.5rem;
  }
  .aesirxui .aesirxsso .fs-2 {
    font-size: 2rem;
  }
  .aesirxui .aesirxsso .fs-3 {
    font-size: 1.75rem;
  }
  .aesirxui .aesirxsso .fs-4 {
    font-size: 1.5rem;
  }
}
@media print {
  .aesirxui .aesirxsso .d-print-inline {
    display: inline;
  }
  .aesirxui .aesirxsso .d-print-inline-block {
    display: inline-block;
  }
  .aesirxui .aesirxsso .d-print-block {
    display: block;
  }
  .aesirxui .aesirxsso .d-print-grid {
    display: grid;
  }
  .aesirxui .aesirxsso .d-print-inline-grid {
    display: inline-grid;
  }
  .aesirxui .aesirxsso .d-print-table {
    display: table;
  }
  .aesirxui .aesirxsso .d-print-table-row {
    display: table-row;
  }
  .aesirxui .aesirxsso .d-print-table-cell {
    display: table-cell;
  }
  .aesirxui .aesirxsso .d-print-flex {
    display: flex;
  }
  .aesirxui .aesirxsso .d-print-inline-flex {
    display: inline-flex;
  }
  .aesirxui .aesirxsso .d-print-none {
    display: none;
  }
}
.aesirxui .aesirxsso :root {
  --aesirxsso-breakpoint-xs: 0;
  --aesirxsso-breakpoint-sm: 576px;
  --aesirxsso-breakpoint-md: 768px;
  --aesirxsso-breakpoint-lg: 992px;
  --aesirxsso-breakpoint-xl: 1200px;
  --aesirxsso-breakpoint-xxl: 1400px;
}
.aesirxui .aesirxsso .row {
  --aesirxsso-gutter-x: 1.5rem;
  --aesirxsso-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--aesirxsso-gutter-y));
  margin-right: calc(-0.5 * var(--aesirxsso-gutter-x));
  margin-left: calc(-0.5 * var(--aesirxsso-gutter-x));
}
.aesirxui .aesirxsso .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--aesirxsso-gutter-x) * 0.5);
  padding-left: calc(var(--aesirxsso-gutter-x) * 0.5);
  margin-top: var(--aesirxsso-gutter-y);
}
.aesirxui .aesirxsso .col {
  flex: 1 0 0%;
}
.aesirxui .aesirxsso .row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.aesirxui .aesirxsso .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.aesirxui .aesirxsso .row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.aesirxui .aesirxsso .row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.aesirxui .aesirxsso .row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.aesirxui .aesirxsso .row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.aesirxui .aesirxsso .row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.aesirxui .aesirxsso .col-auto {
  flex: 0 0 auto;
  width: auto;
}
.aesirxui .aesirxsso .col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.aesirxui .aesirxsso .col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.aesirxui .aesirxsso .col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.aesirxui .aesirxsso .col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.aesirxui .aesirxsso .col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.aesirxui .aesirxsso .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.aesirxui .aesirxsso .col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.aesirxui .aesirxsso .col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.aesirxui .aesirxsso .col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.aesirxui .aesirxsso .col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.aesirxui .aesirxsso .col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.aesirxui .aesirxsso .col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.aesirxui .aesirxsso .offset-1 {
  margin-left: 8.33333333%;
}
.aesirxui .aesirxsso .offset-2 {
  margin-left: 16.66666667%;
}
.aesirxui .aesirxsso .offset-3 {
  margin-left: 25%;
}
.aesirxui .aesirxsso .offset-4 {
  margin-left: 33.33333333%;
}
.aesirxui .aesirxsso .offset-5 {
  margin-left: 41.66666667%;
}
.aesirxui .aesirxsso .offset-6 {
  margin-left: 50%;
}
.aesirxui .aesirxsso .offset-7 {
  margin-left: 58.33333333%;
}
.aesirxui .aesirxsso .offset-8 {
  margin-left: 66.66666667%;
}
.aesirxui .aesirxsso .offset-9 {
  margin-left: 75%;
}
.aesirxui .aesirxsso .offset-10 {
  margin-left: 83.33333333%;
}
.aesirxui .aesirxsso .offset-11 {
  margin-left: 91.66666667%;
}
.aesirxui .aesirxsso .g-0,
.aesirxui .aesirxsso .gx-0 {
  --aesirxsso-gutter-x: 0;
}
.aesirxui .aesirxsso .g-0,
.aesirxui .aesirxsso .gy-0 {
  --aesirxsso-gutter-y: 0;
}
.aesirxui .aesirxsso .g-1,
.aesirxui .aesirxsso .gx-1 {
  --aesirxsso-gutter-x: 0.25rem;
}
.aesirxui .aesirxsso .g-1,
.aesirxui .aesirxsso .gy-1 {
  --aesirxsso-gutter-y: 0.25rem;
}
.aesirxui .aesirxsso .g-2,
.aesirxui .aesirxsso .gx-2 {
  --aesirxsso-gutter-x: 0.5rem;
}
.aesirxui .aesirxsso .g-2,
.aesirxui .aesirxsso .gy-2 {
  --aesirxsso-gutter-y: 0.5rem;
}
.aesirxui .aesirxsso .g-3,
.aesirxui .aesirxsso .gx-3 {
  --aesirxsso-gutter-x: 1rem;
}
.aesirxui .aesirxsso .g-3,
.aesirxui .aesirxsso .gy-3 {
  --aesirxsso-gutter-y: 1rem;
}
.aesirxui .aesirxsso .g-4,
.aesirxui .aesirxsso .gx-4 {
  --aesirxsso-gutter-x: 1.5rem;
}
.aesirxui .aesirxsso .g-4,
.aesirxui .aesirxsso .gy-4 {
  --aesirxsso-gutter-y: 1.5rem;
}
.aesirxui .aesirxsso .g-5,
.aesirxui .aesirxsso .gx-5 {
  --aesirxsso-gutter-x: 3rem;
}
.aesirxui .aesirxsso .g-5,
.aesirxui .aesirxsso .gy-5 {
  --aesirxsso-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .aesirxui .aesirxsso .col-sm {
    flex: 1 0 0%;
  }
  .aesirxui .aesirxsso .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .aesirxui .aesirxsso .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .aesirxui .aesirxsso .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .aesirxui .aesirxsso .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .aesirxui .aesirxsso .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .aesirxui .aesirxsso .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .aesirxui .aesirxsso .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .aesirxui .aesirxsso .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .aesirxui .aesirxsso .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .offset-sm-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .aesirxui .aesirxsso .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .aesirxui .aesirxsso .offset-sm-3 {
    margin-left: 25%;
  }
  .aesirxui .aesirxsso .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .aesirxui .aesirxsso .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .aesirxui .aesirxsso .offset-sm-6 {
    margin-left: 50%;
  }
  .aesirxui .aesirxsso .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .aesirxui .aesirxsso .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .aesirxui .aesirxsso .offset-sm-9 {
    margin-left: 75%;
  }
  .aesirxui .aesirxsso .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .aesirxui .aesirxsso .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .aesirxui .aesirxsso .g-sm-0,
  .aesirxui .aesirxsso .gx-sm-0 {
    --aesirxsso-gutter-x: 0;
  }
  .aesirxui .aesirxsso .g-sm-0,
  .aesirxui .aesirxsso .gy-sm-0 {
    --aesirxsso-gutter-y: 0;
  }
  .aesirxui .aesirxsso .g-sm-1,
  .aesirxui .aesirxsso .gx-sm-1 {
    --aesirxsso-gutter-x: 0.25rem;
  }
  .aesirxui .aesirxsso .g-sm-1,
  .aesirxui .aesirxsso .gy-sm-1 {
    --aesirxsso-gutter-y: 0.25rem;
  }
  .aesirxui .aesirxsso .g-sm-2,
  .aesirxui .aesirxsso .gx-sm-2 {
    --aesirxsso-gutter-x: 0.5rem;
  }
  .aesirxui .aesirxsso .g-sm-2,
  .aesirxui .aesirxsso .gy-sm-2 {
    --aesirxsso-gutter-y: 0.5rem;
  }
  .aesirxui .aesirxsso .g-sm-3,
  .aesirxui .aesirxsso .gx-sm-3 {
    --aesirxsso-gutter-x: 1rem;
  }
  .aesirxui .aesirxsso .g-sm-3,
  .aesirxui .aesirxsso .gy-sm-3 {
    --aesirxsso-gutter-y: 1rem;
  }
  .aesirxui .aesirxsso .g-sm-4,
  .aesirxui .aesirxsso .gx-sm-4 {
    --aesirxsso-gutter-x: 1.5rem;
  }
  .aesirxui .aesirxsso .g-sm-4,
  .aesirxui .aesirxsso .gy-sm-4 {
    --aesirxsso-gutter-y: 1.5rem;
  }
  .aesirxui .aesirxsso .g-sm-5,
  .aesirxui .aesirxsso .gx-sm-5 {
    --aesirxsso-gutter-x: 3rem;
  }
  .aesirxui .aesirxsso .g-sm-5,
  .aesirxui .aesirxsso .gy-sm-5 {
    --aesirxsso-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .aesirxui .aesirxsso .col-md {
    flex: 1 0 0%;
  }
  .aesirxui .aesirxsso .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .aesirxui .aesirxsso .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .aesirxui .aesirxsso .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .aesirxui .aesirxsso .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .aesirxui .aesirxsso .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .aesirxui .aesirxsso .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .aesirxui .aesirxsso .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .aesirxui .aesirxsso .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .aesirxui .aesirxsso .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .offset-md-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .aesirxui .aesirxsso .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .aesirxui .aesirxsso .offset-md-3 {
    margin-left: 25%;
  }
  .aesirxui .aesirxsso .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .aesirxui .aesirxsso .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .aesirxui .aesirxsso .offset-md-6 {
    margin-left: 50%;
  }
  .aesirxui .aesirxsso .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .aesirxui .aesirxsso .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .aesirxui .aesirxsso .offset-md-9 {
    margin-left: 75%;
  }
  .aesirxui .aesirxsso .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .aesirxui .aesirxsso .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .aesirxui .aesirxsso .g-md-0,
  .aesirxui .aesirxsso .gx-md-0 {
    --aesirxsso-gutter-x: 0;
  }
  .aesirxui .aesirxsso .g-md-0,
  .aesirxui .aesirxsso .gy-md-0 {
    --aesirxsso-gutter-y: 0;
  }
  .aesirxui .aesirxsso .g-md-1,
  .aesirxui .aesirxsso .gx-md-1 {
    --aesirxsso-gutter-x: 0.25rem;
  }
  .aesirxui .aesirxsso .g-md-1,
  .aesirxui .aesirxsso .gy-md-1 {
    --aesirxsso-gutter-y: 0.25rem;
  }
  .aesirxui .aesirxsso .g-md-2,
  .aesirxui .aesirxsso .gx-md-2 {
    --aesirxsso-gutter-x: 0.5rem;
  }
  .aesirxui .aesirxsso .g-md-2,
  .aesirxui .aesirxsso .gy-md-2 {
    --aesirxsso-gutter-y: 0.5rem;
  }
  .aesirxui .aesirxsso .g-md-3,
  .aesirxui .aesirxsso .gx-md-3 {
    --aesirxsso-gutter-x: 1rem;
  }
  .aesirxui .aesirxsso .g-md-3,
  .aesirxui .aesirxsso .gy-md-3 {
    --aesirxsso-gutter-y: 1rem;
  }
  .aesirxui .aesirxsso .g-md-4,
  .aesirxui .aesirxsso .gx-md-4 {
    --aesirxsso-gutter-x: 1.5rem;
  }
  .aesirxui .aesirxsso .g-md-4,
  .aesirxui .aesirxsso .gy-md-4 {
    --aesirxsso-gutter-y: 1.5rem;
  }
  .aesirxui .aesirxsso .g-md-5,
  .aesirxui .aesirxsso .gx-md-5 {
    --aesirxsso-gutter-x: 3rem;
  }
  .aesirxui .aesirxsso .g-md-5,
  .aesirxui .aesirxsso .gy-md-5 {
    --aesirxsso-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .aesirxui .aesirxsso .col-lg {
    flex: 1 0 0%;
  }
  .aesirxui .aesirxsso .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .aesirxui .aesirxsso .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .aesirxui .aesirxsso .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .aesirxui .aesirxsso .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .aesirxui .aesirxsso .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .aesirxui .aesirxsso .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .aesirxui .aesirxsso .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .aesirxui .aesirxsso .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .aesirxui .aesirxsso .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .offset-lg-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .aesirxui .aesirxsso .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .aesirxui .aesirxsso .offset-lg-3 {
    margin-left: 25%;
  }
  .aesirxui .aesirxsso .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .aesirxui .aesirxsso .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .aesirxui .aesirxsso .offset-lg-6 {
    margin-left: 50%;
  }
  .aesirxui .aesirxsso .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .aesirxui .aesirxsso .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .aesirxui .aesirxsso .offset-lg-9 {
    margin-left: 75%;
  }
  .aesirxui .aesirxsso .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .aesirxui .aesirxsso .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .aesirxui .aesirxsso .g-lg-0,
  .aesirxui .aesirxsso .gx-lg-0 {
    --aesirxsso-gutter-x: 0;
  }
  .aesirxui .aesirxsso .g-lg-0,
  .aesirxui .aesirxsso .gy-lg-0 {
    --aesirxsso-gutter-y: 0;
  }
  .aesirxui .aesirxsso .g-lg-1,
  .aesirxui .aesirxsso .gx-lg-1 {
    --aesirxsso-gutter-x: 0.25rem;
  }
  .aesirxui .aesirxsso .g-lg-1,
  .aesirxui .aesirxsso .gy-lg-1 {
    --aesirxsso-gutter-y: 0.25rem;
  }
  .aesirxui .aesirxsso .g-lg-2,
  .aesirxui .aesirxsso .gx-lg-2 {
    --aesirxsso-gutter-x: 0.5rem;
  }
  .aesirxui .aesirxsso .g-lg-2,
  .aesirxui .aesirxsso .gy-lg-2 {
    --aesirxsso-gutter-y: 0.5rem;
  }
  .aesirxui .aesirxsso .g-lg-3,
  .aesirxui .aesirxsso .gx-lg-3 {
    --aesirxsso-gutter-x: 1rem;
  }
  .aesirxui .aesirxsso .g-lg-3,
  .aesirxui .aesirxsso .gy-lg-3 {
    --aesirxsso-gutter-y: 1rem;
  }
  .aesirxui .aesirxsso .g-lg-4,
  .aesirxui .aesirxsso .gx-lg-4 {
    --aesirxsso-gutter-x: 1.5rem;
  }
  .aesirxui .aesirxsso .g-lg-4,
  .aesirxui .aesirxsso .gy-lg-4 {
    --aesirxsso-gutter-y: 1.5rem;
  }
  .aesirxui .aesirxsso .g-lg-5,
  .aesirxui .aesirxsso .gx-lg-5 {
    --aesirxsso-gutter-x: 3rem;
  }
  .aesirxui .aesirxsso .g-lg-5,
  .aesirxui .aesirxsso .gy-lg-5 {
    --aesirxsso-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .aesirxui .aesirxsso .col-xl {
    flex: 1 0 0%;
  }
  .aesirxui .aesirxsso .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .aesirxui .aesirxsso .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .aesirxui .aesirxsso .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .aesirxui .aesirxsso .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .aesirxui .aesirxsso .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .aesirxui .aesirxsso .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .aesirxui .aesirxsso .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .aesirxui .aesirxsso .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .aesirxui .aesirxsso .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .offset-xl-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .aesirxui .aesirxsso .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .aesirxui .aesirxsso .offset-xl-3 {
    margin-left: 25%;
  }
  .aesirxui .aesirxsso .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .aesirxui .aesirxsso .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .aesirxui .aesirxsso .offset-xl-6 {
    margin-left: 50%;
  }
  .aesirxui .aesirxsso .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .aesirxui .aesirxsso .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .aesirxui .aesirxsso .offset-xl-9 {
    margin-left: 75%;
  }
  .aesirxui .aesirxsso .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .aesirxui .aesirxsso .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .aesirxui .aesirxsso .g-xl-0,
  .aesirxui .aesirxsso .gx-xl-0 {
    --aesirxsso-gutter-x: 0;
  }
  .aesirxui .aesirxsso .g-xl-0,
  .aesirxui .aesirxsso .gy-xl-0 {
    --aesirxsso-gutter-y: 0;
  }
  .aesirxui .aesirxsso .g-xl-1,
  .aesirxui .aesirxsso .gx-xl-1 {
    --aesirxsso-gutter-x: 0.25rem;
  }
  .aesirxui .aesirxsso .g-xl-1,
  .aesirxui .aesirxsso .gy-xl-1 {
    --aesirxsso-gutter-y: 0.25rem;
  }
  .aesirxui .aesirxsso .g-xl-2,
  .aesirxui .aesirxsso .gx-xl-2 {
    --aesirxsso-gutter-x: 0.5rem;
  }
  .aesirxui .aesirxsso .g-xl-2,
  .aesirxui .aesirxsso .gy-xl-2 {
    --aesirxsso-gutter-y: 0.5rem;
  }
  .aesirxui .aesirxsso .g-xl-3,
  .aesirxui .aesirxsso .gx-xl-3 {
    --aesirxsso-gutter-x: 1rem;
  }
  .aesirxui .aesirxsso .g-xl-3,
  .aesirxui .aesirxsso .gy-xl-3 {
    --aesirxsso-gutter-y: 1rem;
  }
  .aesirxui .aesirxsso .g-xl-4,
  .aesirxui .aesirxsso .gx-xl-4 {
    --aesirxsso-gutter-x: 1.5rem;
  }
  .aesirxui .aesirxsso .g-xl-4,
  .aesirxui .aesirxsso .gy-xl-4 {
    --aesirxsso-gutter-y: 1.5rem;
  }
  .aesirxui .aesirxsso .g-xl-5,
  .aesirxui .aesirxsso .gx-xl-5 {
    --aesirxsso-gutter-x: 3rem;
  }
  .aesirxui .aesirxsso .g-xl-5,
  .aesirxui .aesirxsso .gy-xl-5 {
    --aesirxsso-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .aesirxui .aesirxsso .col-xxl {
    flex: 1 0 0%;
  }
  .aesirxui .aesirxsso .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .aesirxui .aesirxsso .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .aesirxui .aesirxsso .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .aesirxui .aesirxsso .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .aesirxui .aesirxsso .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .aesirxui .aesirxsso .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .aesirxui .aesirxsso .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .aesirxui .aesirxsso .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .aesirxui .aesirxsso .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .aesirxui .aesirxsso .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .aesirxui .aesirxsso .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .aesirxui .aesirxsso .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .aesirxui .aesirxsso .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .aesirxui .aesirxsso .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .aesirxui .aesirxsso .offset-xxl-0 {
    margin-left: 0;
  }
  .aesirxui .aesirxsso .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .aesirxui .aesirxsso .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .aesirxui .aesirxsso .offset-xxl-3 {
    margin-left: 25%;
  }
  .aesirxui .aesirxsso .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .aesirxui .aesirxsso .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .aesirxui .aesirxsso .offset-xxl-6 {
    margin-left: 50%;
  }
  .aesirxui .aesirxsso .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .aesirxui .aesirxsso .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .aesirxui .aesirxsso .offset-xxl-9 {
    margin-left: 75%;
  }
  .aesirxui .aesirxsso .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .aesirxui .aesirxsso .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .aesirxui .aesirxsso .g-xxl-0,
  .aesirxui .aesirxsso .gx-xxl-0 {
    --aesirxsso-gutter-x: 0;
  }
  .aesirxui .aesirxsso .g-xxl-0,
  .aesirxui .aesirxsso .gy-xxl-0 {
    --aesirxsso-gutter-y: 0;
  }
  .aesirxui .aesirxsso .g-xxl-1,
  .aesirxui .aesirxsso .gx-xxl-1 {
    --aesirxsso-gutter-x: 0.25rem;
  }
  .aesirxui .aesirxsso .g-xxl-1,
  .aesirxui .aesirxsso .gy-xxl-1 {
    --aesirxsso-gutter-y: 0.25rem;
  }
  .aesirxui .aesirxsso .g-xxl-2,
  .aesirxui .aesirxsso .gx-xxl-2 {
    --aesirxsso-gutter-x: 0.5rem;
  }
  .aesirxui .aesirxsso .g-xxl-2,
  .aesirxui .aesirxsso .gy-xxl-2 {
    --aesirxsso-gutter-y: 0.5rem;
  }
  .aesirxui .aesirxsso .g-xxl-3,
  .aesirxui .aesirxsso .gx-xxl-3 {
    --aesirxsso-gutter-x: 1rem;
  }
  .aesirxui .aesirxsso .g-xxl-3,
  .aesirxui .aesirxsso .gy-xxl-3 {
    --aesirxsso-gutter-y: 1rem;
  }
  .aesirxui .aesirxsso .g-xxl-4,
  .aesirxui .aesirxsso .gx-xxl-4 {
    --aesirxsso-gutter-x: 1.5rem;
  }
  .aesirxui .aesirxsso .g-xxl-4,
  .aesirxui .aesirxsso .gy-xxl-4 {
    --aesirxsso-gutter-y: 1.5rem;
  }
  .aesirxui .aesirxsso .g-xxl-5,
  .aesirxui .aesirxsso .gx-xxl-5 {
    --aesirxsso-gutter-x: 3rem;
  }
  .aesirxui .aesirxsso .g-xxl-5,
  .aesirxui .aesirxsso .gy-xxl-5 {
    --aesirxsso-gutter-y: 3rem;
  }
}
.aesirxui .aesirxsso .fs-18 {
  font-size: 18px;
}
.aesirxui .aesirxsso .fs-14 {
  font-size: 14px;
}
.aesirxui .aesirxsso .fs-12 {
  font-size: 12px;
}
.aesirxui .aesirxsso .mb-20px {
  margin-bottom: 20px;
}
.aesirxui .aesirxsso .mb-18px {
  margin-bottom: 18px;
}
.aesirxui .aesirxsso .cursor-pointer {
  cursor: pointer;
}
.aesirxui .aesirxsso.aesirxsso-rounded .form-control {
  border-radius: 50px;
}
.aesirxui .aesirxsso.aesirxsso-rounded .btn {
  border-radius: 50px;
}
.aesirxui .aesirxsso.aesirxsso-rounded .username_left {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
.aesirxui .aesirxsso .form-control {
  padding: 13px;
}
.aesirxui .aesirxsso .btn {
  min-height: 48px;
}
.aesirxui .aesirxsso .btn:not(.btn-ethereum, .btn-concordium) {
  padding: 13px 24px;
}
.aesirxui .aesirxsso .btn:hover.text-white {
  color: #fff;
}
.aesirxui .aesirxsso .btn:disabled, .aesirxui .aesirxsso .btn.disabled {
  opacity: 1;
  background: #a5a4b5;
  color: #fff;
  border-color: transparent;
}
.aesirxui .aesirxsso .line {
  position: relative;
}
.aesirxui .aesirxsso .line::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  background-color: #e9e9e9;
}
.aesirxui .aesirxsso .bg-success-light {
  background-color: #d1fae5;
  color: #0f674c;
}
.aesirxui .aesirxsso .btn-outline {
  border-color: #e9e9e9;
  color: #132342;
}
.aesirxui .aesirxsso .btn-outline:hover {
  border-color: #000;
}
.aesirxui .aesirxsso .back-button {
  margin-bottom: 32px;
}
.aesirxui .aesirxsso .modal-content {
  border: 0;
}
.aesirxui .aesirxsso .btn-close {
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 1;
}
.aesirxui .aesirxsso .custom-loading {
  background: rgba(255, 255, 255, 0.25);
}
.aesirxui .aesirxsso .text-inherit {
  color: inherit !important;
}
.aesirxui .aesirxsso + .modal-backdrop {
  z-index: 1056;
}
.aesirxui .aesirxsso + .modal-backdrop + .modal {
  z-index: 1057;
}
.aesirxui .aesirxsso .modal-dialog-centered {
  min-height: 100%;
}
.aesirxui .aesirxsso.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.aesirxui .aesirxsso .popover-dark .popover {
  background-color: #222328;
  border-color: #222328;
  color: #fff;
  border-radius: 6px;
}
.aesirxui .aesirxsso .popover-dark .popover-body {
  color: #fff;
}
.aesirxui .aesirxsso .popover-dark .popover-arrow::after {
  border-top-color: #222328;
}
.aesirxui .aesirxsso .username_left {
  margin-left: 1px;
  height: 47px;
  width: 47px;
}
.aesirxui .modal-backdrop {
  --aesirxsso-backdrop-zindex: 1050;
  --aesirxsso-backdrop-bg: #000;
  --aesirxsso-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--aesirxsso-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--aesirxsso-backdrop-bg);
}
.aesirxui .modal-backdrop.fade {
  opacity: 0;
}
.aesirxui .modal-backdrop.show {
  opacity: var(--aesirxsso-backdrop-opacity);
}
.aesirxui .aesirxsso-register .modal-dialog {
  height: 100%;
}
@media (max-width: 991.98px) {
  .aesirxui .aesirxsso .modal-dialog {
    max-width: 100%;
    height: 100%;
    margin: 0;
  }
  .aesirxui .aesirxsso .modal-dialog .modal-content {
    height: 100%;
  }
  .aesirxui .aesirxsso .modal-dialog .modal-content .modal-body {
    display: flex;
    align-items: center;
  }
  .aesirxui .aesirxsso.aesirxsso-register .btn-close {
    filter: none;
  }
  .aesirxui .aesirxsso .btn-close {
    filter: invert(1);
  }
}
.aesirxui [data-bs-theme=dark] .aesirxsso .border,
.aesirxui [data-bs-theme=dark] .aesirxsso .border-bottom,
.aesirxui [data-bs-theme=dark] .aesirxsso .border-top,
.aesirxui [data-bs-theme=dark] .aesirxsso .border-start,
.aesirxui [data-bs-theme=dark] .aesirxsso .border-end {
  border-color: var(--aesirxsso-border-color);
}
.aesirxui [data-bs-theme=dark] .aesirxsso .btn-dark {
  border-color: var(--aesirxsso-dark-border-subtle);
  background-color: var(--aesirxsso-dark-bg-subtle);
}
.aesirxui [data-bs-theme=dark] .aesirxsso .line::before {
  background-color: var(--aesirxsso-border-color);
}
.aesirxui [data-bs-theme=dark] .aesirxsso .btn-outline {
  border-color: #e9e9e9;
  color: #fff;
}
.aesirxui [data-bs-theme=dark] .aesirxsso .btn-outline:hover {
  border-color: #fff;
}
.aesirxui [data-bs-theme=dark] .aesirxsso .btn-close {
  filter: none;
}
.aesirxui [data-bs-theme=dark] .aesirxsso .back-button img {
  filter: invert(1);
}
.aesirxui [data-bs-theme=dark] .aesirxsso .twitter-icon {
  filter: invert(1);
}

.btn_loading {
  display: none;
}

.body_login_page .btn_loading {
  display: block;
}
.body_login_page .btn_icon_login {
  display: none;
}
.body_login_page .wr_btn_login {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.form-control:focus {
  color: #1a2226;
  background-color: #fff;
  border-color: #0a083b !important;
  box-shadow: none;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/pages/Login/index.tsx
import { SSOButton, EmailLogin } from "aesirx-sso";
import { AesirxAuthenticationApiService, Storage } from "aesirx-lib";
import { env } from "aesirx-lib";
var LoginPage = ({ text, loginEmail }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const getPreregistration = async (jwt) => {
    return await axios_default.get(
      `${env.REACT_APP_WEB3_API_ENDPOINT ?? "https://web3id.backend.aesirx.io:8001"}/preregistration/aesirx`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  };
  const onGetData = async (response) => {
    setLoading(true);
    if (response.error) {
      notify(response.error_description, "error");
    } else {
      const authService = new AesirxAuthenticationApiService();
      await authService.setTokenUser(response, false);
      try {
        const preregistration = response?.jwt && await getPreregistration(response?.jwt);
        Storage.setItem("preregistration", preregistration?.data);
      } catch (error) {
        console.error(error);
      }
      Storage.setItem("auth", true);
      setLoading(false);
      window.location.reload();
    }
  };
  let version = "";
  try {
    version = VERSION;
  } catch (error) {
  }
  return /* @__PURE__ */ React.createElement("div", { className: "vh-100 bg-blue-9 login-page position-relative" }, /* @__PURE__ */ React.createElement("div", { className: "row justify-content-center align-items-center h-100 " }, /* @__PURE__ */ React.createElement("div", { className: "col-lg-8 col-xxl-6" }, /* @__PURE__ */ React.createElement("div", { className: "d-block p-2 p-lg-5" }, /* @__PURE__ */ React.createElement("p", { className: "fs-2 fw-semibold mb-2 text-center text-blue-5" }, t("txt_welcome_to"), "AesirX ", text), /* @__PURE__ */ React.createElement("p", { className: "fs-2 fw-semibold text-center text-blue-5" }, t("txt_sign_in_to_getting_started")), /* @__PURE__ */ React.createElement("div", { className: "position-relative mt-24" }, loading ? /* @__PURE__ */ React.createElement(Spinner, null) : /* @__PURE__ */ React.createElement(React.Fragment, null, loginEmail ? /* @__PURE__ */ React.createElement("div", { className: "w-100 w-lg-50 mx-auto" }, /* @__PURE__ */ React.createElement(
    EmailLogin,
    {
      onGetData,
      demoUser: env.REACT_APP_DEMO_USER ?? "",
      demoPassword: env.REACT_APP_DEMO_PASSWORD ?? ""
    }
  )) : /* @__PURE__ */ React.createElement(
    SSOButton,
    {
      className: "btn-success btn w-100 w-lg-50 w-xxl-40 mx-auto fw-semibold position-relative d-flex align-item-center justify-content-center my-3 px-4",
      text: t("txt_sign_in_with_sso"),
      onGetData,
      demoUser: env.REACT_APP_DEMO_USER ?? "",
      demoPassword: env.REACT_APP_DEMO_PASSWORD ?? ""
    }
  )))))), /* @__PURE__ */ React.createElement("div", null, version && /* @__PURE__ */ React.createElement("span", { className: "version mb-2 text text-body fs-14 position-absolute bottom-0 w-100 text-center" }, t("txt_version"), " ", version)));
};

export {
  LoginPage
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
